import { Fragment, useState } from 'react';
// import logo from './logo.svg';

import * as ComponentUtils from '../../utils/component-utils';


import { useDataContext } from '../../utils/data-context';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { convertToPbpQueryUI, pbpQuery, pbpQueryMenu, QueryType } from '../../utils/pbp-query-utils';
import VideoSearchFilter from './QueryFilter';
import * as BrowserUtils from '../../utils/browser-utils';
import { useLocation } from "wouter";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OptionsToggle from './OptionsToggle';

const createId = () => {
    return crypto.getRandomValues(new Uint32Array(1))[0];
}

const createDefaultFilter = () => {
    return { queryType: QueryType.GAMEID, id: createId(), isAnd: true, isValid: false }
}

interface videoSearchProps {
    visible: boolean,
    spoilers: boolean,
    toggleSpoilers: () => void,
    fullscreen: boolean,
    toggleFullscreen: () => void,
    randomize: boolean,
    toggleRandomize: () => void,
    closeDrawer: () => void,
    onSearch: (data: {}, isNba: boolean, season: string, ordsq: string) => void,
}

export default function QuerySelector(props: videoSearchProps) {
    const [selectedLeague, setSelectedLeague] = useState('nba');
    const [selectedSeason, setSelectedSeason] = useState(ComponentUtils.getLatestSeason('nba'));
    const [sortType, setSortType] = useState('asc');
    const [filters, setFilters] = useState([createDefaultFilter()] as pbpQueryMenu[]);
    const setLeagueAndSeason = (league: string) => {
        setSelectedLeague(league);
        ComponentUtils.resetSelectedSeason(league, setSelectedSeason);
    };
    const setLocation = useLocation()[1];

    const showClosestDefender = selectedLeague === 'nba' && ComponentUtils.closestDefenderYears.has(selectedSeason);

    const data = useDataContext();
    const isNba = selectedLeague === 'nba';
    const gamesToUse = isNba ? data.gamesBySeason : data.wgamesBySeason;
    const seasons = Object.keys(gamesToUse);

    const playersToUse = selectedLeague === 'nba' ? data.playersBySeason : data.wplayersBySeason;

    const seasonTeamPlayerTransport = selectedLeague === 'nba' ? data.seasonTeamPlayers : data.wseasonTeamPlayers;
    const teamPlayers = seasonTeamPlayerTransport.seasonTeamPlayer[selectedSeason];
    const teamsToUse = Object.keys(teamPlayers).map(id => {
        return { id, name: seasonTeamPlayerTransport.teamNamesById[id] };
    });

    const onSubmit = () => {
        // queryState is  useState<PbpQueryUtils.pbpQueryUI>
        // we create one "OR" query, that's our base query
        // every child query is a group of "AND"s
        const andGroups = [] as pbpQuery[];
        let currAndGroup = [] as pbpQuery[];
        const addAndGroup = () => {
            if (currAndGroup.length > 0) {
                const toAdd = { queryType: QueryType.AND, childQueries: currAndGroup };
                andGroups.push(toAdd)
            };
            currAndGroup = [];
        }
        filters.filter(filter => filter.isValid).forEach(filter => {
            if (!filter.isAnd) addAndGroup();
            currAndGroup.push(convertToPbpQueryUI(filter));
        })
        addAndGroup();
        if (andGroups.length === 0) {
            alert("No valid filters selected");
            return;
        } else if (andGroups.length === 1 && andGroups[0].childQueries?.length === 1) {
            // do stuff
            const query = andGroups[0].childQueries[0];
            if (query.queryType === QueryType.GAMEID) {
                setLocation(BrowserUtils.createGamesVideoUrl(query.gameIds as number[], selectedSeason, isNba, query.videoType as string, query.playerId));
                props.closeDrawer();
                return;
            } else if (query.queryType === QueryType.PLAY && query.playerId) {
                setLocation(BrowserUtils.createPlayerVideoUrl(query.playerId as number, selectedSeason, isNba, query.filters as string, query.shotFilters as string, sortType));
                props.closeDrawer();
                return;
            } else if (query.queryType === QueryType.LINEUP) {
                setLocation(BrowserUtils.createLineupVideoUrl(selectedSeason, isNba, query.teamId as number, query.playerIds?.map(x => x.toString()).join() as string, sortType, true, true));
                props.closeDrawer();
                return;
            }
        }
        const queryState = { queryType: QueryType.OR, childQueries: andGroups };
        props.onSearch(queryState, selectedLeague === 'nba', selectedSeason, sortType === 'asc' ? '' : 'latest');
    };

    return (<div style={props.visible ? {} : { display: 'none' }}>
        <Box>
            <FormControl>
                <InputLabel>League</InputLabel>
                <Select className='search-fields' size="small" label="League" value={selectedLeague}
                    onChange={(e) => setLeagueAndSeason(e.target.value)}>
                    <MenuItem value='nba'>NBA</MenuItem>
                    <MenuItem value='wnba'>WNBA</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel>Season</InputLabel>
                <Select className='search-fields' size="small" label="Season" value={selectedSeason}
                    onChange={(e) => setSelectedSeason(e.target.value)}>
                    {
                        seasons.sort((a, b) => {
                            if (a < b) return 1;
                            else if (a > b) return -1;
                            else return 0;
                        }).map((season, i) => {
                            const seasonText = selectedLeague === 'nba' ? season : season.substring(0, 4);
                            return (<MenuItem key={`season${i}`} value={season}>{seasonText}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
        </Box>

        <Grid container paddingTop="10px" spacing={2}>
            <Grid item xs={6} textAlign="right">
                <Typography variant="body2" display="inline-block">Order:&nbsp;&nbsp;</Typography>
                <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={sortType}
                    onChange={(e, newVal) => newVal !== null && setSortType(newVal)}
                    aria-label="Sort order">
                    <ToggleButton value="asc" aria-label="Earliest">
                        Earliest
                    </ToggleButton>
                    <ToggleButton value="desc" aria-label="Latest">
                        Latest
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
            <OptionsToggle
                    spoilers={props.spoilers}
                    toggleSpoilers={props.toggleSpoilers}
                    fullscreen={props.fullscreen}
                    toggleFullscreen={props.toggleFullscreen}
                    randomize={props.randomize}
                    toggleRandomize={props.toggleRandomize}
                />
                            </Grid>
        </Grid>
        <Accordion disableGutters sx={{ backgroundColor: "#303030", border: "1px solid #424242" }}>
            <AccordionSummary
                sx={{ margin: 0 }}
                expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ margin: 0 }}>Examples</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <a className='white-link' href='/jokic-v-bam.png' target='_blank'><Typography sx={{ margin: 0 }}>Jokic vs. Bam (including defense)</Typography></a>
                <a className='white-link' href='/fox-clutch.png' target='_blank'><Typography sx={{ margin: 0 }}>De'Aaron Fox in clutch time</Typography></a>
                <a className='white-link' href='/query-tatum-assist.png' target='_blank'> <Typography sx={{ margin: 0 }}>Jayson Tatum assisting corner 3s</Typography></a>
            </AccordionDetails>
        </Accordion>
        <Divider textAlign="left" sx={{
            "&::before, &::after": {
                borderColor: "lightgray",
            },
        }}>
            <Typography>Filters</Typography>
        </Divider>
        <Box>
            {
                filters.map((filter, i) => {
                    const removeFilter = () => {
                        filters.splice(i, 1);
                        setFilters(filters.map(x => x));
                    };
                    const setQuery = (query: pbpQueryMenu) => {
                        filters[i] = { ...query, id: filters[i].id };
                    };
                    return (<Fragment key={`${filter.id}`}><VideoSearchFilter
                        spoilers={props.spoilers}
                        showClosestDefender={showClosestDefender}
                        isFirst={i === 0}
                        isOnly={filters.length <= 1}
                        removeFilter={removeFilter}
                        setQuery={setQuery}
                        games={gamesToUse[selectedSeason]}
                        teams={teamsToUse}
                        players={playersToUse[selectedSeason]}
                        teamPlayers={teamPlayers} />
                        {
                            i !== (filters.length - 1) && <Divider sx={{ bgcolor: 'lightgray' }} />
                        }
                    </Fragment>
                    );
                })
            }
            <Grid paddingTop="10px">
                <Button size="small" fullWidth variant="outlined" startIcon={<AddIcon />} onClick={() => {
                    const toSet = [...filters, createDefaultFilter()];
                    setFilters(toSet);
                }}>
                    Add Filter
                </Button>
            </Grid>
        </Box>
        {/* <Button variant="contained" onClick={onSubmit}>Submit</Button> */}
        <button className="action-button submit-button" style={{ marginBottom: '10px' }} onClick={onSubmit}>Submit</button>
    </div>);
}
