import { useState } from 'react';
// import logo from './logo.svg';

import * as ComponentUtils from '../../utils/component-utils';


import { useDataContext } from '../../utils/data-context';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { useLocation } from "wouter";
import * as BrowserUtils from '../../utils/browser-utils';
import { pbpQueryMenu, QueryType } from '../../utils/pbp-query-utils';
import GameSelector from './GameSelector';
import LineupSelector from './LineupSelector';
import PlayerSelector from './PlayerSelector';
import OptionsToggle from './OptionsToggle';
import {closestDefenderYears} from '../../utils/component-utils';
const createId = () => {
    return crypto.getRandomValues(new Uint32Array(1))[0];
}

const createDefaultFilter = () => {
    return { queryType: QueryType.GAMEID, id: createId(), isAnd: true, isValid: false }
}

interface videoSearchProps {
    visible: boolean,
    spoilers: boolean,
    toggleSpoilers: () => void,
    fullscreen: boolean,
    toggleFullscreen: () => void,
    randomize: boolean,
    toggleRandomize: () => void,
    queryType: string,
    closeDrawer: () => void
};
export default function VideoSearch(props: videoSearchProps) {
    const [selectedLeague, setSelectedLeague] = useState('nba');
    const [selectedSeason, setSelectedSeason] = useState(ComponentUtils.getLatestSeason('nba'));
    const [sortType, setSortType] = useState('asc');
    const [filter, setFilter] = useState(createDefaultFilter() as pbpQueryMenu);

    const setLeagueAndSeason = (league: string) => {
        setSelectedLeague(league);
        ComponentUtils.resetSelectedSeason(league, setSelectedSeason);
    };
    const setLocation = useLocation()[1];

    const showClosestDefender = selectedLeague === 'nba' && closestDefenderYears.has(selectedSeason);

    const data = useDataContext();
    const isNba = selectedLeague === 'nba';
    const gamesToUse = isNba ? data.gamesBySeason : data.wgamesBySeason;
    const seasons = Object.keys(gamesToUse);

    const playersToUse = selectedLeague === 'nba' ? data.playersBySeason : data.wplayersBySeason;

    const seasonTeamPlayerTransport = selectedLeague === 'nba' ? data.seasonTeamPlayers : data.wseasonTeamPlayers;
    const teamPlayers = seasonTeamPlayerTransport.seasonTeamPlayer[selectedSeason];
    const teamsToUse = Object.keys(teamPlayers).map(id => {
        return { id, name: seasonTeamPlayerTransport.teamNamesById[id] };
    });

    const setQuery = (query: pbpQueryMenu) => {
        setFilter({ ...query, id: filter.id });
    };
    const onSubmit = () => {
        if (!filter.isValid) {
            alert("Missing required selections");
            return;
        }
        if (props.queryType === QueryType.GAMEID) {
            setLocation(BrowserUtils.createGamesVideoUrl(filter.gameIds as number[], selectedSeason, isNba, filter.videoType as string, filter.playerId));
            props.closeDrawer();
            return;
        } else if (props.queryType === QueryType.PLAY) {
            setLocation(BrowserUtils.createPlayerVideoUrl(filter.playerId as number, selectedSeason, isNba, filter.filters as string, filter.shotFilters as string, sortType));
            props.closeDrawer();
            return;
        } else if (props.queryType === QueryType.LINEUP) {
            setLocation(BrowserUtils.createLineupVideoUrl(selectedSeason, isNba, filter.teamId as number, filter.playerIds?.map(x => x.toString()).join() as string, sortType, true, true));
            props.closeDrawer();
            return;
        }
    };

    return (<div style={props.visible ? {} : { display: 'none' }}>
        <Box>
            <FormControl>
                <InputLabel>League</InputLabel>
                <Select className='search-fields' size="small" label="League" value={selectedLeague}
                    onChange={(e) => setLeagueAndSeason(e.target.value)}>
                    <MenuItem value='nba'>NBA</MenuItem>
                    <MenuItem value='wnba'>WNBA</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel>Season</InputLabel>
                <Select className='search-fields' size="small" label="Season" value={selectedSeason}
                    onChange={(e) => setSelectedSeason(e.target.value)}>
                    {
                        seasons.sort((a, b) => {
                            if (a < b) return 1;
                            else if (a > b) return -1;
                            else return 0;
                        }).map((season, i) => {
                            const seasonText = selectedLeague === 'nba' ? season : season.substring(0, 4);
                            return (<MenuItem key={`season${i}`} value={season}>{seasonText}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
        </Box>

        <Grid container paddingTop="10px" spacing={2}>
            <Grid item xs={6} textAlign="right">
                <Typography variant="body2" display="inline-block">Order:&nbsp;&nbsp;</Typography>
                <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={sortType}
                    onChange={(e, newVal) => newVal !== null && setSortType(newVal)}
                    aria-label="Sort order">
                    <ToggleButton value="asc" aria-label="Earliest">
                        Earliest
                    </ToggleButton>
                    <ToggleButton value="desc" aria-label="Latest">
                        Latest
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
                <OptionsToggle
                    spoilers={props.spoilers}
                    toggleSpoilers={props.toggleSpoilers}
                    fullscreen={props.fullscreen}
                    toggleFullscreen={props.toggleFullscreen}
                    randomize={props.randomize}
                    toggleRandomize={props.toggleRandomize}
                />
            </Grid>
        </Grid>

        <Box>
            {props.queryType === QueryType.GAMEID && <GameSelector games={gamesToUse[selectedSeason]} players={playersToUse[selectedSeason]} spoilers={props.spoilers} setQuery={setQuery} />}
            {props.queryType === QueryType.PLAY && <PlayerSelector playerOptional={false} showClosestDefender={showClosestDefender} players={playersToUse[selectedSeason]} setQuery={setQuery} />}
            {props.queryType === QueryType.LINEUP && <LineupSelector teamPlayers={teamPlayers} teams={teamsToUse} setQuery={setQuery} />}
        </Box>
        {/* <Button variant="contained" onClick={onSubmit}>Submit</Button> */}
        <button className="action-button submit-button" style={{ marginBottom: "10px" }} onClick={onSubmit}>Submit</button>
    </div>);

}