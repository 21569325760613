import {useState} from 'react';

import './search.css';

import {useDataContext} from '../utils/data-context';
import * as ComponentUtils from '../utils/component-utils';

import LeagueSeasonSelector from './common/league-season-selector';
// import logo from './logo.svg';


import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import OptionsToggle from './menu/OptionsToggle';

function MiscSearch(props: miscSearchProps) {
    const [selectedLeague, setSelectedLeague] = useState('nba');
    const [selectedSeason, setSelectedSeason] = useState(ComponentUtils.getLatestSeason('nba'));
    const [sortType, setSortType] = useState('asc');
    const data = useDataContext();

    const setLeagueAndSeason = (league: string) => {
        setSelectedLeague(league);
        ComponentUtils.resetSelectedSeason(league, setSelectedSeason);
    };
    const gamesToUse = selectedLeague === 'nba' ? data.gamesBySeason : data.wgamesBySeason;
    const seasons = Object.keys(gamesToUse);
    return (<div style={props.visible ? {} : { display: 'none' }}>
        <div className='search-field-container'>
        {/* <LeagueSeasonSelector 
                selectedLeague={selectedLeague}
                setLeagueAndSeason={setLeagueAndSeason}
                selectedSeason={selectedSeason}
                seasons={Object.keys(gamesToUse)}
                setSelectedSeason={setSelectedSeason}
            />
            <div className='all-filters-container'>

                <div className='filter-container'>
                    <div className='filter-header'>Order</div>
                    <label className='checkbox-container margin-right-5'>
                        <input type='checkbox' checked={orderByTime === ''}
                            className='filter-checkbox' onChange={() => setOrderByTime('')} />
                        <span className='checkbox-label'>Earliest</span>
                    </label>
                    <label className='checkbox-container margin-right-5'>
                        <input type='checkbox' checked={orderByTime === 'latest'}
                            className='filter-checkbox' onChange={() => setOrderByTime('latest')} />
                        <span className='checkbox-label'>Latest</span>
                    </label>
                    <label className='checkbox-container'>
                        <input type='checkbox' checked={orderByTime === 'shuffle'}
                            className='filter-checkbox' onChange={() => setOrderByTime('shuffle')} />
                        <span className='checkbox-label'>Random</span>
                    </label>
                </div>
            </div> */}
                    <Box>
            <FormControl>
                <InputLabel>League</InputLabel>
                <Select className='search-fields' size="small" label="League" value={selectedLeague}
                    onChange={(e) => setLeagueAndSeason(e.target.value)}>
                    <MenuItem value='nba'>NBA</MenuItem>
                    <MenuItem value='wnba'>WNBA</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel>Season</InputLabel>
                <Select className='search-fields' size="small" label="Season" value={selectedSeason}
                    onChange={(e) => setSelectedSeason(e.target.value)}>
                    {
                        seasons.sort((a, b) => {
                            if (a < b) return 1;
                            else if (a > b) return -1;
                            else return 0;
                        }).map((season, i) => {
                            const seasonText = selectedLeague === 'nba' ? season : season.substring(0, 4);
                            return (<MenuItem key={`season${i}`} value={season}>{seasonText}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
        </Box>

        <Grid container paddingTop="10px" spacing={2}>
            <Grid item xs={6} textAlign="right">
                <Typography variant="body2" display="inline-block">Order:&nbsp;&nbsp;</Typography>
                <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={sortType}
                    onChange={(e, newVal) => newVal !== null && setSortType(newVal)}
                    aria-label="Sort order">
                    <ToggleButton value="asc" aria-label="Earliest">
                        Earliest
                    </ToggleButton>
                    <ToggleButton value="desc" aria-label="Latest">
                        Latest
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
            <OptionsToggle
                    spoilers={props.spoilers}
                    toggleSpoilers={props.toggleSpoilers}
                    fullscreen={props.fullscreen}
                    toggleFullscreen={props.toggleFullscreen}
                    randomize={props.randomize}
                    toggleRandomize={props.toggleRandomize}
                />
            </Grid>
        </Grid>

            <div>
                {/* <button className='action-button' onClick={() => props.onSelectItem(selectedLeague === 'nba', selectedSeason, orderByTime, getClutch)}>Clutch</button> */}
                <button className='action-button' onClick={() => props.onSelectItem('winner', selectedLeague === 'nba', selectedSeason, sortType)}>Winner</button>
                <button className='action-button' onClick={() => props.onSelectItem('bombs', selectedLeague === 'nba', selectedSeason, sortType)}>Bombs</button>
                <button className='action-button' onClick={() => props.onSelectItem('dunkblocks', selectedLeague === 'nba', selectedSeason, sortType)}>Rim stuffs</button>
                <button className='action-button' onClick={() => props.onSelectItem('dunkin1s', selectedLeague === 'nba', selectedSeason, sortType)}>Dunk and ones</button>
                <button className='action-button' onClick={() => props.onSelectItem('ejections', selectedLeague === 'nba', selectedSeason, sortType)}>Ejections</button>
                <button className='action-button' onClick={() => props.onSelectItem('rare', selectedLeague === 'nba', selectedSeason, sortType)}>Rare events</button>
            </div>
            {props.children}
        </div>
    </div>);
}

interface miscSearchProps {
    visible: boolean,
    onSelectItem: (playlistName: string, isNba: boolean, season: string, ordsq: string) => void,
    children?: React.ReactNode,
    spoilers: boolean,
    toggleSpoilers: () => void,
    fullscreen: boolean,
    toggleFullscreen: () => void,
    randomize: boolean,
    toggleRandomize: () => void,
}

export default MiscSearch;