import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { pbpQueryMenu, createQuarterQuery } from "../../utils/pbp-query-utils";

const clutchMessage = 'Clutch time means score is within 5 points and there are 5 minutes or fewer left in the game';
const quarters = ['1Q', '2Q', '3Q', '4Q', 'OT', 'Clutch']
interface quarterSelectorProps {
    setQuery: (query: pbpQueryMenu) => void
}
export default function QuarterSelector(props: quarterSelectorProps) {
    const [selectedQuarters, setSelectedQuarters] = useState([] as string[]);
    useEffect(() => {
        const query = createQuarterQuery(selectedQuarters);
        const isValid = selectedQuarters.length > 0;
        props.setQuery({
            ...query, isValid,
            isAnd: true, id: 0 // these don't matter, get overwritten by containers
        });
    }, [selectedQuarters]);
    return (
        <ToggleButtonGroup
            size="small"
            value={selectedQuarters}
            onChange={(e, newVal) => newVal !== null && setSelectedQuarters(newVal)}
            aria-label="Sort order">
            {
                quarters.map(quarter => {
                    const buttonContent = quarter !== 'Clutch' ?
                        <span>{quarter}</span> : (<Tooltip title={clutchMessage}><span>{quarter}</span></Tooltip>);
                    return (<ToggleButton key={quarter} value={quarter} aria-label="Earliest">{buttonContent}</ToggleButton>);
                })
            }
        </ToggleButtonGroup>
    );
}