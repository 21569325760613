import { useEffect, useState } from 'react';
// import logo from './logo.svg';
import ChangePass from './components/user/change-pass';
import NBApp from './NBApp';
import * as BrowserUtils from './utils/browser-utils';

import { useLocation, useRoute } from "wouter";
import './App.css';



function App() {
    const [pathIsGameVideo, gameVideoParams] = useRoute(BrowserUtils.GAME_VIDEO_URL);
    // const [pathIsIdsVideo, idsVideoParams] = useRoute(BrowserUtils.IDS_VIDEO_URL);
    const [pathIsLineupVideo, lineupVideoParams] = useRoute(BrowserUtils.LINEUP_VIDEO_URL);
    const [pathIsPlayerVideo, playerVideoParams] = useRoute(BrowserUtils.PLAYER_VIDEO_URL);
    const [pathIsPlaylistVideo, playlistVideoParams] = useRoute(BrowserUtils.PLAYLIST_VIDEO_URL);
    const [pathIsChangePass, changePassParams] = useRoute(BrowserUtils.CHANGE_PASSWORD_URL);
    const [showResults, setShowResults] = useState(true);
    const setLocation = useLocation()[1];
    const [isUrlLinkable, setIsUrlLinkable] = useState(false);
    const admin = BrowserUtils.getURLParams().get('admin');
    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const onResize = () => {
            let widthToSet = document.getElementById("App")?.clientWidth;
            if (!widthToSet) widthToSet = window.innerWidth;
            setWidth(widthToSet);
        };
        onResize();
        window.addEventListener('resize', onResize);
    }, []);

    const params = changePassParams as { token: string }

    return (
        <div className="App" id="App">

            <h1>
                the high-low
            </h1>
            <p className='subheader'><a className='white-link' target='_blank' href='https://crowdscout.net/'>Crowdscout</a> is the new and improved successor to this site</p>
            <p className='subheader'>The High-Low will not get new features, but will be updated with new plays indefinitely</p>
            <p className='subheader'>feedback?&nbsp;&nbsp;<u>TheHighLowDotCom@gmail.com</u>&nbsp;&nbsp;<a className='white-link' target='_blank' href='https://twitter.com/thehighlow_NBA'>Twitter</a>

            {/* &nbsp;&nbsp;<a className='white-link' target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSd668xcZFX7_SyywW1xI4EC6JzreubONDVqY5dkSUd5bfxJ7w/viewform'>Survey</a> */}
            </p>
            {!pathIsChangePass ? <NBApp /> : <ChangePass token={params.token} />}
        </div>
    );
}

export default App;
