
import { useEffect, useState } from 'react';
// import logo from './logo.svg';

import * as ComponentUtils from '../../utils/component-utils';
import * as PbpQueryUtils from '../../utils/pbp-query-utils';



import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { player } from '../../utils/api-utils';
import NumCompareSelector from './NumCompareSelector';
import { useDataContext } from '../../utils/data-context';
import { pbpQueryMenu } from '../../utils/pbp-query-utils';

const all = "All";
const allO = "All Offense";
const allD = "All Defense"

const offenseFiltersData = [
    { displayName: allO, isIncluded: false, jsonVal: '' },
    { displayName: '2P Make Unassisted', isIncluded: true, jsonVal: 'make2p' },
    { displayName: '2P Make Assisted', isIncluded: true, jsonVal: 'make2pa' },
    { displayName: '3P Make Unassisted', isIncluded: true, jsonVal: 'make3p' },
    { displayName: '3P Make Assisted', isIncluded: true, jsonVal: 'make3pa' },
    { displayName: 'Assist', isIncluded: true, jsonVal: 'ast' },
    { displayName: 'FT Drawn', isIncluded: true, jsonVal: 'ft' },
    { displayName: '2P Miss', isIncluded: true, jsonVal: 'miss2p' },
    { displayName: '3P Miss', isIncluded: true, jsonVal: 'miss3p' },
    { displayName: 'Turnover', isIncluded: true, jsonVal: 'tov' },
    { displayName: 'Offensive Rebound', isIncluded: false, jsonVal: 'oreb' }
];
const defenseFiltersData = [
    { displayName: allD, isIncluded: false, jsonVal: '' },
    { displayName: 'Defended 2P Miss', isIncluded: false, jsonVal: 'dmiss2p' },
    { displayName: 'Defended 3P Miss', isIncluded: false, jsonVal: 'dmiss3p' },
    { displayName: 'TO Forced', isIncluded: true, jsonVal: 'stl' },
    { displayName: 'Block', isIncluded: true, jsonVal: 'blk' },
    { displayName: 'Defended 2P Make Unassisted', isIncluded: false, jsonVal: 'dmake2p' },
    { displayName: 'Defended 2P Make Assisted', isIncluded: false, jsonVal: 'dmake2pa' },
    { displayName: 'Defended 3P Make Unassisted', isIncluded: false, jsonVal: 'dmake3p' },
    { displayName: 'Defended 3P Make Assisted', isIncluded: false, jsonVal: 'dmake3pa' },
    { displayName: 'Foul', isIncluded: true, jsonVal: 'foul' },
    { displayName: 'Defensive Rebound', isIncluded: false, jsonVal: 'dreb' }
];

const offenseFiltersLookup = new Set(offenseFiltersData.map(x => x.displayName));
const defenseFiltersLookup = new Set(defenseFiltersData.map(x => x.displayName));
interface playerSelectorProps {
    playerOptional: boolean,
    showClosestDefender: boolean,
    players: player[],
    setQuery: (query: pbpQueryMenu) => void
}

export default function PlayerSelector(props: playerSelectorProps) {
    const [selectedPlayer, setSelectedPlayer] = useState<player | null>(null);

    const allPlayTypes = offenseFiltersData.concat(defenseFiltersData);
    const initialPlayTypes = allPlayTypes.filter(x => x.isIncluded);
    const [selectedPlayTypes, setSelectedPlayTypes] = useState(initialPlayTypes);

    const data = useDataContext();
    const shotTypesAndEVAT = data.shotTypes;
    const shotTypes = [all, ...Object.keys(shotTypesAndEVAT)];
    const [selectedShotTypes, setSelectedShotTypes] = useState(shotTypes);
    const [shotTypeSearch, setShotTypeSearch] = useState('');

    const [comparer, setComparer] = useState('gte');
    const [comparerValue, setComparerValue] = useState(0);

    useEffect(() => {
        const filtersJson = selectedPlayTypes.filter(x => x.jsonVal.length > 0).map(x => x.jsonVal);
        const anyShotFilters = filtersJson.some(x => x.includes('make') || x.includes('miss'));
        const shotFilters = anyShotFilters && selectedShotTypes.length !== shotTypes.length ? selectedShotTypes.filter(shotType => shotTypesAndEVAT[shotType]).flatMap(shotType => shotTypesAndEVAT[shotType]) : [];
        const query: PbpQueryUtils.pbpQueryUI = PbpQueryUtils.createPlayerPlaysQuery(selectedPlayer?.id, filtersJson.join(), shotFilters.join(), comparerValue, comparer);
        props.setQuery({
            ...query, isValid: filtersJson.length > 0 && (props.playerOptional || selectedPlayer !== null),
            isAnd: true, id: 0 // these don't matter, get overwritten by containers
        });
    }, [selectedPlayer, selectedPlayTypes, selectedShotTypes, comparer, comparerValue]);

    useEffect(() => {
        setSelectedPlayTypes(initialPlayTypes);
        setSelectedPlayer(null);
    }, [props.players]);

    return (<>
        <Autocomplete
            filterOptions={(options, { inputValue }) => {
                return options.filter(player => (
                    player.name.toLocaleLowerCase().includes(inputValue) ||
                    player.teamAbbrs.toLocaleLowerCase().includes(inputValue) ||
                    player.teamNames.toLocaleLowerCase().includes(inputValue)));
            }}
            fullWidth size="small" limitTags={1} sx={{ marginBottom: '5px' }}
            value={selectedPlayer}
            onChange={(e, newVal) => setSelectedPlayer(newVal)}
            options={props.players}
            getOptionLabel={player => `${player.name} (${player.teamAbbrs})`}
            filterSelectedOptions
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Player"
                        placeholder={props.playerOptional ? "Optional" : ""}
                    />
                )
            }} />
        <Autocomplete
            disableCloseOnSelect
            fullWidth size="small" limitTags={1} multiple sx={{ marginBottom: '5px' }}
            value={selectedPlayTypes}
            onChange={(e, newVal) => {
                // if they selected all
                if (selectedPlayTypes.every(type => type.displayName !== allO) && newVal.some(type => type.displayName === allO))
                    setSelectedPlayTypes(offenseFiltersData.concat(newVal.filter(offFilter => !offenseFiltersLookup.has(offFilter.displayName))));
                else if (selectedPlayTypes.every(type => type.displayName !== allD) && newVal.some(type => type.displayName === allD))
                    setSelectedPlayTypes(newVal.filter(defFilter => !defenseFiltersLookup.has(defFilter.displayName)).concat(defenseFiltersData));
                // if they unselected all
                else if (selectedPlayTypes.some(type => type.displayName === allO) && newVal.every(type => type.displayName !== allO))
                    setSelectedPlayTypes(newVal.filter(x => !offenseFiltersLookup.has(x.displayName)));
                else if (selectedPlayTypes.some(type => type.displayName === allD) && newVal.every(type => type.displayName !== allD))
                    setSelectedPlayTypes(newVal.filter(x => !defenseFiltersLookup.has(x.displayName)));
                else setSelectedPlayTypes(newVal)
            }}
            options={allPlayTypes}
            getOptionLabel={playType => playType.displayName}
            filterSelectedOptions
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Play type"
                    // placeholder="Favorites"
                    />
                )
            }} />
        <Autocomplete
            inputValue={shotTypeSearch}
            onInputChange={(e, val) => {
                if (e && e.type !== 'click') setShotTypeSearch(val)
            }}
            disableCloseOnSelect
            fullWidth size="small" limitTags={1} multiple sx={{ marginBottom: '5px' }}
            value={selectedShotTypes}
            onChange={(e, newVal) => {
                // if they selected all
                if (selectedShotTypes.every(type => type !== all) && newVal.some(type => type === all))
                    setSelectedShotTypes(shotTypes);
                // if they unselected all
                else if (selectedShotTypes.some(type => type === all) && newVal.every(type => type !== all))
                    setSelectedShotTypes([]);
                else setSelectedShotTypes(newVal)
            }}
            options={shotTypes}
            getOptionLabel={shotType => shotType}
            filterSelectedOptions
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Shot type"
                    // placeholder="Favorites"
                    />
                )
            }} />
        <NumCompareSelector numInputName="Shot dist" onChange={(comparer, num) => {
            setComparer(comparer);
            setComparerValue(num);
        }} />
    </>)
}