import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

interface numCompareSelectorProps {
    numInputName: string,
    onChange: (comparer: string, num: number) => void
}

const comparers = [
    { value: "gte", text: "≥" },
    { value: "gt", text: ">" },
    { value: "lt", text: "<" },
    { value: "lte", text: "≤" },
]

export default function NumCompareSelector(props: numCompareSelectorProps) {
    const [selectedComparer, setSelectedComparer] = useState(comparers[0].value);
    const [numVal, setNumVal] = useState(0);
    useEffect(() => {
        props.onChange(selectedComparer, numVal);
    }, [selectedComparer, numVal]);
    return (<>
        <Select size="small" value={selectedComparer}
            onChange={(e) => setSelectedComparer(e.target.value)}>
            {comparers.map(comparer => (<MenuItem key={`comparer${comparer.value}`} value={comparer.value}>{comparer.text}</MenuItem>))}
        </Select>
        <TextField
            size="small"
            id="outlined-number"
            label={props.numInputName}
            type="number"
            value={numVal}
            onChange={e => {
                const num = parseInt(e.target.value);
                if (num >= 0 && num <= 100) setNumVal(num)
                else if (isNaN(num)) setNumVal(0);
            }}
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            InputLabelProps={{
                shrink: true,
            }}
        />
    </>);
}