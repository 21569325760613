import {useState, Fragment, useEffect } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import './search.css';

import {pbpVideo} from '../utils/api-utils';
import { renderFileNameFromPbpVideo } from '../utils/game-selector-utils'

const maxBatchSize = 50;
async function zipAndDownload(pbpVideo: pbpVideo[], startIndex: number) {
    const urls = pbpVideo.map(x => x.videoUrl);
    const failedUrls : string[] = [];
    const blobs = await Promise.all(urls.map(async url => {
        try {
            const mp4 = await fetch(url);
            return await mp4.blob();    
        } catch (e) {
            console.error(e);
            failedUrls.push(url);
        }
    }));
    if (failedUrls.length > 0) alert('Failed to download the following videos. You must manually open and download: ' + failedUrls.join('\r\n'));
    const zip = new JSZip();
    blobs.forEach((blob, i) => {
        if (blob !== null && blob !== undefined)
            zip.file(`${renderFileNameFromPbpVideo(pbpVideo[i])}.mp4`, blob, {binary: true});
    });
    zip.generateAsync({type:"blob"})
        .then(function (blob) {
            saveAs(blob, `Basketball videos ${startIndex+1}-${startIndex+blobs.length}.zip`);
        });
}

interface Props {
    plays: pbpVideo[];
    visible: boolean;
}

const MultiDownload: React.FC<Props> = ({ plays, visible }) => {
    const [startIndex, setStartIndex] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        setStartIndex(0);
    }, [plays]);

    const onClick = async () => {
        const toDownload = plays.filter((x, i) => i >= startIndex && i < (startIndex + maxBatchSize));
        setIsDownloading(true);
        await zipAndDownload(toDownload, startIndex);
        setStartIndex(startIndex + maxBatchSize);
        setIsDownloading(false);
    };

    const playlistDownloaded = startIndex > plays.length-1;
    let buttonText = '';
    const maxSize = Math.min(startIndex+1+maxBatchSize, plays.length);
    const currVideos =  `${startIndex+1}-${maxSize}`;
    if (isDownloading) buttonText = `Downloading videos ${currVideos}`;
    else if (startIndex === 0) buttonText = "Bulk Download";
    else if (playlistDownloaded) buttonText = "Playlist downloaded";
    else buttonText = `Bulk Download videos ${currVideos}`;
    return (<Fragment>{visible && <button className="download-video-button" onClick={onClick} disabled={isDownloading || playlistDownloaded}>{buttonText}</button>}</Fragment>);
}


// async function DownloadCombinedVids(ref : React.RefObject<HTMLVideoElement>) {
//     const blob = new Blob([], {type: 'video/mp4'});
//     const blobUrl = URL.createObjectURL(blob);
//     const source = new MediaSource();
//     const video = ref.current;
//     if (video === null) throw new Error('akljsdfklajsdf');
//     //video.src = blobUrl;
//     video.src = URL.createObjectURL(source);

//     URL.createObjectURL(source);

//     const sourceBuffer = await new Promise<SourceBuffer>((resolve, reject) => {
//         const getSourceBuffer = () => {
//             try {
//                 const sb = source.addSourceBuffer(`video/mp4; codecs="avc1.64001F, mp4a.40.2"`)
//                 resolve(sb);
//             } catch (e) {
//                 reject(e);
//             }
//         }
//         if (source.readyState === 'open') getSourceBuffer();
//         else source.addEventListener('sourceopen', getSourceBuffer);
//     });
//     sourceBuffer.mode = "sequence";

//     const urls = [
//         // "https://videos.nba.com/nba/pbp/media/2023/03/10/0022201002/655/1ceecb11-9238-e88f-82db-6e917d14e4c0_1280x720.mp4",
//         // "https://videos.nba.com/nba/pbp/media/2023/03/10/0022201002/653/cc3bd4d4-694c-6dfa-f3b6-b321afd3fceb_1280x720.mp4", 
//         "https://videos.nba.com/nba/pbp/media/2023/03/10/0022201002/651/475afe6b-8900-52d7-8d63-7c7d7f3b8d0a_1280x720.mp4"
//     ];
//     const buffers = await Promise.all(urls.map(async url => {
//         const vidBlob = await (await fetch(url)).blob();
//         return await vidBlob.arrayBuffer();
//     }));

//     sourceBuffer.onupdateend = () => {
//         console.log('updateend');
//         const top = buffers.shift();
//         console.log(top);
//         if (top !== undefined) sourceBuffer.appendBuffer(top);
//         else {
//             console.log('endofstream');
//             console.log(source.readyState + 2)
//             //source.endOfStream();
//             fetch(blobUrl).then(response => response.blob()).then(blob => {
//                 saveAs(blob, "willtest.mp4")
//             })
//         }
//     };

//     console.log(source.readyState)
//     const top = buffers.shift();
//     if (top !== undefined) sourceBuffer.appendBuffer(top);

//     source.addEventListener('sourceclose', () => console.log('sourceClose'));
//     source.addEventListener('sourceended', () => console.log('sourceended'));
//     // const asdfasdf = new MediaRecorder(source);
//     // const stream = new MediaStream();
//     // source.appendBuffer("");
// }


export default MultiDownload;