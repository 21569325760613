import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";

import { Axis, AxisLeft, AxisTop } from '@visx/axis';
import { localPoint } from '@visx/event';
import { Group } from "@visx/group";
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { scaleUtc, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Line, LinePath } from "@visx/shape";
import { Text } from '@visx/text';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { useRoute } from "wouter";

import { pbpVideo, player, getLineupPlayers } from '../../utils/api-utils';
import * as BrowserUtils from '../../utils/browser-utils';
import { useDataContext } from '../../utils/data-context';
import { downloadSvgAsPng } from '../../utils/svg-export-utils';

import { renderGameTextFromFinalPbpVideo, renderGameTextFromFinalPbpVideoForFilesystem } from '../../utils/game-selector-utils'


import PlayerSelector from '../common/player-selector';
import LineupSelector from '../common/lineup-selector';
import SelectedItem from '../common/selected-item';

import './game-viz.css';

interface Props {
    plays: pbpVideo[];
    initialShowGameViz: boolean;
    setFilteredEventNums: (eventNums: Set<number>) => void;
}
const createGameClockDate = (period: number, gameClock: string) => {
    const splitClock = gameClock.split(':');
    const parsedMins = parseInt(splitClock[0]);
    const parsedSecs = parseInt(splitClock[1]);
    const isOT = period >= 5;
    // -1 because the current period adds 0 base mins
    let baseMins = ((isOT ? 5 : period) - 1) * 12;
    if (isOT) baseMins += (period - 1 - 4) * 5;
    const quarterMins = isOT ? 5 : 12;
    const mins = parsedMins === quarterMins ? 0 : quarterMins - 1 - parsedMins;
    const secs = parsedMins === quarterMins && parsedSecs === 0 ? 0 : 60 - parsedSecs;
    return new Date(0, 0, 0, 0, baseMins + mins, secs);
};

let mouseEnterEvent: null | React.MouseEvent | React.TouchEvent = null;
let mouseDownEvent: null | React.MouseEvent | React.TouchEvent = null;
const PTS = 'Points';
const MISS = 'Miss';
const STEAL = 'Steal';
const TOV = 'TOV';
const FOUL = 'Foul';
const OTHER = 'Other';
const firstLegendData = [
    { label: PTS, color: ['#2dcc2d'] },
    { label: MISS, color: ['rgb(253, 142, 16)'] },
    { label: STEAL, color: ['#f80e0ede'] },
    { label: TOV, color: ['#ff17aa'] },
    { label: FOUL, color: ['#f3f72c'] },
    { label: OTHER, color: ['gray'] }
];

const getLegendTypeFromPlay = (play: pbpVideo) => {
    if (play.eventMsgType === 1) {
        return PTS;
    }
    else if (play.eventMsgType === 2) {
        return MISS; // darker neon orange
    }
    else if (play.eventMsgType === 5) {
        if (play.playDesc.includes('stolen by ')) return STEAL;
        else return TOV;
    }
    else if (play.eventMsgType === 6) {
        if (play.playDesc.includes('FT)')) return play.playDesc.includes('(0/') ? MISS : PTS;
        else if (play.playDesc.includes('off')) return TOV;
        else return FOUL;
    }
    else return OTHER;
};

const getColorClassForPlay = (play: pbpVideo, isHover: boolean) => {
    const suffix = isHover ? '-hover' : '';
    const playType = getLegendTypeFromPlay(play);
    const isFT = play.playDesc.includes('FT)');
    if (playType === FOUL && isFT) return play.playDesc.includes('(0/') ? `miss${suffix}` : `points${suffix}`;
    switch (playType) {
        case PTS:
            return `points${suffix}`;
        case MISS:
            return `miss${suffix}`;
        case STEAL:
            return `play-steal${suffix}`;
        case TOV:
            return `play-turnover${suffix}`;
        case FOUL:
            return `play-foul${suffix}`;
        case OTHER:
            return `play-unknown${suffix}`;
        default:
            throw Error('Unrecognized playtype ' + playType);
    }
}

const lineStrokeWidth = 4;
const GameVizGraph: React.FC<Props> = ({ plays, setFilteredEventNums, initialShowGameViz }) => {
    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip({
        // initial tooltip state
        tooltipOpen: false,
        tooltipLeft: 0,
        tooltipTop: 0,
        tooltipData: '',
    });

    const initialDisplayedLegendItems: { [key: string]: boolean; } = {};

    firstLegendData.forEach(x => initialDisplayedLegendItems[x.label] = true);

    const [displayedLegendItems, setDisplayedLegendItems] = useState(initialDisplayedLegendItems);
    const maxQuarter = Math.max(...plays.map(play => play.period));
    const maxScoreDiff = Math.max(...plays.map(play => Math.abs(play.homeScore - play.awayScore)));
    const [maxWidth, setMaxWidth] = useState(1500);
    const [maxHeight, setMaxHeight] = useState(500);
    const [showGameViz, setShowGameViz] = useState(initialShowGameViz);
    const [gamePlayerIds, setGamePlayerIds] = useState(new Set());
    const [selectedPlayerIds, setSelectedPlayerIds] = useState({} as { [key: number]: boolean });
    const [pathIsGameVideo, gvp] = useRoute(BrowserUtils.GAME_VIDEO_URL);
    const gameVideoParams = gvp as { gameId: string, season: string, league: string }
    const [lineupIdByPlayerIds, setLineupIdByPlayerIds] = useState({} as { [key: number]: number[] })
    const [isLineupLoaded, setIsLineupLoaded] = useState(false);

    useEffect(() => {
        setShowGameViz(initialShowGameViz);
    }, [initialShowGameViz]);

    const [selectedEventNums, setSelectedEventNums] = useState(new Set<number>());

    const filterByPlayer = (x: pbpVideo, playerId: number) => {
        return x.player1Id === playerId || x.player2Id === playerId || x.player3Id === playerId;
    };
    useEffect(() => {
        const toSend = new Set<number>();
        plays
            .filter(play => displayedLegendItems[getLegendTypeFromPlay(play)] && selectedEventNums.has(play.eventNum))
            .forEach(play => toSend.add(play.eventNum));
        setFilteredEventNums(toSend);
    }, [selectedEventNums, displayedLegendItems]);

    const [filteredPlayer, _setFilteredPlayer] = useState<player | null>(null);
    const setFilteredPlayer = (player: player | null) => {
        _setFilteredPlayer(player);
        const selectedEventNums = player === null ?
            new Set<number>() :
            plays.filter(x => filterByPlayer(x, player.id)).map(x => x.eventNum);
        setSelectedEventNums(new Set(selectedEventNums));
    };

    useEffect(() => {
        const gamePlayerIds = new Set();
        plays.forEach(x => {
            if (x.player1Id) gamePlayerIds.add(x.player1Id);
            if (x.player2Id) gamePlayerIds.add(x.player2Id);
            if (x.player3Id) gamePlayerIds.add(x.player3Id);
        });
        setGamePlayerIds(gamePlayerIds);
        const uniqLineupIds = new Set();
        plays.forEach(x => {
            uniqLineupIds.add(x.homeLineupId);
            uniqLineupIds.add(x.awayLineupId);
        });
        const lineupIds = Array.from(uniqLineupIds).join(",");
        const toSetLineupPlayerIds: { [key: number]: number[]; } = {};
        setIsLineupLoaded(false);
        setSelectedPlayerIds({});
        getLineupPlayers(gameVideoParams.season, lineupIds, gameVideoParams.league === 'nba')
            .then(res => {
                res.lineups.forEach(x => {
                    x.playerIds.forEach(y => {
                        if (toSetLineupPlayerIds[y] === undefined) toSetLineupPlayerIds[y] = [];
                        toSetLineupPlayerIds[y].push(x.lineupId);
                    });
                });
                setLineupIdByPlayerIds(toSetLineupPlayerIds);
                setIsLineupLoaded(true);
            });
    }, [plays]);
    const data = useDataContext();
    const seasonPlayers = gameVideoParams.league === 'nba' ? data.playersBySeason : data.wplayersBySeason;
    const validPlayers = seasonPlayers[gameVideoParams.season].filter(x => gamePlayerIds.has(x.id));

    let windowWidth = 0;
    let windowHeight = 0;
    useEffect(() => {
        const onResize = () => {
            let width = document.getElementById("App")?.clientWidth;
            if (!width) width = window.innerWidth;
            windowWidth = width;
            width = Math.min(width * .95, 1500);
            let height = document.getElementById("App")?.clientHeight;
            if (!height) height = window.innerHeight;
            windowHeight = height;
            height = Math.min(height * .95, 500);
            // height is max 2/3 of width
            if (height / width > 0.66) height = width * 0.66;
            setMaxWidth(width);
            setMaxHeight(height);
        };
        onResize();
        window.addEventListener('resize', onResize);
    }, []);

    // create scales for the x and y axes
    const xScale = scaleUtc({
        domain: [createGameClockDate(1, '12:00'), createGameClockDate(maxQuarter, '00:00')],
        range: [0, maxWidth]
    });
    const yScale = scaleLinear({
        domain: [-maxScoreDiff, maxScoreDiff],
        range: [maxHeight, 0]
    });

    const displayedPlays = plays.filter(play => displayedLegendItems[getLegendTypeFromPlay(play)]);
    // create lines for each play on the graph
    const lines = displayedPlays.map((play, index) => {
        const isSelected = selectedEventNums.size === 0 || selectedEventNums.has(play.eventNum);
        const colorClass = getColorClassForPlay(play, false);
        const legendType = getLegendTypeFromPlay(play);
        const playIsFt = play.playDesc.includes('FT)');
        let numPoints = 0;
        if (playIsFt) {
            if (play.playDesc.includes('(3')) numPoints += 3;
            else if (play.playDesc.includes('(2')) numPoints += 2;
            else if (play.playDesc.includes('(1')) numPoints += 1;
        }
        if (play.is3Pt === true) numPoints += 3;
        else if (play.is3Pt === false) numPoints += 2;
        if (numPoints === 0) numPoints = 2;

        const baseScale = 1 * maxScoreDiff / 20;
        const scale = baseScale * numPoints;
        const isVertical = playIsFt && play.eventMsgType === 6 ? !play.isHome : play.isHome;
        return (
            <Line
                key={'play' + index}
                x1={xScale(createGameClockDate(play.period, play.gameClock))}
                y1={yScale(isVertical ? scale : -scale)}
                x2={xScale(createGameClockDate(play.period, play.gameClock))}
                y2={yScale(0)}
                strokeWidth={lineStrokeWidth}
                strokeOpacity={isSelected ? 1.0 : 0.1}
                className={colorClass}
            />
        );
    });

    const isMobile = window.matchMedia('(pointer: coarse)').matches;
    const timeoutId = useRef<NodeJS.Timeout | null>(null);
    const clearCurrTimeout = () => {
        if (timeoutId.current !== null) clearTimeout(timeoutId.current)
    };

    const debounceHide = () => {
        clearCurrTimeout();
        timeoutId.current = setTimeout(() => hideTooltip(), 200);
    };
    const handleMouseOver = useCallback((event: React.MouseEvent<SVGLineElement, MouseEvent>, pbpVideo: pbpVideo) => {
        clearCurrTimeout();
        showTooltip({
            tooltipLeft: (localPoint(event)?.x ?? 0),
            tooltipTop: localPoint(event)?.y ?? 0,
            tooltipData: `${pbpVideo.homeScore}-${pbpVideo.awayScore} ${pbpVideo.period}Q ${pbpVideo.gameClock} ${pbpVideo.playDesc}`
        });
    }, [plays]);

    const hoverLines = displayedPlays.map((play, index) => {
        const displayColor = displayedLegendItems[getLegendTypeFromPlay(play)];
        const colorClass = displayColor ? getColorClassForPlay(play, true) : '';
        return (
            <Line
                onMouseOver={(e) => displayColor && handleMouseOver(e, play)}
                onMouseLeave={debounceHide}
                key={'play' + index}
                x1={xScale(createGameClockDate(play.period, play.gameClock))}
                y1={maxHeight}
                x2={xScale(createGameClockDate(play.period, play.gameClock))}
                y2={-maxHeight}
                // from={{ x: 0, y: 0 }}
                // to={{ x: 0, y: 0 }}
                strokeWidth={lineStrokeWidth}
                strokeOpacity={1.0}
                className={colorClass + ' play-hover'}
            />
        );
    });

    // create step graph for score diff
    const stepLines: { x: number, y: number }[] = [];
    let lastScorediff = 0;
    plays.forEach((play, index) => {
        stepLines.push({ x: xScale(createGameClockDate(play.period, play.gameClock)), y: yScale(lastScorediff) });
        lastScorediff = play.homeScore - play.awayScore;
        stepLines.push({ x: xScale(createGameClockDate(play.period, play.gameClock)), y: yScale(lastScorediff) });
    });
    const scoreTicks = [];
    let currScoreTick = maxScoreDiff;
    if (maxScoreDiff % 5 !== 0) currScoreTick = (Math.floor(maxScoreDiff / 5) + 1) * 5;
    while (currScoreTick >= -maxScoreDiff) {
        scoreTicks.push(currScoreTick);
        currScoreTick -= 5;
    }

    const clockTicks = [];
    for (let i = 1; i < maxQuarter; i++) {
        clockTicks.push(createGameClockDate(i, '00:00'));
    }

    const homeCss = { fill: plays[0].homeColor, fillOpacity: 0.25 };
    const awayCss = { fill: plays[0].awayColor, fillOpacity: 0.25 };

    const testHomeLineupCss = { fill: plays[0].homeColor, fillOpacity: 0.35 };
    const testAwayLineupCss = { fill: plays[0].awayColor, fillOpacity: 0.35 };

    const legendScale = scaleOrdinal({
        domain: firstLegendData.map(x => x.label),
        range: firstLegendData.map(x => x.color),
    });

    const onMouseTouchUp = (event: React.MouseEvent | React.TouchEvent, isTouch: boolean) => {
        if (mouseDownEvent !== null) {
            const isValidUp = (isTouch ? true : mouseEnterEvent !== null
                && mouseDownEvent.timeStamp >= mouseEnterEvent.timeStamp);
            if (isValidUp) {
                const mouseDownX = localPoint(mouseDownEvent)?.x ?? -1;
                const mouseUpX = localPoint(event)?.x ?? -1;
                const leftX = Math.min(mouseDownX, mouseUpX);
                const rightX = Math.max(mouseDownX, mouseUpX);
                const toSet = (mouseDownEvent.ctrlKey || mouseDownEvent.shiftKey) ?
                    new Set(selectedEventNums) : new Set<number>();

                hoverLines.forEach((line, i) => {
                    const barLeft = line.props.x1 - (lineStrokeWidth / 2);
                    const barRight = line.props.x1 + (lineStrokeWidth / 2);
                    const barLeftInClick = (barLeft >= leftX && barLeft <= rightX);
                    const barRightInClick = (barRight >= leftX && barRight < rightX);
                    const clickWithinBar = barLeft <= leftX && barLeft <= rightX && barRight >= leftX && barRight >= rightX;
                    // if left side gte leftclick, and left side lte rightclick
                    if (barLeftInClick || barRightInClick || clickWithinBar) {
                        toSet.add(plays[i].eventNum);
                    }
                });
                setSelectedEventNums(toSet);
            }
        }
    };
    const svgRef = useRef<SVGSVGElement>(null);

    const finalPlay = plays[plays.length - 1];

    const selectedPlayerIdsArr = Object.keys(selectedPlayerIds)
        .map(x => parseInt(x))
        .filter(x => selectedPlayerIds[x]);

    const lineupHilite = [] as { x: number, width: number }[];
    let startPlay: pbpVideo | null = null;
    plays.forEach((play, i) => {
        // also add, is not last play
        const isLastPlay = i === plays.length - 1;
        const everyPlayerOnFloor = selectedPlayerIdsArr.every(x => {
            return lineupIdByPlayerIds[x].some(lineupId => lineupId === play.homeLineupId || lineupId === play.awayLineupId);
        });
        if (!isLastPlay && everyPlayerOnFloor) {
            if (startPlay === null) startPlay = play;
        } else if (startPlay !== null) {
            const lastPlay = plays[i - 1];
            const x = xScale(createGameClockDate(startPlay.period, startPlay.gameClock));
            const width = xScale(createGameClockDate(lastPlay.period, isLastPlay ? "00:00" : lastPlay.gameClock)) - x;
            lineupHilite.push({ x, width });
            startPlay = null;
        }
    });

    return (
        <div className='game-viz-container'>
            {
                showGameViz && <Fragment>
                    <h2 style={{ margin: 5 }}>{renderGameTextFromFinalPbpVideo(finalPlay, true)}</h2>
                    <div style={{ margin: 5 }}>
                        <p style={{ margin: 0 }}>Click and drag to filter. Shift/ctrl click and drag to set multiple filters</p>
                    </div>
                </Fragment>
            }
            {/* <div>
                <label>Highlight when player was on court: </label>
                <select>
                    <option>Jayson Tatum</option>
                </select>
                <button className={showGameViz ? 'action-button active-button' : 'action-button'} onClick={() => setShowGameViz(!showGameViz)}>Show game graph</button>
            </div> */}
            {
                showGameViz && <div className='search-container search-field-container'>
                    <div>
                        {
                            filteredPlayer === null ?
                                <PlayerSelector
                                    displayText='Filter by player'
                                    players={validPlayers}
                                    onSelectItem={player => setFilteredPlayer(player)} />
                                :
                                <SelectedItem
                                    text={filteredPlayer.name + " (" + filteredPlayer.teamAbbrs + ")"}
                                    onDelete={() => setFilteredPlayer(null)} />
                        }
                    </div>
                    <div>
                        {
                            isLineupLoaded ? 
                                <LineupSelector players={validPlayers} selectedPlayers={selectedPlayerIds} setSelectedPlayers={setSelectedPlayerIds} placeholder={'Highlight player(s) on court'} selectedPlayerHeader={'On-court time highlighted:'} />
                                :
                                <input type="text" className="margin-top search-fields search-text" placeholder="Loading lineups..." />
                        }
                    </div>
                    <div>
                        <button className={'action-button'} onClick={() => setSelectedEventNums(new Set<number>())}>Clear Filtered Plays</button>
                        <button className={'action-button'} onClick={() => {
                            if (svgRef.current !== null) downloadSvgAsPng(svgRef.current, renderGameTextFromFinalPbpVideoForFilesystem(finalPlay, true))
                            else console.log("svgref is null");
                        }}>Download</button>
                    </div>
                </div>
            }
            {
                showGameViz && <div style={{ display: 'inline-block', position: 'relative' }}>
                    <svg width={maxWidth} height={maxHeight} style={{ cursor: 'default' }} ref={svgRef}
                        onMouseEnter={e => mouseEnterEvent = e}
                        onMouseLeave={() => mouseEnterEvent = null}
                        onMouseDown={e => {
                            mouseDownEvent = e;
                            e.preventDefault();
                        }}
                        onTouchEnd={e => onMouseTouchUp(e, true)}
                        onTouchStart={e => mouseDownEvent = e}
                        onMouseUp={e => onMouseTouchUp(e, false)}>
                        <Group>
                            <rect width={maxWidth} height={maxHeight} style={{fill: '#212121'}}></rect>
                            <rect width={maxWidth} height={maxHeight / 2} style={homeCss} />
                            <rect width={maxWidth} height={maxHeight / 2} y={maxHeight / 2} style={awayCss} />
                            <AxisTop
                                scale={xScale}
                                tickFormat={time => '12:00'}
                                tickValues={clockTicks}
                                numTicks={clockTicks.length}
                                tickStroke={"#616161"}
                                tickLength={maxHeight * 2}
                                tickTransform={`translate(0, -${maxHeight / 2})`}
                                stroke="#bdbdbd"
                                strokeWidth={3}
                                top={maxHeight / 2}
                                label={'x AXIS LABEL'}
                            />
                            <Text fill='#919191' fontWeight='bold' style={{ fontFamily: "'Ubuntu', sans-serif" }} verticalAnchor="middle" scaleToFit={true} width={30 * finalPlay.homeTeam.length} dy={maxHeight / 8} dx={maxWidth / 2 - (30 * finalPlay.homeTeam.length) / 2}>
                                {plays[plays.length - 1].homeTeam}
                            </Text>
                            <Text fill='#919191' fontWeight='bold' style={{ fontFamily: "'Ubuntu', sans-serif" }} verticalAnchor="middle" scaleToFit={true} width={30 * finalPlay.awayTeam.length} dy={maxHeight * 6 / 8} dx={maxWidth / 2 - (30 * finalPlay.awayTeam.length) / 2}>
                                {plays[plays.length - 1].awayTeam}
                            </Text>
                            {/* Test retacles */}
                            {
                                lineupHilite.map(coords => {
                                    return (<Fragment>
                                        <rect width={coords.width} height={maxHeight / 2} x={coords.x} style={testHomeLineupCss} />
                                        <rect width={coords.width} height={maxHeight / 2} y={maxHeight / 2} x={coords.x} style={testAwayLineupCss} />
                                    </Fragment>)
                                })
                            }
                            <AxisLeft
                                scale={yScale}
                                tickFormat={score => Math.abs(parseInt(score.toString())).toString()}
                                tickValues={scoreTicks}
                                numTicks={scoreTicks.length}
                                tickStroke={"#616161"}
                                tickLength={-maxWidth}
                                tickLabelProps={() => {
                                    return { fontSize: 14, fontFamily: 'Arial', textAnchor: 'end', overflow: 'visible', fill: '#616161' };
                                }}
                                stroke="transparent"
                                strokeWidth={1}
                                labelOffset={-20} />
                            <Axis
                                scale={xScale}
                                tickFormat={time => '12:00'}
                                tickValues={clockTicks}
                                tickStroke={"#616161"}
                                tickLength={maxHeight * 2}
                                tickTransform={`translate(0, -${maxHeight / 2})`}
                                stroke="#bdbdbd"
                                strokeWidth={3}
                                top={maxHeight / 2} />
                            {lines}
                            <LinePath
                                data={stepLines}
                                x={d => d.x}
                                y={d => d.y}
                                stroke='#ffffff9e'
                                strokeWidth={2} />
                            {hoverLines}
                            <Text fill='#919191' fontFamily="'Ubuntu', sans-serif" verticalAnchor="end" scaleToFit={true} width={maxWidth / 6} dx={5} dy={maxHeight - 10}>thehighlow.io</Text>
                            {/* <Text fill='#919191' fontFamily="'Ubuntu', sans-serif" verticalAnchor="end" scaleToFit={true} width={maxWidth / 4} dx={maxWidth - 5 - (maxWidth / 4)} dy={maxHeight - 40}>
                                {firstLegendData.filter(x => displayedLegendItems[x.label]).map(x => x.label).join(', ')}
                            </Text>
                            <Text fill='#919191' fontFamily="'Ubuntu', sans-serif" verticalAnchor="end" scaleToFit={true} width={maxWidth / 3} dx={maxWidth - 5 - (maxWidth / 3)} dy={maxHeight - 10}>Highlighted: Jayson Tatum, Jaylen Brown, Al Horford, Someone Else, somethingsomething on floor</Text> */}
                        </Group>
                    </svg>
                    <div style={{
                        position: 'absolute',
                        fontSize: '14px',
                        top: 0,
                        left: 0,
                        display: 'inline-block', // required in addition to `direction` if overriding styles
                    }}>
                        <LegendOrdinal scale={legendScale} direction="column-reverse" itemDirection="row-reverse"
                            labelMargin="0 5px 0 0" shapeMargin="0px 0 0"
                            style={{
                                paddingLeft: '5px',
                                backgroundColor: '#212121',
                                display: 'inline-block',
                                color: 'white'
                            }}>
                            {(labels) =>
                                labels.map((label) => {
                                    return (
                                        <LegendItem key={`legend-${label.text}-${label.index}`}
                                            onClick={() => {
                                                setDisplayedLegendItems({ ...displayedLegendItems, [`${label.text}`]: !displayedLegendItems[label.text] })
                                            }}
                                            className='checkbox-container'
                                            style={{
                                                backgroundColor: '#212121', display: 'flex',
                                                width: maxHeight > 400 ? '100px' : '80px',
                                                height: maxHeight > 400 ? '20px' : '12px',
                                                fontSize: maxHeight > 400 ? '16px' : '10px'
                                            }}>
                                            <input type='checkbox' className='filter-checkbox' checked={displayedLegendItems[label.text]}
                                            // no need for the onchange to actually do anything - this is so react stops yelling at us
                                                onChange={() => {}}
                                            />
                                            <LegendLabel align="left" margin="0" className='checkbox-label' style={{ backgroundColor: '#212121' }}>
                                                {label.text}
                                            </LegendLabel>
                                            {
                                                label.value !== undefined && label.value.map((color, i) => {
                                                    const right = i * 16;
                                                    return (<svg width={15} height={15} style={{ position: 'absolute', right: right, top: '1' }}>
                                                        <rect fill={color} width={15} height={15} />
                                                    </svg>)
                                                })
                                            }
                                        </LegendItem>
                                    );
                                })
                            }
                        </LegendOrdinal>
                    </div>
                    {
                        tooltipOpen &&
                        <TooltipWithBounds
                            key={Math.random()} // needed for bounds to update correctly - TODO WH - is this true??
                            left={tooltipLeft}
                            top={tooltipTop}
                            style={{ ...defaultStyles, backgroundColor: '#212121', color: 'white', width: '150px' }}>
                            {tooltipData}
                        </TooltipWithBounds>
                    }

                </div>
            }

        </div>

    );
};

export default GameVizGraph;