import * as PbpQueryUtils from '../../utils/pbp-query-utils';

import React, { useState } from 'react';

function TeamSelector(props: teamSelectorProps) {
    const [selectedTeam, setSelectedTeam] = useState(props.teams[0]);
    const [isHome, setIsHome] = useState(true);
    const [isAway, setIsAway] = useState(true);
    const onSubmit = () => {
        if (!isHome && !isAway) {
            alert('Must select home team or away team (or both)');
            return;
        }
        const newQuery : PbpQueryUtils.pbpQueryUI = PbpQueryUtils.createTeamQuery(parseInt(selectedTeam.id), isHome, isAway);
        newQuery.displayText = selectedTeam.name;
        if (isHome) newQuery.displayText += ' is home team'; 
        if (isAway) {
            newQuery.displayText += (isHome) ? ' or away team' : ' is away team'; 
        }
        props.onSubmit(newQuery);
    };
    return (<React.Fragment>
        <div className='search-field-container'>
            Team is...
            <select className='search-fields margin-left-15' value={selectedTeam.id} onChange={e => {
                const newTeam = props.teams.find(x => x.id === e.target.value);
                if (newTeam !== undefined) setSelectedTeam(newTeam);
            }}>
                {
                    props.teams.map(team => {
                        return <option value={team.id}>{team.name}</option>
                    })
                }
            </select>
        </div>
        <div className='search-field-container'>
            Team is one of...
            <button className={isHome ? 'action-button active-button margin-left-15' : 'action-button margin-left-15'} onClick={() => setIsHome(!isHome)}>Home team</button>
            <button className={isAway ? 'action-button active-button' : 'action-button'} onClick={() => setIsAway(!isAway)}>Away team</button>
        </div>
        {/* <div className='search-field-container'>
            <select>

            </select>
            Team is one of...
            <button>Home team</button>
            <button>Away team</button>
        </div> */}
        <button className='submit-button action-button' onClick={onSubmit}>Add</button>
    </React.Fragment>);
}

interface teamSelectorProps {
    teams: team[],
    onSubmit: (query: PbpQueryUtils.pbpQueryUI) => void
}

interface team {
    id: string,
    name: string
}
export default TeamSelector;