import { pbpVideo, ShotType } from '../../utils/api-utils';
import * as d3 from 'd3';
import './shot-map.css';

import ShotChartBG from '../static/shot-chart-bg.png';

import React, { useRef, useState } from 'react';

const ShotMap = (props: shotMapProps) => {
    const svgRef = React.useRef(null);
    const width = 500;
    const height = 940;
    React.useEffect(() => {
        const svg = d3.select(svgRef.current);
        const dataToUse = props.data.filter(x => x.locX !== null && x.locY !== null);
        let maxY = -123123;
        dataToUse.forEach(x => {
            if (x.locY !== null && x.locY > maxY) {
                maxY = x.locY;
            }
        })
        console.log(dataToUse.find(x => x.locY === maxY))
        const node = svg.selectAll("play-dot").data(dataToUse);

        const scaleX = d3.scaleLinear()
            .domain([-250, 250])
            .range([0, width]);

        const scaleY = d3.scaleLinear()
            .domain([-52, 890])
            .range([0, height]);

        const courtWidth = 50;
        const scaleCourtX = d3.scaleLinear()
            .domain([0, courtWidth])
            .range([0, width]);

        const courtLength = 94;
        const scaleCourtY = d3.scaleLinear()
            .domain([0, courtLength])
            .range([0, height]);

        svg.append('rect')
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .style('fill', 'none')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', height)
        // 3pt short line
        svg.append('line')
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .attr('x1', scaleCourtX(3))
        .attr('y1', 0)
        .attr('x2', scaleCourtX(3))
        .attr('y2', scaleCourtY(14))
        svg.append('line')
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .attr('x1', scaleCourtX(courtWidth-3))
        .attr('y1', 0)
        .attr('x2', scaleCourtX(courtWidth-3))
        .attr('y2', scaleCourtY(14))

        const arcGenerator = d3.arc();

        svg.append('path')
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .style('fill', 'none')
        .attr("transform", `translate(${scaleCourtX(courtWidth/2)},${scaleCourtY(4.75)})`)
        .attr('d', arcGenerator({
            startAngle: 0.625 * Math.PI,
            endAngle: 1.375 * Math.PI,
            innerRadius: scaleCourtX(23.75),
            outerRadius: scaleCourtX(23.75)
        }));
        // const curve = d3.line().curve(d3.curveNatural);
        // const points : [number, number][] = [[scaleCourtX(3), scaleCourtY(14)], [scaleCourtX(courtWidth/2), scaleCourtY(28.5)], [scaleCourtX(courtWidth-3),  scaleCourtY(14)]];
        // svg.append('path')
        // .attr('d', curve(points))
        // .attr('stroke', 'white')
        // with multiple points defined, if you leave out fill:none,
        // the overlapping space defined by the points is filled with
        // the default value of 'black'
        // .attr('fill', 'none');

        // painted area
        svg.append('rect')
        .attr('x', scaleCourtX(19))
        .attr('y', 0)
        .attr('width', scaleCourtX(12))
        .attr('height', scaleCourtY(19))
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .style('fill', 'none')

        // backboard
        svg.append('line')
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .attr('x1', scaleCourtX(courtWidth/2-3))
        .attr('y1', scaleCourtY(4))
        .attr('x2', scaleCourtX(courtWidth/2+3))
        .attr('y2', scaleCourtY(4))

        // rim
        svg.append('circle')
        .style('stroke', 'white')
        .style('stroke-width', 1)
        .style('fill', 'none')
        .attr('r', scaleCourtY(0.75))
        .attr('cx', scaleCourtX(courtWidth/2))
        .attr('cy', scaleCourtY(4.75))

        // svg.append("svg:image")
        // .attr("id","image-url")
        // .attr("xlink:href", ShotChartBG)
        // .attr("width", width)
        // .attr("height", height/2);
        let tooltipDeprecated = svg
        .append("div")
        .style("position", "absolute")
        .style("z-index", "10")
        .style("visibility", "visible")
        .classed('tooltip', true)

        node.enter()
            .append("svg:circle")
            // .style('stroke-width', 2)
            // .style('stroke', d => {
            //     if (d.isMadeShot) {
            //         return d.is3Pt ? '#34eb3a' : 'green';
            //     } else  {
            //         if (d.playDesc.includes('blocked')) // TODO fix
            //             return '#ff0000';
            //         else
            //             return d.is3Pt ? '#b80202' : '#4d0000';
            //     }
            // })
            .attr('title', d => d.playDesc)
            // .attr('fill', '#E13A3E')
            .attr('fill', d => {
                if (d.eventMsgType === 1) return 'green';
                else if (d.eventMsgType === 2) return 'orange';
                else if (d.eventMsgType === 5) return 'red';
                else if (d.eventMsgType === 6) return 'yellow';
                if (d.shotType == ShotType.Floater)
                    return '#a8a300'; // 
                else if (d.shotType == ShotType.Jumper)
                    return '#680091'; // 
                else if (d.shotType == ShotType.MoveJumper)
                    return '#00919c'; // 
                //            return '#424242'; // light gray
                else// if (d.shotType == ShotType.Rim)
                    return '#05b038'; // 
                // if (d.shotType == ShotType.Floater)
                //     return '#2b2b2b'; // pretty dark grey
                // else if (d.shotType == ShotType.Jumper)
                //     return '#999999'; // very light grey
                // else if (d.shotType == ShotType.MoveJumper)
                //     return '#474747'; // light gray
                // //            return '#424242'; // light gray
                // else// if (d.shotType == ShotType.Rim)
                //     return 'black'; // darkest possible
            })
            .attr('class', 'play-dot')
            .attr("r", 5)
            .attr("cx", function (d) {
                if (d.locX)
                    return scaleX(d.locX);
                else
                    return null;
            })
            .attr("cy", function (d) {
                if (d.locY)
                    return scaleY(d.locY);
                else
                    return null;
            })
            .on("mouseover", d => tooltipDeprecated
                .style("visibility", "visible")
                .append('p')
                .classed("tooltip", true)
                .text(d.playDesc)
            )
            .on("mouseout", () => tooltipDeprecated.style("visibility", "visible")
                .selectAll('p')
                .remove()
            )



        // .attr("class", function(d) { return classByShot(d);})
        // .style("fill", function(d) { return colorValue(d);});

        // var courtBGUrl = "{% static 'images/court.jpg' %}";
        // svg.append("svg:defs")
        // .append("svg:pattern")
        // .attr("id", "bg")
        // .attr('patternUnits', 'userSpaceOnUse')
        // .attr("width", max.x)
        // .attr("height", max.y)
        // .append("svg:image")
        // .attr("id","image-url")
        // .attr("xlink:href", courtUrl)
        // .attr("width", max.x)
        // .attr("height", max.y);
    }, props.data);
    return (<svg ref={svgRef}
        width={width} height={height}
    ></svg>);
}

interface shotMapProps {
    data: pbpVideo[]
}

// const ShotMapTest = () => ShotMap({data: testData.map(x => {
//     return {...x, eventNum: 0}
//     })});


export default ShotMap;