export const downloadSvgAsPng = (svg: SVGSVGElement, fileName: string) => {
    // CSS must be explicitly embedded
    const style = createStyleElementFromCSS();
    svg.insertBefore(style, svg.firstChild);

    const data = (new XMLSerializer()).serializeToString(svg);
    const svgBlob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8'
    });

    style.remove();
    // newText.remove();

    // convert the blob object to a dedicated URL
    const url = URL.createObjectURL(svgBlob);

    // load the SVG blob to a flesh image object
    const img = new Image();

    img.src = url;
    img.addEventListener('load', () => {
        // draw the image on an ad-hoc canvas
        const bbox = svg.getBoundingClientRect();
        const canvas = document.createElement('canvas');
        canvas.width = bbox.width;
        canvas.height = bbox.height;

        const context = canvas.getContext('2d');
        if (context === null) throw new Error("Context is null")
        context.drawImage(img, 0, 0, bbox.width, bbox.height);

        URL.revokeObjectURL(url);

        // trigger a synthetic download operation with a temporary link
        const a = document.createElement('a');
        a.download = fileName + '.jpg';
        document.body.appendChild(a);
        a.href = canvas.toDataURL();
        a.click();
        a.remove();
    });
};


const createStyleElementFromCSS = () => {
    // assume index.html loads only one CSS file in <header></header>
    const sheets = document.styleSheets;
    const styleRules = [];
    for (let i = 0; i < sheets.length; i++) {
        const sheet = sheets[i];
        // return if this has a url, and the url is not THL
        if (sheet.href !== null && !sheet.href?.includes('thehighlow.io') && !sheet.href?.includes('localhost:44329')) continue;
        for (let i = 0; i < sheet.cssRules.length; i++) {
            const styleRule = sheet.cssRules.item(i);
            if (styleRule !== null) styleRules.push(styleRule.cssText);
        }
    }

    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(styleRules.join(' ')))

    return style;
};