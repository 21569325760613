import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";

interface optionsToggleProps {
    spoilers: boolean,
    toggleSpoilers: () => void,
    fullscreen: boolean,
    toggleFullscreen: () => void,
    randomize: boolean,
    toggleRandomize: () => void,
}
export default function OptionsToggle(props: optionsToggleProps) {
    return (<FormGroup>
        <FormControlLabel sx={{ marginLeft: '0px' }} control={<Switch size="small" checked={props.spoilers} onChange={props.toggleSpoilers} />} label="Spoilers" />
        <FormControlLabel sx={{ marginLeft: '0px' }} control={<Switch size="small" checked={props.fullscreen} onChange={props.toggleFullscreen} />} label="Fullscreen video" />
        <FormControlLabel sx={{ marginLeft: '0px' }} control={<Switch size="small" checked={props.randomize} onChange={props.toggleRandomize} />} label="Randomize" />
    </FormGroup>);
}