import { createContext, useContext } from "react";

import * as ApiUtils from './api-utils';

export interface dataContext {
    gamesBySeason: ApiUtils.gameBySeason,
    wgamesBySeason: ApiUtils.gameBySeason,
    playersBySeason: ApiUtils.playerBySeason,
    wplayersBySeason: ApiUtils.playerBySeason,
    seasonTeamPlayers: ApiUtils.seasonTeamPlayerTransport,
    wseasonTeamPlayers: ApiUtils.seasonTeamPlayerTransport,
    shotTypes: ApiUtils.shotTypes,
    mediaVids: ApiUtils.mediaVidHeavyBySeason,
    wMediaVids: ApiUtils.mediaVidHeavyBySeason,
}

export const DefaultDataContext = () => {
    return {
        gamesBySeason: {},
        wgamesBySeason: {},
        playersBySeason: {},
        wplayersBySeason: {},
        seasonTeamPlayers: {
            seasonTeamPlayer: {},
            teamNamesById: {},
            teamAbbrById: {}
        },
        wseasonTeamPlayers: {
            seasonTeamPlayer: {},
            teamNamesById: {},
            teamAbbrById: {}
        },
        shotTypes: {},
        mediaVids: {},
        wMediaVids: {},
    };
};

export const DataContext = createContext<dataContext>(DefaultDataContext());

export const useDataContext = () => useContext(DataContext)