export interface pbpQuery {
    queryType: QueryType,
    childQueries?: pbpQuery[],
    gameIds?: number[],
    filters?: string,
    shotFilters?: string,
    playerId?: number,
    comparerValue?: number,
    comparerTime?: string,
    comparer?: string,
    teamId?: number,
    homeTeamId?: number,
    awayTeamId?: number,
    teamIsHome?: boolean,
    teamIsAway?: boolean,
    quarters?: string[],
    playerIds?: number[],
    locs?: string[],
    videoType?: string
}

export interface pbpQueryUI extends pbpQuery {
    displayText?: string,
    childQueries?: pbpQueryUI[]
}

export interface pbpQueryMenu extends pbpQuery {
    id: number,
    isAnd: boolean,
    isValid: boolean
}

export const convertToPbpQueryUI = (item: pbpQueryMenu) => {
    const toReturn = { ...item } as any;
    delete toReturn["id"];
    delete toReturn["isAnd"];
    delete toReturn["isValid"];
    return toReturn as pbpQueryUI;
}
export enum QueryType {
    OR = "or",
    AND = "and",
    GAMEID = "gameId",
    LINEUP = "lineup",
    LOC = "loc",
    PLAY = "play",
    QUARTER = "quarter",
    SCORE_DIFF = "scoreDiff",
    SHOT_DIST = "shotDist",
    TEAM = "team",
    TEAM2 = "team2",
    TIME = "time"
}

export const createGameIdQuery = (gameIds: number[]): pbpQuery => {
    return {
        queryType: QueryType.GAMEID,
        gameIds
    } as pbpQuery;
};

export const createGamesQuery = (gameIds: number[], playerId?: number, videoType?: string): pbpQuery => {
    return {
        queryType: QueryType.GAMEID,
        gameIds,
        playerId,
        videoType
    } as pbpQuery;
};

export const createPlayerPlaysQuery = (playerId: number | undefined, filters: string, shotFilters: string, comparerValue: number, comparer: string): pbpQuery => {
    return {
        queryType: QueryType.PLAY,
        playerId,
        filters,
        shotFilters,
        comparerValue,
        comparer
    };
};

export const createScoreDiffQuery = (comparerValue: number, comparer: string): pbpQuery => {
    return {

        queryType: QueryType.SCORE_DIFF,
        comparerValue,
        comparer
    };
};

export const createQuarterTimeQuery = (comparerTime: string, comparer: string): pbpQuery => {
    return {
        queryType: QueryType.TIME,
        comparerTime,
        comparer
    };
}

export const createTeamQuery = (teamId: number, teamIsHome: boolean, teamIsAway: boolean): pbpQuery => {
    return {
        queryType: QueryType.TEAM,
        teamId,
        teamIsHome,
        teamIsAway
    }
};

export const createTeam2Query = (homeTeamId: number | undefined, awayTeamId: number | undefined): pbpQuery => {
    return {
        queryType: QueryType.TEAM2,
        homeTeamId,
        awayTeamId
    }
};
export const createQuarterQuery = (quarters: string[]): pbpQuery => {
    return {
        queryType: QueryType.QUARTER,
        quarters
    };
};

export const createLineupQuery = (playerIds: number[], teamId: number): pbpQuery => {
    return {
        queryType: QueryType.LINEUP,
        playerIds,
        teamId
    };
};

export const createLocQuery = (locs: string[]): pbpQuery => {
    return {
        queryType: QueryType.LOC,
        locs
    }
};

export const convertComparerValToSymbol = (val: string) => {
    if (val === 'gte') return '≥';
    else if (val === 'gt') return '>';
    else if (val === 'lt') return '<';
    else if (val === 'lte') return '≤';
    else throw new Error('Unrecognized value: ' + val);
};
