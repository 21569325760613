import React, { useState } from 'react';
import Modal from 'react-modal';

import './search.css';
import * as ComponentUtils from '../utils/component-utils';
import * as PbpQueryUtils from '../utils/pbp-query-utils';
import {useDataContext} from '../utils/data-context';


import LeagueSeasonSelector from './common/league-season-selector';
import QueryModal from './query/query-modal';
import OrderEarliestLatestSelector from './common/order-earliest-latest-selector';

Modal.setAppElement('#root');

const QueryType = PbpQueryUtils.QueryType;

const _validateCleanQuery = (query: PbpQueryUtils.pbpQueryUI) => {
    let numChanges = 0;
    if (query.childQueries?.length !== undefined) {
        let initialLength = query.childQueries.length;
        query.childQueries = query.childQueries.filter(cq => cq.childQueries?.length === undefined  || cq.childQueries.length > 0);
        numChanges = initialLength - query.childQueries.length;
        query.childQueries.forEach(x => numChanges += _validateCleanQuery(x));
    }
    return numChanges;
};

const validateCleanQuery = (query: PbpQueryUtils.pbpQueryUI) => {
    let numChanges = 1;
    while (numChanges > 0) {
        numChanges = _validateCleanQuery(query);
    }
}

const createBaseQuery = () => {
    return {
        queryType: QueryType.AND, displayText: 'And', childQueries: []    
    };
};

function QuerySearch(props: querySearchProps) {
    const [selectedLeague, setSelectedLeague] = useState('nba');
    const [selectedSeason, _setSelectedSeason] = useState(ComponentUtils.getLatestSeason('nba'));
    const [orderByTime, setOrderByTime] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [queryState, setQuery] = useState<PbpQueryUtils.pbpQueryUI>(
        createBaseQuery()
    );

    
    const data = useDataContext();

    const refreshQuery = () => setQuery({ ...queryState });

    const setLeagueAndSeason = (league: string) => {
        setSelectedLeague(league);
        ComponentUtils.resetSelectedSeason(league, setSelectedSeason);
    };

    const setSelectedSeason = (season: string) => {
        setQuery(createBaseQuery());
        _setSelectedSeason(season);
    };
    const leagueGames = selectedLeague === 'nba' ? data.gamesBySeason : data.wgamesBySeason;
    const gamesToUse = leagueGames[selectedSeason];
    const playersToUse = selectedLeague === 'nba' ? data.playersBySeason  : data.wplayersBySeason;
    const seasonTeamPlayerTransport = selectedLeague === 'nba' ? data.seasonTeamPlayers  : data.wseasonTeamPlayers;
    const teamPlayers = seasonTeamPlayerTransport.seasonTeamPlayer[selectedSeason];
    const teamsToUse = Object.keys(teamPlayers).map(id => {
        return {id, name: seasonTeamPlayerTransport.teamNamesById[id]};
    });
    const [addQueryStatement, setAddQueryStatement] = useState(() => (query: PbpQueryUtils.pbpQueryUI) => { });

    function renderQuery(query: PbpQueryUtils.pbpQueryUI, depth: number, index: number, removeFromParentArray?: () => void) {
        const isOr = query.queryType === QueryType.OR;
        const isAndOr = isOr || query.queryType === QueryType.AND;
        const toggleAndOr = () => {
            query.queryType = isOr ? QueryType.AND : QueryType.OR;
            refreshQuery();
        };
        const andClass = ' action-button ' + (isOr ? '' : 'active-button action-button');
        const orClass = ' action-button ' + (isOr ? 'active-button' : '');
        let queryContent = isAndOr ? (
            <React.Fragment>
                <button className={andClass} onClick={toggleAndOr}>And</button>/
                <button className={orClass + ' margin-right-15'} onClick={toggleAndOr}>Or</button>
            </React.Fragment>) :
            (<React.Fragment>{query.displayText}</React.Fragment>);
        if (index >= 0) {
            queryContent = (<React.Fragment>
                <div className='query-content'>{queryContent}</div>
                <button className='action-button remove-query-button' onClick={removeFromParentArray}>x</button>
            </React.Fragment>);
        };

        let containerClass = '';
        if (isAndOr) containerClass += ' andor-container ';
        if (depth >= 1) containerClass += ' inner-query ';
        return (<div key={`${depth}${index}queryStatement`} className={containerClass}>
            <div className='query'>
                {queryContent}
                {
                    query.childQueries && isAndOr && <React.Fragment>
                        <button className='action-button' onClick={() => {
                            setShowModal(true);
                            setAddQueryStatement(() => (newQuery: PbpQueryUtils.pbpQueryUI) => {
                                query.childQueries?.push(newQuery);
                                refreshQuery();
                                setShowModal(false);
                            })
                        }}>+ Filter</button>
                        {
                            depth < 2 &&
                            <button className='action-button' onClick={() => {
                                query.childQueries?.push({ queryType: QueryType.AND, displayText: '', childQueries: [] });
                                refreshQuery();
                            }}>+ And/Or Group</button>
                        }
                    </React.Fragment>
                }
            </div>
            {query.childQueries && query.childQueries.map((x, i) =>
                renderQuery(x, depth + 1, i, () => {
                    query.childQueries?.splice(i, 1);
                    refreshQuery();
                })
            )}
        </div>);
    }

    return (<div style={props.visible ? {} : { display: 'none' }}>
        <div className='search-field-container'>
            <div>
                <LeagueSeasonSelector
                    selectedLeague={selectedLeague}
                    setLeagueAndSeason={setLeagueAndSeason}
                    selectedSeason={selectedSeason}
                    seasons={Object.keys(leagueGames)}
                    setSelectedSeason={setSelectedSeason}
                />
            </div>
            <div>
                <button className={props.spoilers ? 'action-button active-button' : 'action-button'} onClick={() => props.toggleSpoilers()}>Spoilers</button>
                <button className={showHelp ? 'action-button active-button' : 'action-button'} onClick={() => setShowHelp(!showHelp)}>Help!</button>
            </div>
        </div>
        {
            showHelp && <div className='help'>
                <p>This tab lets you search for plays that match one (or all) of the filters you select. For example, to search for Smart assisting Tatum, you would add one filter for Tatum's made shots, combined in an AND group with one filter for Smart's assists.</p>
                <p>AND: Every filter must be true for a play to appear in the results</p>
                <p>OR: One filter must be true for a play to appear in the results</p>
                <p>The <u>+Filter</u> button lets you add a filter to the group. The <u>+And/Or</u> button adds a new group. A group has a box around it. Each group of filters can be either And/Or, and groups can contain groups</p>
            </div>
        }
        <div className='all-filters-container'>
            <OrderEarliestLatestSelector
                orderByTime={orderByTime}
                setOrderByTime={setOrderByTime} />
        </div>
        <div className='query-container'>
            {
                renderQuery(queryState, 0, -1)
            }
        </div>
        <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            className='modal-content'
            overlayClassName='modal-overlay'
            >
            <button className='action-button close-modal' onClick={() => setShowModal(false)}>x</button>
            <QueryModal
                selectedLeague={selectedLeague}
                selectedSeason={selectedSeason}
                games={gamesToUse}
                players={playersToUse[selectedSeason]}
                teams={teamsToUse}
                teamPlayers={teamPlayers}
                shotTypes={data.shotTypes} // TODO connect this to DataContext
                spoilers={props.spoilers}
                addQueryStatement={addQueryStatement} />
        </Modal>
        {props.children}
        <div className='search-field-container'>
            <button className='action-button submit-button' onClick={() => {
                validateCleanQuery(queryState);
                refreshQuery();
                if (queryState.childQueries?.length === 0) {
                    alert('Must have at least one filter');
                    return;
                }
                props.onSelectItem(queryState, selectedLeague === 'nba', selectedSeason, orderByTime);
            }}>Search</button>
        </div>
    </div>);
}

interface querySearchProps {
    visible: boolean,
    onSelectItem: (data: {}, isNba: boolean, season: string, ordsq: string) => void,
    spoilers: boolean,
    toggleSpoilers: () => void,
    children?: React.ReactNode
}

export default QuerySearch;