import '../search.css';

function SelectedItem(props: selectedItemProps) {
    return (<button className='search-fields search-text action-button selected-item' onClick={() => props.onDelete()}>
    x&nbsp;&nbsp;&nbsp;&nbsp;{props.text}
    </button>
    );
}

interface selectedItemProps {
    text: string,
    onDelete: () => void
}

export default SelectedItem;