import * as ApiUtils from './api-utils';

const urlPrefix = ApiUtils.urlPrefix + "user/";

export interface loginExpiration {
    expirationTime : string
}

export const login = async (username: string, password: string) => {
    const data = {username, password};
    const response = await fetch(`${urlPrefix}login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    if (response.status === 401) return false;
    const expiration : loginExpiration = await response.json();
    return true;
    // console.log('expiration', expiration);
    // return new Date(expiration.expirationTime);
};

export const tryLogin = async () => {
    const response = await fetch(`${urlPrefix}authorize`);
    if (response.status === 401) return false;
    return true;
}

export const changePassword = async (token: string, password: string) => {
    const data = {token, password};
    const res = await fetch(`${urlPrefix}password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    if (res.status > 399) return false;
    return true;
};

export const resetPassword = async (username: string) => {
    await fetch(`${urlPrefix}reset?username=${username}`);
    return true;
};
