import { game, pbpVideo } from './api-utils';
export const renderGameText = (game: game) => {
    let splitHomeTeam = game.homeTeam.split(' ');
    let splitAwayTeam = game.awayTeam.split(' ');
    let homeTeamForUse = splitHomeTeam[splitHomeTeam.length - 1];
    let awayTeamForUse = splitAwayTeam[splitAwayTeam.length - 1];
    if (game.homePts > game.awayPts)
        return `${homeTeamForUse} ${game.homePts} v. ${game.awayPts} ${awayTeamForUse} ${game.gameDate}`;
    else
        return `${awayTeamForUse} ${game.awayPts} @ ${game.homePts} ${homeTeamForUse} ${game.gameDate}`;
}

export const renderGameTextFromFinalPbpVideo = (pbpVideo: pbpVideo, spoilers: boolean) => {
    let splitHomeTeam = pbpVideo.homeTeam.split(' ');
    let splitAwayTeam = pbpVideo.awayTeam.split(' ');
    let homeTeamForUse = splitHomeTeam[splitHomeTeam.length - 1];
    let awayTeamForUse = splitAwayTeam[splitAwayTeam.length - 1];
    const homePoints = spoilers ? pbpVideo.homeScore : '';
    const awayPoints = spoilers ? pbpVideo.awayScore : '';
    return `${homeTeamForUse} ${homePoints} v. ${awayPoints} ${awayTeamForUse} ${pbpVideo.gameDate}`;
};

export const renderGameTextFromFinalPbpVideoForFilesystem = (pbpVideo: pbpVideo, spoilers: boolean) => {
    let splitHomeTeam = pbpVideo.homeTeam.split(' ');
    let splitAwayTeam = pbpVideo.awayTeam.split(' ');
    let homeTeamForUse = splitHomeTeam[splitHomeTeam.length - 1];
    let awayTeamForUse = splitAwayTeam[splitAwayTeam.length - 1];
    const homePoints = spoilers ? pbpVideo.homeScore : '';
    const awayPoints = spoilers ? pbpVideo.awayScore : '';
    return `${homeTeamForUse} ${homePoints} v. ${awayPoints} ${awayTeamForUse} ${pbpVideo.gameDate.replaceAll('/', '-')}`;
};

export const renderGameTextNoSpoilers = (game: game) => {
    let splitHomeTeam = game.homeTeam.split(' ');
    let splitAwayTeam = game.awayTeam.split(' ');
    let homeTeamForUse = splitHomeTeam[splitHomeTeam.length - 1];
    let awayTeamForUse = splitAwayTeam[splitAwayTeam.length - 1];
    return `${awayTeamForUse} @ ${homeTeamForUse} ${game.gameDate}`;
};

export const searchGames = (game: game, query: string) => {
    const splitQuery = query.split(/[\s*]+/).map(x => x.toLocaleLowerCase());
    return splitQuery.every(queryPart => {
        return game !== null && game !== undefined && (game.homeTeam.toLocaleLowerCase().includes(queryPart) ||
            game.awayTeam.toLocaleLowerCase().includes(queryPart) ||
            game.dateSearch.toLocaleLowerCase().includes(queryPart) || game.gameDate.includes(queryPart) || game.homePts.toString() === queryPart || game.awayPts.toString() === queryPart)
    });
};

export const renderFileNameFromPbpVideo = (pbpVideo: pbpVideo) => {
    return `${pbpVideo.awayTeam}@${pbpVideo.homeTeam}_${pbpVideo.gameDate.replaceAll('/', '-')}_${pbpVideo.period}Q_${pbpVideo.gameClock.replace(':', '\uA789')}`;
}