import { useState } from 'react';
// import logo from './logo.svg';

import * as ComponentUtils from '../../utils/component-utils';


import { useDataContext } from '../../utils/data-context';

import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import GameSelector from './GameSelector';
import PlayerSelector from './PlayerSelector';
import List from '@mui/material/List';
import FormControl from '@mui/material/FormControl';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import QuarterSelector from './QuarterSelector';
import NumCompareSelector from './NumCompareSelector';
import QuarterTimeSelector from './QuarterTimeSelector';
import InputLabel from '@mui/material/InputLabel';
import LineupSelector from './LineupSelector';
import TeamSelector from './TeamSelector';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { game, player, teamPlayers } from '../../utils/api-utils';
import { pbpQueryMenu } from '../../utils/pbp-query-utils';
import LocationSelector from './LocationSelector';

export enum SelectorType {
    GAME = "Game",
    PLAY = "Play/player",
    LINEUP = "Lineup",
    TEAM = "Team",
    QUARTER = "Quarter",
    TIME = "Quarter time",
    LOC = "Play location",
    SCORE = "Score diff",
}

const selectorTypes = [
    SelectorType.GAME,
    SelectorType.PLAY,
    SelectorType.TEAM,
    SelectorType.LINEUP,
    SelectorType.QUARTER,
    SelectorType.TIME,
    SelectorType.LOC,
    SelectorType.SCORE,
];

interface videoSearchFilterProps {
    games: game[],
    players: player[],
    showClosestDefender: boolean,
    teams: { id: string, name: string }[],
    teamPlayers: teamPlayers,
    isFirst: boolean,
    isOnly: boolean,
    removeFilter: () => void,
    setQuery: (query: pbpQueryMenu) => void,
    spoilers: boolean
}

export default function VideoSearchFilter(props: videoSearchFilterProps) {
    const [selectorType, setSelectorType] = useState(SelectorType.GAME);
    const [boolOperator, setBoolOperator] = useState("and");

    const setQuery = (query: pbpQueryMenu) => {
        props.setQuery({ ...query, isAnd: boolOperator === "and" });
    };
    return (
        <>
            <Grid container sx={{ textAlign: 'left', marginTop: '10px', marginBottom: '5px', alignItems: "flex-start"}}>
                <Grid item container xs={12} sm={6} marginBottom='5px' justifyContent={{ "sm": props.isOnly ? "flex-end" : "space-between" }} display={{ "sm": "flex" }}>
                    {!props.isOnly && <IconButton aria-label="delete" size="small" onClick={props.removeFilter}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>}
                    <Box>
                        {
                            !props.isFirst && <Select
                                size="small"
                                value={boolOperator}
                                onChange={e => setBoolOperator(e.target.value)}>
                                <MenuItem value={"and"}>And</MenuItem>
                                <MenuItem value={"or"}>Or</MenuItem>
                            </Select>
                        }
                        <FormControl>
                            <InputLabel>Filter</InputLabel>
                            <Select
                                label="Filter"
                                size="small"
                                value={selectorType}
                                onChange={e => setSelectorType(e.target.value as SelectorType)}>
                                {
                                    selectorTypes.map(type => <MenuItem key={`selector${type}`} value={type}>{type}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item container xs={12} sm={6} alignItems="center">
                    {selectorType === SelectorType.GAME && <GameSelector games={props.games} players={props.players} spoilers={props.spoilers} setQuery={setQuery} />}
                    {selectorType === SelectorType.PLAY && <PlayerSelector playerOptional={true} showClosestDefender={props.showClosestDefender} players={props.players} setQuery={setQuery} />}
                    {selectorType === SelectorType.QUARTER && <QuarterSelector setQuery={setQuery} />}
                    {selectorType === SelectorType.SCORE && <NumCompareSelector numInputName="Score" onChange={(comparer, num) => setQuery} />}
                    {selectorType === SelectorType.TIME && <QuarterTimeSelector setQuery={setQuery} />}
                    {selectorType === SelectorType.LINEUP && <LineupSelector teamPlayers={props.teamPlayers} teams={props.teams} setQuery={setQuery} />}
                    {selectorType === SelectorType.TEAM && <TeamSelector teams={props.teams} setQuery={setQuery} />}
                    {selectorType === SelectorType.LOC && <LocationSelector setQuery={setQuery} />}
                </Grid>
            </Grid>
        </>
    );
}