import { player } from '../../utils/api-utils';
import React from 'react';
import '../search.css';

function PlayerSelector(props: playerSelectorProps) {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [focused, setFocused] = React.useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const searchTermLower = searchTerm.toLocaleLowerCase();
    let placeholder = 'Search by player/team';
    if (props.displayText) placeholder = props.displayText;
    return (<React.Fragment>
        <input type='text' className='search-fields search-text'
            onFocus={onFocus} onBlur={onBlur}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
            placeholder={placeholder} />
        {
            (focused || searchTerm.length > 0) && <div className='search-results-container' style={{zIndex: 2}}>
                {
                    props.players
                        .filter(player => (
                            player.name.toLocaleLowerCase().includes(searchTermLower) ||
                            player.teamAbbrs.toLocaleLowerCase().includes(searchTermLower) ||
                            player.teamNames.toLocaleLowerCase().includes(searchTermLower)))
                        .map((player, i) => {
                            const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                props.onSelectItem(player);
                                setSearchTerm('');
                            };
                            return (<button className='search-result' key={`player${i}`} onMouseDown={onClick}>{player.name} ({player.teamAbbrs})</button>);
                        })
                }
            </div>
        }
    </React.Fragment>);
}

interface playerSelectorProps {
    players: player[],
    displayText?: string,
    onSelectItem: (player: player) => void
}

export default PlayerSelector;