
import {useState} from 'react';
import './search.css';
import {mediaVidHeavy} from '../utils/api-utils';
import * as ComponentUtils from '../utils/component-utils';
import {useDataContext} from '../utils/data-context';
import { renderGameText, searchGames } from '../utils/game-selector-utils'

function searchMedia(media: mediaVidHeavy, query: string) {
    const splitQuery = query.split(/[\s*]+/).map(x => x.toLocaleLowerCase());
    return splitQuery.every(queryPart => {
        return media.title.toLowerCase().includes(queryPart) || searchGames(media.game, queryPart);
    });
}

function MediaSearch(props: mediaSearchProps) {
    const [selectedLeague, setSelectedLeague] = useState('nba');
    const [selectedSeason, setSelectedSeason] = useState(ComponentUtils.getLatestSeason('nba'));
    const [searchTerm, setSearchTerm] = useState('');

    const data = useDataContext();
    const setLeagueAndSeason = (league: string) => {
        setSelectedLeague(league);

        ComponentUtils.resetSelectedSeason(league, setSelectedSeason);
    };
    const mediaToUse = selectedLeague === 'nba' ? data.mediaVids : data.wMediaVids;

    const filteredMedia = mediaToUse[selectedSeason]?.filter(media => searchTerm.length > 0 && (searchMedia(media, searchTerm))) ?? [];
    return (<div style={props.visible ? {} : { display: 'none' }}>
        <div className='search-field-container'>
            {/* <LeagueSeasonSelector
                selectedLeague={selectedLeague}
                setLeagueAndSeason={setLeagueAndSeason}
                selectedSeason={selectedSeason}
                seasons={Object.keys(gamesToUse)}
                setSelectedSeason={setSelectedSeason}
            /> */}
        <input type='text' className='search-fields search-text'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
            placeholder='Search by team, score, date, person' />
        {
            filteredMedia.length > 0 && <div className='search-results-container'>
                {
                    filteredMedia.map((media, i) => {
                        const onClick = () => window.open(media.url, '_blank');
                        return (<button className='search-result' key={`filteredGames${i}`} onClick={onClick}>
                            {media.title}&nbsp;&nbsp;
                            <span className='play-desc-misc'>({renderGameText(media.game)})</span></button>);
                    })
                }
            </div>
        }
        </div>
    </div>);
}


// function searchMedia(game: game, query: string) {
//     const splitQuery = query.split(/[\s*]+/).map(x => x.toLocaleLowerCase());
//     return splitQuery.every(queryPart => {
//         return game.homeTeam.toLocaleLowerCase().includes(queryPart) ||
//             game.awayTeam.toLocaleLowerCase().includes(queryPart) ||
//             game.dateSearch.toLocaleLowerCase().includes(queryPart) || game.gameDate.includes(queryPart) || game.homePts.toString() === queryPart || game.awayPts.toString() === queryPart
//     });
// }


interface mediaSearchProps {
    visible: boolean
}

export default MediaSearch;