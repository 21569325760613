import React, { Fragment, useState, useEffect } from 'react';
import Modal from 'react-modal';

// import * as PbpQueryUtils from '../utils/pbp-query-utils';
import { useUserContext } from '../../utils/user-context';
import * as ApiUserUtils from '../../utils/api-user-utils';
import * as CookieUtils from '../../utils/cookie-utils';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
Modal.setAppElement('#root');

function Login(props: loginProps) {
    const { authenticated, setAuthenticated } = useUserContext();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [lastLoginFailed, setLastLoginFailed] = useState(false);
    const [didReset, setDidReset] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);

    const tryLogin = async () => {
        try {
            if (CookieUtils.hasJwtCookie()) {
                const didLogin = await ApiUserUtils.tryLogin();
                if (didLogin) setAuthenticated(true);
            }
        } catch (e) {
            console.error(e);
        }
        return true;
    };
    useEffect(() => {
        tryLogin();
    }, []);
    const onLogin = async () => {
        try {
            console.log('authenticated', authenticated)
            const didLogin = await ApiUserUtils.login(username, password);
            console.log(didLogin);
            if (!didLogin) {
                setLastLoginFailed(true);
            }
            else {
                setAuthenticated(true);
                setUsername('');
                setPassword('');
                props.onAction();
            }
        } catch (e) {
            alert("An error occurred. Please try again later")
            console.log(e);
        }
    };

    const onReset = async () => {
        try {
            const result = await ApiUserUtils.resetPassword(username);
            setDidReset(true);
        } catch (e) {
            alert("An error occurred. Please try again later")
            console.log(e);
        }
    };

    return (<React.Fragment>
        <ToggleButtonGroup
            value={isResetPassword}
            exclusive
            onChange={(e, newVal) => setIsResetPassword(newVal)}
            aria-label="Login or reset password"
            sx={{ justifyContent: 'center', width: '100%', marginRight: '48px' }}
        >
            <ToggleButton value={false} aria-label="Video search">
                Login
            </ToggleButton>
            <ToggleButton value={true} aria-label="Query search">
                Reset Password
            </ToggleButton>
        </ToggleButtonGroup>
        <div className='margin-top margin-bottom'>
            <input type='text' className='search-fields search-text'
                value={username}
                onChange={e => setUsername(e.target.value)}
                placeholder={"Email"} />
        </div>
        {
            !isResetPassword && <div className='margin-top margin-bottom'>
                <input type='password' className='search-fields search-text'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder={"Password"} />
            </div>
        }
        {!isResetPassword ?
            lastLoginFailed && <p>Email or password was incorrect</p> :
            didReset && <Fragment>
                <p>If the email address is a valid user, we will send an email with reset instructions.</p>
            </Fragment>
        }
        <div className='margin-top'>
            {
                !isResetPassword ?
                    <button className='action-button submit-button' onClick={onLogin}>Login</button>
                    :
                    <button className='action-button submit-button' onClick={onReset}>Reset</button>
            }
        </div>
        <div className='margin-top margin-bottom'>
            <a className='white-link' href='https://ko-fi.com/thehighlow/tiers' target='_blank'>Subscribe</a> to get an account and access to additional features
        </div>
    </React.Fragment>);
}

interface loginProps {
    onAction: () => void
}

export default Login;