import { useEffect, useState, Fragment } from 'react';
// import logo from './logo.svg';
import * as ApiUtils from './utils/api-utils';
import * as BrowserUtils from './utils/browser-utils';
import * as CookieUtils from './utils/cookie-utils';
import { DataContext, DefaultDataContext } from './utils/data-context';
import { UserContext, DefaultUserContext } from './utils/user-context';
import * as PbpQueryUtils from './utils/pbp-query-utils';
import { useLocation, useRoute } from "wouter";

import GameSearch from './components/game-search';
import PlayerSearch from './components/player-search';
import QuerySearch from './components/query-search';
import LineupSearch from './components/lineup-search';
import MiscSearch from './components/misc-search';
import VideoPlayer from './components/video-player';

import MediaSearch from './components/media-search';
import LivePbp from './components/live-pbp';

import Login from './components/user/login';
import MenuLayout from './components/menu/MenuLayout';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';

enum SearchType {
    Games,
    Players,
    Query,
    Lineups,
    Misc,
    Press,
    Hidden,
    LivePbp,
    Login
}

const minGameId = 0;
const maxGameId = 99999999;
const minPeriod = 0;
const minEventNum = 0;
const maxClock = "12:00";

const defaultDataContext = DefaultDataContext();
const defaultUserContext = DefaultUserContext();

const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: [
            ''
        ].join(',')
    },
    shape: {
        borderRadius: 0
    }
});

function NBApp() {
    const [dataContext, setDataContext] = useState(defaultDataContext);
    const [authenticated, setAuthenticated] = useState(false);
    const usercontextValue = { authenticated, setAuthenticated }
    const [dataContextIsLoaded, setDataContextIsLoaded] = useState(false);
    const [searchType, setSearchType] = useState(SearchType.Games);
    const pbpVideoDefault: ApiUtils.pbpVideo[] = [];
    const [pbpVideo, setPbpVideo] = useState(pbpVideoDefault);
    const [isCallInFlight, setCallInFlight] = useState(false);
    const [spoilers, setSpoilers] = useState(true);
    const isMobile = window.matchMedia('(pointer: coarse)').matches;
    const [fullscreen, setFullscreen] = useState(isMobile);
    const [randomize, setRandomize] = useState(false); 
    const [paginationFunc, setPaginationFunc] = useState<null | (() => void)>(null);
    const [showGameViz, setShowGameViz] = useState(false);
    const [pathIsGameVideo, gameVideoParams] = useRoute(BrowserUtils.GAME_VIDEO_URL);
    const [pathIsGamesVideo, gamesVideoParams] = useRoute(BrowserUtils.GAMES_VIDEO_URL);
    // const [pathIsIdsVideo, idsVideoParams] = useRoute(BrowserUtils.IDS_VIDEO_URL);
    const [pathIsLineupVideo, lineupVideoParams] = useRoute(BrowserUtils.LINEUP_VIDEO_URL);
    const [pathIsPlayerVideo, playerVideoParams] = useRoute(BrowserUtils.PLAYER_VIDEO_URL);
    const [pathIsPlaylistVideo, playlistVideoParams] = useRoute(BrowserUtils.PLAYLIST_VIDEO_URL);
    const [showResults, setShowResults] = useState(true);
    const setLocation = useLocation()[1];
    const [isUrlLinkable, setIsUrlLinkable] = useState(false);
    const admin = BrowserUtils.getURLParams().get('admin');
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    useEffect(() => {
        const onLoadApiCalls = async () => {
            let mediaVids = {} as ApiUtils.mediaVidBySeason;
            let mediaVidsHeavy = {} as ApiUtils.mediaVidHeavyBySeason;
            let gamesBySeasonLocal = {} as ApiUtils.gameBySeason;
            let wGamesBySeasonLocal = {} as ApiUtils.gameBySeason;
            const dataContext = DefaultDataContext();
            const apiCalls = [
                ApiUtils.getGames(true).then(x => {
                    gamesBySeasonLocal = x;
                    dataContext.gamesBySeason = x;
                    return true;
                }),
                ApiUtils.getGames(false).then(x => {
                    dataContext.wgamesBySeason = x;
                    return true;
                }),
                // ApiUtils.getPlayers(true).then(x => setPlayersBySeason(x)).then(() => setIsPlayersLoaded(true)),
                // ApiUtils.getPlayers(false).then(x => setWPlayersBySeason(x)).then(() => setIsWPlayersLoaded(true)),
                ApiUtils.getSeasonTeamPlayers(true).then(x => {
                    dataContext.seasonTeamPlayers = x;
                    dataContext.playersBySeason = ApiUtils.convertSTPToPlayers(x);
                    return true;
                }),
                ApiUtils.getSeasonTeamPlayers(false).then(x => {
                    dataContext.wseasonTeamPlayers = x;
                    dataContext.wplayersBySeason = ApiUtils.convertSTPToPlayers(x);
                    return true;
                }),
                ApiUtils.getShotTypes().then(x => {
                    dataContext.shotTypes = x;
                    return true;
                }),
                ApiUtils.getMediaVids(true).then(x => {
                    mediaVids = x;
                    return true;
                })
            ];
            await Promise.all(apiCalls);
            let gamesByGameId = {} as { [key: string]: ApiUtils.game };
            Object.keys(mediaVids).forEach(season => {
                gamesBySeasonLocal[season].forEach(x => gamesByGameId[x.gameId] = x);
                mediaVids[season].forEach(x => {
                    if (mediaVidsHeavy[season] === undefined) mediaVidsHeavy[season] = [];
                    mediaVidsHeavy[season].push({ ...x, game: gamesByGameId[x.gameId] });
                });
            });
            dataContext.mediaVids = mediaVidsHeavy;
            dataContext.wMediaVids = {};
            setDataContext(dataContext);
            setDataContextIsLoaded(true);
        }
        onLoadApiCalls();
    }, []);
    const onLoadRouteCalls = async () => {
        const urlParams = BrowserUtils.getURLParams();
        const ids = urlParams.get('ids');
        const isNba = urlParams.has('isw') ? false : true;
        const ordsq = urlParams.get('ordsq') ?? '';
        setIsUrlLinkable(pathIsGameVideo || pathIsPlaylistVideo || pathIsLineupVideo || pathIsPlayerVideo);
        if (ids) {
            setIsDrawerOpen(false);
            await fetchIds(ids, isNba);
        } else if (pathIsGameVideo) {
            const params = gameVideoParams as { gameId: string, season: string, league: string }
            setIsDrawerOpen(false);
            await fetchGame(parseInt(params.gameId), params.season, params.league !== 'wnba', urlParams.get('videoType'), urlParams.get('playerId'));
        } else if (pathIsGamesVideo) {
            const params = gamesVideoParams as { gameIds: string, season: string, league: string }
            setIsDrawerOpen(false);
            await fetchGames(params.gameIds, params.season, params.league !== 'wnba', urlParams.get('videoType'), urlParams.get('playerId'));
        } else if (pathIsPlayerVideo) {
            const shotFilters = urlParams.get('shotfilters')?.split(',').map(x => parseInt(x)).filter(x => Number.isInteger(x)) ?? [];
            const params = playerVideoParams as { playerId: string, season: string, filters: string, league: string };
            setIsDrawerOpen(false);
            await fetchPlayer(parseInt(params.playerId), params.season, params.filters.split(','), shotFilters, params.league !== 'wnba', ordsq);
        } else if (pathIsPlaylistVideo) {
            const params = playlistVideoParams as { playlistName: string, season: string, league: string, ordsq: string };
            setIsDrawerOpen(false);
            await fetchPlaylist(params.playlistName, params.season, params.league !== 'wnba', ordsq);
        } else if (pathIsLineupVideo) {
            const off = urlParams.get('off') === 'true';
            const def = urlParams.get('def') === 'true';
            const params = lineupVideoParams as { playerIds: string, season: string, teamId: string, league: string };
            setIsDrawerOpen(false);
            fetchLineup(params.playerIds.split(',').map(x => parseInt(x)), params.league !== 'wnba', params.season, params.teamId, off, def, ordsq);
        }
    };
    useEffect(() => {
        onLoadRouteCalls();
    }, [window.location.href]);

    const setPbpVideoNoPag = (video: ApiUtils.pbpVideo[]) => {
        setPbpVideo(video);
        setPaginationFunc(null);
    };

    const fetchPlaylist = async (playlistName: string, season: string, isNba: boolean, ordsq: string) => {
        setSearchType(SearchType.Hidden);
        setCallInFlight(true);
        try {
            const video = await ApiUtils.getPlaylistVideo(playlistName, isNba, season, ordsq);
            setCallInFlight(false);
            if (video.length === 0) alert('No video found');
            else setPbpVideoNoPag(video);
        } catch (e) {
            alert('An error occurred');
        }
    }
    const fetchGame = async (gameId: number, season: string, isNba: boolean, videoType: string | null, playerId: string | null) => {
        setSearchType(SearchType.Hidden);
        setCallInFlight(true);
        try {
            const video = await ApiUtils.getGameVideo(gameId, season, isNba, videoType, playerId);
            setCallInFlight(false);
            if (video.length === 0) alert('No video found');
            else setPbpVideoNoPag(video);
        } catch (e) {
            alert('An error occurred');
        }
    };
    const fetchGames = async (gameIds: string, season: string, isNba: boolean, videoType: string | null, playerId: string | null) => {
        setSearchType(SearchType.Hidden);
        setCallInFlight(true);
        try {
            const gameIdsInt = gameIds.split(',').map(x => parseInt(x));
            const query = PbpQueryUtils.createGamesQuery(gameIdsInt, playerId ? parseInt(playerId) : undefined, videoType ? videoType : undefined);
            await _onSubmitQuery(query, isNba, season, '', false);
            setCallInFlight(false);
        } catch (e) {
            alert('An error occurred');
        }
    };
    const fetchIds = async (ids: string, isNba: boolean) => {
        setSearchType(SearchType.Hidden);
        setCallInFlight(true);
        try {
            const video = await ApiUtils.getIdVideo(ids, isNba);
            setCallInFlight(false);
            if (video.length === 0) alert('No video found');
            else setPbpVideoNoPag(video);
        } catch (e) {
            alert('An error occurred');
        }
    };
    const fetchPlayer = async (playerId: number, season: string, onFilters: string[], shotFilters: number[], isNba: boolean, ordsq: string) => {
        setSearchType(SearchType.Hidden);
        const filters = onFilters.join();
        const shotFilterJson = shotFilters.join();
        setCallInFlight(true);
        try {
            const video = await ApiUtils.getPlayerVideo(playerId, season, filters, shotFilterJson, isNba, ordsq);
            setCallInFlight(false);
            if (video.length === 0) alert('No video found');
            else setPbpVideoNoPag(video);
        } catch (e) {
            alert('An error occurred');
        }
    }

    const onSelectGame = (gameId: number, season: string, isNba: boolean, videoType: string, playerId: number | undefined) => {
        setLocation(BrowserUtils.createGameVideoUrl(gameId, season, isNba, videoType, playerId));
    }

    const onSelectPlayer = (playerId: number, season: string, onFilters: string[], shotFilters: number[], isNba: boolean, ordsq: string) => {
        setLocation(BrowserUtils.createPlayerVideoUrl(playerId, season, isNba, onFilters.join(), shotFilters.join(), ordsq));
    };

    const onSelectLineup = (playerIds: number[], isNba: boolean, season: string, teamId: string, includeOffense: boolean, includeDefense: boolean, ordsq: string) => {
        setLocation(BrowserUtils.createLineupVideoUrl(season, isNba, parseInt(teamId), playerIds.map(x => x.toString()).join(), ordsq, includeOffense, includeDefense));
    };

    let prevVideo: ApiUtils.pbpVideo[] = [];

    const fetchLineup = async (playerIds: number[], isNba: boolean, season: string, teamId: string, includeOffense: boolean, includeDefense: boolean, ordsq: string) => {
        setSearchType(SearchType.Hidden);
        setCallInFlight(true);
        try {
            const firstVideo = ordsq !== "latest" ?
                await ApiUtils.getLineupVideo(playerIds, isNba, season, teamId, includeOffense, includeDefense, ordsq, minGameId, minPeriod, maxClock, minEventNum) :
                await ApiUtils.getLineupVideo(playerIds, isNba, season, teamId, includeOffense, includeDefense, ordsq, maxGameId, minPeriod, maxClock, minEventNum);
            setCallInFlight(false);
            if (firstVideo.length === 0) {
                alert('No video found');
            }
            else {
                prevVideo = firstVideo;
                const setPagFuncIfNeeded = (video: ApiUtils.pbpVideo[]) => {
                    const finalVideo = video[video.length - 1];
                    if (video.length !== 0) {
                        const newPaginationFunc = async () => {
                            var newVideo = await ApiUtils.getLineupVideo(playerIds, isNba, season, teamId, includeOffense, includeDefense, ordsq, finalVideo.gameId, finalVideo.period, finalVideo.gameClock, finalVideo.eventNum);
                            setPagFuncIfNeeded(newVideo);
                            prevVideo = prevVideo.concat(newVideo);
                            setPbpVideo(prevVideo);
                            if (prevVideo.length > 2000) {
                                prevVideo = prevVideo.slice(-2000);
                            }
                        };
                        // why do i need to create a new function??? react seems to just call the funciton that gets passed in
                        setPaginationFunc(() => newPaginationFunc);
                    } else {
                        setPaginationFunc(null);
                    }
                };
                setPagFuncIfNeeded(firstVideo);
                setPbpVideo(firstVideo);
            }
        } catch (e) {
            alert('An error occurred');
        }
    };

    const onSelectMiscPlaylist = async (playlistName: string, isNba: boolean, season: string, ordsq: string) => {
        setLocation(BrowserUtils.createPlaylistVideoUrl(playlistName, season, isNba, ordsq));
    };
    const gamesSearchCss = 'search-type' + (searchType === SearchType.Games ? ' search-type-active' : '');
    const playersSearchCss = 'search-type' + (searchType === SearchType.Players ? ' search-type-active' : '');
    const lineupsSearchCss = 'search-type' + (searchType === SearchType.Lineups ? ' search-type-active' : '');
    const miscCss = 'search-type' + (searchType === SearchType.Misc ? ' search-type-active' : '');
    const pressCss = 'search-type' + (searchType === SearchType.Press ? ' search-type-active' : '');
    const loginCss = 'search-type' + (searchType === SearchType.Login ? ' search-type-active' : '');
    const queryCss = 'search-type' + (searchType === SearchType.Query ? ' search-type-active' : '');
    const livePbpCss = 'search-type' + (searchType === SearchType.LivePbp ? ' search-type-active' : '');
    const switchSearchType = (newSearchType: SearchType) => {
        return () => (searchType === newSearchType) ? setSearchType(SearchType.Hidden) : setSearchType(newSearchType)
    };
    const removeVideo = (i: number) => {
        setPbpVideo(pbpVideo.filter((x, j) => j !== i));
        setIsUrlLinkable(false);
    };
    const onSubmitQuery = async (data: {}, isNba: boolean, season: string, ordsq: string) => {
        await _onSubmitQuery(data, isNba, season, ordsq, true);
    }
    const _onSubmitQuery = async (data: {}, isNba: boolean, season: string, ordsq: string, setUrl: boolean) => {
        if (setUrl) setLocation(BrowserUtils.QUERY_VIDEO_URL);
        setSearchType(SearchType.Hidden);
        setCallInFlight(true);
        try {
            const firstVideo = ordsq !== "latest" ?
                await ApiUtils.getQueryResults(data, isNba, season, ordsq, minGameId, minPeriod, maxClock, minEventNum) :
                await ApiUtils.getQueryResults(data, isNba, season, ordsq, maxGameId, minPeriod, maxClock, minEventNum);

            setCallInFlight(false);
            if (firstVideo.length === 0) alert('No video found');
            else {
                prevVideo = firstVideo;
                const setPagFuncIfNeeded = (video: ApiUtils.pbpVideo[]) => {
                    const finalVideo = video[video.length - 1];
                    if (finalVideo !== undefined) {
                        const newPaginationFunc = async () => {
                            // TODO make sure this query is returning 0, not just getting the last video over and over
                            var newVideo = await ApiUtils.getQueryResults(data, isNba, season, ordsq, finalVideo.gameId, finalVideo.period, finalVideo.gameClock, finalVideo.eventNum);
                            setPagFuncIfNeeded(newVideo);
                            prevVideo = prevVideo.concat(newVideo);
                            setPbpVideo(prevVideo);
                            if (prevVideo.length > 2000) {
                                prevVideo = prevVideo.slice(-2000);
                            }
                        };
                        setPaginationFunc(() => newPaginationFunc);
                    } else {
                        setPaginationFunc(null);
                    }
                };
                setPagFuncIfNeeded(firstVideo);
                setPbpVideo(firstVideo);
            }
        } catch (e) {
            alert('An error occurred');
        }
    };

    const renderOptionButtons = (isGameVizVisible: boolean) => (<div>
        <label>Options: </label>
        <button className={spoilers ? 'action-button active-button' : 'action-button'} onClick={() => setSpoilers(!spoilers)}>Spoilers</button>
        <button className={fullscreen ? 'action-button active-button' : 'action-button'} onClick={() => setFullscreen(!fullscreen)}>Starts Fullscreen</button>
        {
            isGameVizVisible &&
            <button className={showGameViz ? 'action-button active-button' : 'action-button'} onClick={() => setShowGameViz(!showGameViz)}>Game Graph</button>
        }
    </div>);


    return (
        <Fragment>
            {
                dataContextIsLoaded && <DataContext.Provider value={dataContext}>
                    <UserContext.Provider value={usercontextValue}>
                    <ThemeProvider theme={defaultTheme}>

                        <MenuLayout
                            isOpen={isDrawerOpen}
                            onSearch={onSubmitQuery}
                            spoilers={spoilers} toggleSpoilers={() => setSpoilers(!spoilers)}
                            fullscreen={fullscreen} toggleFullscreen={() => setFullscreen(!fullscreen)}
                            randomize={randomize} toggleRandomize={() => setRandomize(!randomize)}
                        />
                        {
                            false && <div className='search-container'>
                                <div>
                                    <div className='tab-container'>
                                    </div>
                                    <div className='tab-container'>
                                        <button type='button' className={gamesSearchCss} onClick={switchSearchType(SearchType.Games)}>Games</button>
                                        <button type='button' className={playersSearchCss} onClick={switchSearchType(SearchType.Players)}>Players</button>
                                        <button type='button' className={queryCss} onClick={switchSearchType(SearchType.Query)}>Query</button>
                                        <button type='button' className={lineupsSearchCss} onClick={switchSearchType(SearchType.Lineups)}>Lineups</button>
                                        <button type='button' className={miscCss} onClick={switchSearchType(SearchType.Misc)}>Misc</button>
                                        <button type='button' className={pressCss} onClick={switchSearchType(SearchType.Press)}>Press</button>
                                        {/* {admin && <button type='button' className={livePbpCss} onClick={switchSearchType(SearchType.LivePbp)}>Live PBP</button>} */}
                                        {(!authenticated ?
                                            <button type='button' className={loginCss} onClick={switchSearchType(SearchType.Login)}>Login</button>
                                            :
                                            <button type='button' className='search-type' onClick={() => {
                                                CookieUtils.removeJwtCookie();
                                                setAuthenticated(false);
                                            }}>Logout</button>)}
                                    </div>
                                </div>
                                <GameSearch
                                    onSubmit={onSelectGame}
                                    visible={searchType === SearchType.Games}
                                    spoilers={spoilers}
                                    toggleSpoilers={() => setSpoilers(!spoilers)}
                                    gameViz={showGameViz}
                                    toggleGameViz={() => setShowGameViz(!showGameViz)}
                                >
                                    {renderOptionButtons(true)}
                                </GameSearch>
                                <PlayerSearch onSelectItem={onSelectPlayer} visible={searchType === SearchType.Players}>{renderOptionButtons(false)}</PlayerSearch>
                                <QuerySearch
                                    onSelectItem={onSubmitQuery} visible={searchType === SearchType.Query} spoilers={spoilers} toggleSpoilers={() => setSpoilers(!spoilers)}>{renderOptionButtons(false)}</QuerySearch>                            <LineupSearch onSelectItem={onSelectLineup} visible={searchType === SearchType.Lineups}>{renderOptionButtons(false)}</LineupSearch>
                                {/* <MiscSearch onSelectItem={onSelectMiscPlaylist} visible={searchType === SearchType.Misc}>{renderOptionButtons(false)}</MiscSearch> */}
                                <MediaSearch visible={searchType === SearchType.Press} />
                                {<div className='search-field-container' style={searchType === SearchType.Login ? {} : { display: 'none' }} >
                                    <Login onAction={switchSearchType(SearchType.Games)} />
                                </div>}
                            </div>
                        }
                        {
                            pbpVideo.length > 0 &&
                            <Grid container paddingTop="10px" spacing={2}>
                                <Grid item xs={6} textAlign="right">
                                    <Typography variant="body2" display="inline-block">Playlist:&nbsp;&nbsp;</Typography>
                                    <ToggleButtonGroup
                                        size="small"
                                        exclusive
                                        value={showResults}
                                        onChange={(e, newVal) => newVal !== null && setShowResults(newVal)}
                                        aria-label="Sort order">
                                        <ToggleButton value={true} aria-label="Search Result">
                                            Search
                                        </ToggleButton>
                                        <ToggleButton value={false} aria-label="Latest">
                                            My Reel
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <FormControlLabel sx={{ marginLeft: '0px' }} control={<Switch size="small" checked={spoilers} 
                                            onChange={(e, newVal) => setSpoilers(newVal)} />} label="Spoilers" />
                                        <FormControlLabel sx={{ marginLeft: '0px' }} control={<Switch size="small" checked={fullscreen} 
                                            onChange={(e, newVal) => setFullscreen(newVal)} />} label="Fullscreen video" />
                                        <FormControlLabel sx={{ marginLeft: '0px' }} control={<Switch size="small" checked={randomize} 
                                            onChange={(e, newVal) => setRandomize(newVal)} />} label="Randomize" />
                                        {
                                            pathIsGameVideo && <FormControlLabel sx={{ marginLeft: '0px' }} control={<Switch size="small" checked={showGameViz} onChange={(e, newVal) => setShowGameViz(newVal)} />} label="Spoilers" />
                                        }
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        }
                        {
                            isCallInFlight && <div className="spinner"></div>
                        }
                        {pbpVideo.length > 0 && <VideoPlayer
                            isUrlLinkable={isUrlLinkable}
                            data={pbpVideo}
                            paginationFunc={paginationFunc}
                            removeVideo={removeVideo}
                            spoilers={spoilers}
                            toggleSpoilers={() => setSpoilers(!spoilers)}
                            fullscreen={fullscreen}
                            toggleFullscreen={() => setFullscreen(!fullscreen)}
                            videoIsGame={pathIsGameVideo}
                            showResults={showResults}
                            randomize={randomize}
                            toggleRandomize={() => setRandomize(!randomize)}
                            initialShowGameViz={showGameViz} />}
                        {/* {searchType === SearchType.LivePbp && <LivePbp visible={searchType === SearchType.LivePbp} />} */}
                        </ThemeProvider>
                    </UserContext.Provider>
                </DataContext.Provider>
            }
        </Fragment>
    );
}

export default NBApp;
