import React, { useEffect, useState } from 'react';
import { shotTypes } from '../../utils/api-utils';
import '../search.css';

const closestDefenderTypes = new Set(['dmiss2p', 'dmiss3p', 'dmake2p', 'dmake3p', 'dmake2pa', 'dmake3pa']);

function PlayFilters(props: playFiltersProps) {
    const offenseFiltersData = [
        { displayName: '2P Make Unassisted', isIncluded: true, jsonVal: 'make2p' },
        { displayName: '2P Make Assisted', isIncluded: true, jsonVal: 'make2pa' },
        { displayName: '3P Make Unassisted', isIncluded: true, jsonVal: 'make3p' },
        { displayName: '3P Make Assisted', isIncluded: true, jsonVal: 'make3pa' },
        { displayName: 'Assist', isIncluded: true, jsonVal: 'ast' },
        { displayName: 'FT Drawn', isIncluded: true, jsonVal: 'ft' },
        { displayName: '2P Miss', isIncluded: true, jsonVal: 'miss2p' },
        { displayName: '3P Miss', isIncluded: true, jsonVal: 'miss3p' },
        { displayName: 'Turnover', isIncluded: true, jsonVal: 'tov' },
        { displayName: 'O Rebs', isIncluded: false, jsonVal: 'oreb' }
    ];
    const defenseFiltersData = [
        { displayName: 'Def 2P Miss', isIncluded: false, jsonVal: 'dmiss2p' },
        { displayName: 'Def 3P Miss', isIncluded: false, jsonVal: 'dmiss3p' },
        { displayName: 'TO Forced', isIncluded: true, jsonVal: 'stl' },
        { displayName: 'Block', isIncluded: true, jsonVal: 'blk' },
        { displayName: 'Def 2P Make Unassisted', isIncluded: false, jsonVal: 'dmake2p' },
        { displayName: 'Def 2P Make Assisted', isIncluded: false, jsonVal: 'dmake2pa' },
        { displayName: 'Def 3P Make Unassisted', isIncluded: false, jsonVal: 'dmake3p' },
        { displayName: 'Def 3P Make Assisted', isIncluded: false, jsonVal: 'dmake3pa' },
        { displayName: 'Foul', isIncluded: true, jsonVal: 'foul' },
        { displayName: 'D Rebs', isIncluded: false, jsonVal: 'dreb' }
    ];
    const [offenseFilters, setOffenseFilters] = useState(offenseFiltersData);
    const [defenseFilters, setDefenseFilters] = useState(defenseFiltersData);
    useEffect(() => {
        const filters = offenseFilters.concat(defenseFilters).filter(x => x.isIncluded);
        props.onSelectItem(filters.map(x => x.jsonVal));
    }, [offenseFilters, defenseFilters]);
    const flipAllOffenseFilters = () => {
        const toSet = [...offenseFilters];
        const flipTo = !toSet.some(x => x.isIncluded);
        toSet.forEach(x => x.isIncluded = flipTo);
        setOffenseFilters(toSet);
    };
    const flipAllDefenseFilters = () => {
        const toSet = [...defenseFilters];
        const flipTo = !toSet.some(x => x.isIncluded);
        toSet.forEach(x => x.isIncluded = flipTo);
        setDefenseFilters(toSet);
    };

    const { shotTypeFilters } = props;
    const [showShotTypeFilters, setShowShotTypeFilters] = useState(false);
    const setShotTypeFilters = (shotType: string) => {
        props.setShotTypeFilters({ ...shotTypeFilters, [shotType]: !shotTypeFilters[shotType] });
    };

    const [shotTypeSearchTerm, setShotTypeSearchTerm] = useState('');
    const searchedShotTypeFilters = Object.keys(props.shotTypes)
        .filter(shotType => shotTypeSearchTerm.length === 0 || shotType.toLocaleLowerCase().includes(shotTypeSearchTerm));
    const anyShotsSelected = searchedShotTypeFilters.some(shotType => shotTypeFilters[shotType]);
    const setAllSearchedShotTypeFilters = () => {
        const toSet = { ...shotTypeFilters };
        searchedShotTypeFilters.forEach(shotType => toSet[shotType] = !anyShotsSelected);
        props.setShotTypeFilters(toSet);
    };

    return (<React.Fragment>
        <div className='all-filters-container'>
            <div className='filter-container'>
                <div className='filter-header-clickable'>
                    <label className='checkbox-container'>
                        <input type='checkbox' checked={offenseFilters.some(x => x.isIncluded)}
                            className='filter-checkbox' onChange={flipAllOffenseFilters} />
                        <span className='checkbox-label'>Offense</span>
                    </label>
                </div>
                {
                    offenseFilters.map((item, i) => {
                        const onChecked = () => {
                            const toSet = [...offenseFilters];
                            toSet[i].isIncluded = !toSet[i].isIncluded;
                            setOffenseFilters(toSet);
                        };
                        return (
                            <div className='filter' key={`offense${i}`}>
                                <label className='checkbox-container'>
                                    <input type='checkbox' checked={item.isIncluded}
                                        className='filter-checkbox' onChange={onChecked} />
                                    <span className='checkbox-label'>{item.displayName}</span>
                                </label>
                            </div>
                        );
                    })
                }
            </div>
            <div className='filter-container'>
                <div className='filter-header-clickable'>
                    <label className='checkbox-container'>
                        <input type='checkbox' checked={defenseFilters.some(x => x.isIncluded)}
                            className='filter-checkbox' onChange={flipAllDefenseFilters} />
                        <span className='checkbox-label'>Defense</span>
                    </label>
                </div>
                {
                    defenseFilters.filter(item => props.showClosestDefenderFilters ? true : !closestDefenderTypes.has(item.jsonVal)).map((item, i) => {
                        const onChecked = () => {
                            const toSet = [...defenseFilters];
                            toSet.map(toSetItem => {
                                if (toSetItem.displayName === item.displayName)
                                    toSetItem.isIncluded = !toSetItem.isIncluded;
                                return toSetItem;
                            });
                            setDefenseFilters(toSet);
                        };
                        return (
                            <div className='filter' key={`defense${item.displayName}`}>
                                <label className='checkbox-container'>
                                    <input type='checkbox' checked={item.isIncluded}
                                        className='filter-checkbox' onChange={onChecked} />
                                    <span className='checkbox-label'>{item.displayName}</span>
                                </label>
                            </div>
                        );
                    })
                }
            </div>
        </div>
        <button className='action-button' onClick={() => setShowShotTypeFilters(!showShotTypeFilters)}>Show/hide shot type filters</button>
        <div style={showShotTypeFilters ? {} : { display: 'none' }}>
            <input type='text' className='search-fields search-text'
                value={shotTypeSearchTerm}
                onChange={e => setShotTypeSearchTerm(e.target.value.toLocaleLowerCase())}
                placeholder='Filter shot types' />
        </div>
        <div className='all-filters-container' style={showShotTypeFilters ? {} : { display: 'none' }}>
            <div className='filter-container'>
                <div className='filter'>
                    <label className='checkbox-container'>
                        <input type='checkbox' checked={anyShotsSelected}
                            className='filter-checkbox' onChange={() => setAllSearchedShotTypeFilters()} />
                        <span className='checkbox-label' title='Selecting none means including all shot types'><i>Select/unselect all</i></span>
                    </label>
                </div>
                {searchedShotTypeFilters
                    .map((shotType, i) => {
                        return (<div className='filter' key={`shotType${i}`}>
                            <label className='checkbox-container'>
                                <input type='checkbox' checked={shotTypeFilters[shotType]}
                                    className='filter-checkbox' onChange={() => setShotTypeFilters(shotType)} />
                                <span className='checkbox-label'>{shotType}</span>
                            </label>
                        </div>);
                    })}
            </div>
        </div>
    </React.Fragment>);
}

interface playFiltersProps {
    showClosestDefenderFilters: boolean,
    shotTypes: shotTypes,
    shotTypeFilters: { [shotType: string]: boolean },
    setShotTypeFilters: (shotTypeFilters: { [shotType: string]: boolean }) => void,
    onSelectItem: (selectedFiltersJson: string[]) => void
}

export default PlayFilters;