import { createContext, useContext } from "react";

export interface userContext {
    authenticated: boolean,
    setAuthenticated: (value: boolean) => void
}

export const DefaultUserContext = () => {
    return {
        authenticated: false,
        setAuthenticated: (value: boolean) => {}
    };
};

export const UserContext = createContext<userContext>(DefaultUserContext());

export const useUserContext = () => useContext(UserContext)