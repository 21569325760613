import { useEffect, useState } from 'react';
// import logo from './logo.svg';


import { useLocation } from "wouter";
import Login from '../user/login';

import Close from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MediaSearch from '../media-search';
import MiscSearch from '../misc-search';
import QuerySearch from '../query-search';
import QuerySelector from './QuerySelector';
import VideoSearch from './VideoSearch';

import * as BrowserUtils from '../../utils/browser-utils';
import * as CookieUtils from '../../utils/cookie-utils';
import { QueryType } from '../../utils/pbp-query-utils';
import { useUserContext } from '../../utils/user-context';

const drawerWidth: number = 600;
enum SearchType {
    Game,
    Play,
    Search,
    Query,
    Misc,
    Login,
    Fake
}

interface MenuLayoutProps {
    isOpen: boolean,
    onSearch: (data: {}, isNba: boolean, season: string, ordsq: string) => void,
    spoilers: boolean,
    toggleSpoilers: () => void,
    fullscreen: boolean,
    toggleFullscreen: () => void,
    randomize: boolean,
    toggleRandomize: () => void
}

function MenuLayout(props: MenuLayoutProps) {
    const [searchType, setSearchType] = useState<SearchType>(SearchType.Game);
    const { authenticated, setAuthenticated } = useUserContext();

    const [open, setOpen] = useState(props.isOpen);
    const toggleDrawer = () => setOpen(!open);
    useEffect(() => setOpen(props.isOpen), [props.isOpen]);

    const onSearch = (data: {}, isNba: boolean, season: string, ordsq: string) => {
        props.onSearch(data, isNba, season, ordsq);
        setOpen(false);
    };
    const setLocation = useLocation()[1];

    const onSelectMiscPlaylist = async (playlistName: string, isNba: boolean, season: string, ordsq: string) => {
        setLocation(BrowserUtils.createPlaylistVideoUrl(playlistName, season, isNba, ordsq));
        setOpen(false);
    };
    const drawerContent = (<>
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                px: [1],
            }}>
            <IconButton onClick={toggleDrawer}>
                <Close />
            </IconButton>
            <ToggleButtonGroup
                value={searchType}
                exclusive
                onChange={(e, newSearchType) => newSearchType !== null && setSearchType(newSearchType)}
                aria-label="Menu options"
                sx={{ justifyContent: 'center', width: '100%', marginRight: '48px' }}>
                <ToggleButton value={SearchType.Game} aria-label="Game search">
                    Game
                </ToggleButton>
                <ToggleButton value={SearchType.Play} aria-label="Player search">
                    Player
                </ToggleButton>
                <ToggleButton value={SearchType.Search} aria-label="Video search">
                    Query
                </ToggleButton>
                <ToggleButton value={SearchType.Misc} aria-label="Misc">
                    Misc
                </ToggleButton>
                {(!authenticated ?
                    <ToggleButton value={SearchType.Login} aria-label="Login">
                        Login
                    </ToggleButton>
                    :
                    <ToggleButton value={SearchType.Fake} onClick={() => {
                        CookieUtils.removeJwtCookie();
                        setAuthenticated(false);
                        setSearchType(SearchType.Game);
                    }} aria-label="Login">
                        Logout
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </Toolbar>
        <Divider />
        <div className='search-container' style={{ margin: 'auto', textAlign: 'center', marginTop: '10px' }}>
            <VideoSearch
                visible={searchType === SearchType.Game}
                queryType={QueryType.GAMEID}
                closeDrawer={() => setOpen(false)}
                fullscreen={props.fullscreen}
                toggleFullscreen={props.toggleFullscreen}
                spoilers={props.spoilers}
                toggleSpoilers={props.toggleSpoilers}
                randomize={props.randomize}
                toggleRandomize={props.toggleRandomize}
            />
            <VideoSearch
                visible={searchType === SearchType.Play}
                queryType={QueryType.PLAY}
                closeDrawer={() => setOpen(false)}
                fullscreen={props.fullscreen}
                toggleFullscreen={props.toggleFullscreen}
                spoilers={props.spoilers}
                toggleSpoilers={props.toggleSpoilers}
                randomize={props.randomize}
                toggleRandomize={props.toggleRandomize}
            />

            <QuerySelector
                visible={searchType === SearchType.Search}
                closeDrawer={() => setOpen(false)}
                onSearch={onSearch}
                fullscreen={props.fullscreen}
                toggleFullscreen={props.toggleFullscreen}
                spoilers={props.spoilers}
                toggleSpoilers={props.toggleSpoilers}
                randomize={props.randomize}
                toggleRandomize={props.toggleRandomize}
            />
            {
                searchType === SearchType.Misc && <Divider textAlign="left" sx={{
                    "&::before, &::after": {
                        borderColor: "lightgray",
                    },
                }}>
                    <Typography>Press Conferences</Typography>
                </Divider>
            }
            <MediaSearch visible={searchType === SearchType.Misc} />
            {
                searchType === SearchType.Misc && <Divider textAlign="left" sx={{
                    "&::before, &::after": {
                        borderColor: "lightgray",
                    },
                }}>
                    <Typography>Random playlists</Typography>
                </Divider>
            }
            <MiscSearch visible={searchType === SearchType.Misc} onSelectItem={onSelectMiscPlaylist}
                spoilers={props.spoilers}
                toggleSpoilers={() => props.toggleSpoilers()}
                fullscreen={props.fullscreen}
                toggleFullscreen={props.toggleFullscreen}
                randomize={props.randomize}
                toggleRandomize={props.toggleRandomize}
                />
            {/* <QuerySearch
                onSelectItem={onSubmitQuery} visible={searchType === SearchType.Query} spoilers={spoilers} toggleSpoilers={() => setSpoilers(!spoilers)}>{renderOptionButtons(false)}</QuerySearch>
            <LineupSearch onSelectItem={onSelectLineup} visible={searchType === SearchType.Lineups}>{renderOptionButtons(false)}</LineupSearch>
            <MiscSearch onSelectItem={onSelectMiscPlaylist} visible={searchType === SearchType.Misc}>{renderOptionButtons(false)}</MiscSearch> */}

            {
                searchType === SearchType.Login && <div className='search-field-container'>
                    <Login onAction={() => setSearchType(SearchType.Game)} />
                </div>
            }
        </div>
    </>);

    return (
        <>
            <Fab variant="extended"
                color="primary"
                onClick={toggleDrawer}
                sx={{
                    margin: 'auto',
                    // left: `${menuIconLeft}px`,
                    // position: 'absolute'
                }}>
                <ExpandMoreIcon />
                {/* <MenuIcon /> */}
                <Typography
                    variant="subtitle1"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}>
                    Menu
                </Typography>
            </Fab>
            <Drawer
                sx={{
                    '& .MuiDrawer-paper': {
                        margin: 'auto',
                        marginBottom: '10px',
                        maxWidth: drawerWidth,
                        boxSizing: 'border-box',
                        minHeight: '400px',
                        backgroundColor: '#424242'
                    },
                }}
                anchor={"top"}
                variant="persistent"
                open={open}>
                {drawerContent}
            </Drawer>
        </>
    );
}

export default MenuLayout;
