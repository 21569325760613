export const urlPrefix = (process.env.NODE_ENV === 'development' ? 'https://localhost:44329/api/' : '/api/');

const getFetchOptions = (method: string) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return {
        headers,
        method,
        // mode: 'no-cors'        
    }
};

export const getGames = async (isNba: boolean) => {
    const response = await fetch(`${urlPrefix}games?isNba=${isNba.toString()}`, getFetchOptions('GET'));
    const games : gameBySeason = await response.json();
    return games;
};

export const getPlayers = async (isNba: boolean) => {
    const response = await fetch(`${urlPrefix}players?isNba=${isNba.toString()}`, getFetchOptions('GET'));
    const players : playerBySeason = await response.json();
    return players;
};

export const getGameVideo = async (gameId: number, season: string, isNba: boolean, videoType: string | null, playerId: string | null) => {
    const response = await fetch(`${urlPrefix}gamepbpvideo?gameId=${gameId}&season=${season}&isNba=${isNba.toString()}&videoType=${videoType ?? ''}&playerId=${playerId ?? ''}`);
    const pbpVideo: pbpVideo[] = await response.json();
    return pbpVideo;
};

export const getPlayerVideo = async (playerId: number, season: string, filters: string, shotFilters: string, isNba: boolean, ordsq: string) => {
    const response = await fetch(`${urlPrefix}playerpbpvideo?playerId=${playerId}&season=${season}&filters=${filters}&shotFilters=${shotFilters}&isNba=${isNba.toString()}&ordsq=${ordsq}`);
    const pbpVideo: pbpVideo[] = await response.json();
    return pbpVideo;
};

export const getIdVideo = async (ids: string, isNba: boolean) => {
    const isw = isNba ? '' : 'isw=t&'
    const response = await fetch(`${urlPrefix}ids?${isw}ids=${ids}`);
    const pbpVideo: pbpVideo[] = await response.json();
    return pbpVideo;
};

export const getSeasonTeamPlayers = async (isNba: boolean) => {
    const response = await fetch(`${urlPrefix}seasonTeamPlayers?isNba=${isNba.toString()}`);
    const seasonTeamPlayers: seasonTeamPlayerTransport = await response.json();
    return seasonTeamPlayers;
};

export const convertSTPToPlayers = (stp: seasonTeamPlayerTransport) => {
    const toReturn = {} as playerBySeason;
    Object.keys(stp.seasonTeamPlayer).forEach(season => {
        const playersByPlayerId = {} as {[key: string]: playerPartial};
        const teamPlayers = stp.seasonTeamPlayer[season];
        Object.keys(teamPlayers).forEach(teamId => {
            const players = teamPlayers[teamId];
            players.forEach(player => {
                const abbr = stp.teamAbbrById[teamId].toLocaleUpperCase();
                const teamName = stp.teamNamesById[teamId];
                if (playersByPlayerId[player.id] === undefined) {
                    playersByPlayerId[player.id] = {...player, teamAbbrs: [abbr], teamNames: teamName}
                } else {
                    playersByPlayerId[player.id].teamAbbrs.push(abbr);
                    playersByPlayerId[player.id].teamNames += ', ' + teamName;
                }
            });
        });
        const fixedPlayers = [] as player[];
        Object.keys(playersByPlayerId).forEach(playerId => {
            playersByPlayerId[playerId].teamAbbrs = playersByPlayerId[playerId].teamAbbrs.sort((a, b) => a.localeCompare(b));
            fixedPlayers.push({...playersByPlayerId[playerId], teamAbbrs: playersByPlayerId[playerId].teamAbbrs.join(', ')});
        });
        fixedPlayers.sort((a, b) => a.name.localeCompare(b.name));
        toReturn[season] = fixedPlayers;
    });
    return toReturn;
};

export const getLineupVideo = async (playerIds: number[], isNba: boolean, season: string, teamId: string, includeOffense: boolean, includeDefense: boolean, ordsq: string, gameId: number, period: number, gameClock: string, eventNum: number) => {
    const response = await fetch(`${urlPrefix}lineupvideo?isNba=${isNba.toString()}&season=${season}&teamId=${teamId}&playerIds=${playerIds.join(',')}&off=${includeOffense}&def=${includeDefense}&ordsq=${ordsq}&gameId=${gameId}&period=${period}&gameClock=${gameClock}&eventNum=${eventNum}`);
    const pbpVideo: pbpVideo[] = await response.json();
    return pbpVideo;
};

export const getPlaylistVideo =  async (playlistName: string, isNba: boolean, season: string, ordsq: string) => {
    const response = await fetch(`${urlPrefix}playlist/${playlistName}?isNba=${isNba.toString()}&season=${season}&ordsq=${ordsq}`);
    const pbpVideo: pbpVideo[] = await response.json();
    return pbpVideo;
};

export const getQueryResults = async (data: object, isNba: boolean, season: string, ordsq: string, gameId: number, period: number, gameClock: string, eventNum: number) => {
    const response = await fetch(`${urlPrefix}query?isNba=${isNba.toString()}&season=${season}&ordsq=${ordsq}&gameId=${gameId}&period=${period}&gameClock=${gameClock}&eventNum=${eventNum}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    const pbpVideo: pbpVideo[] = await response.json();
    return pbpVideo;
};

export const getShotTypes = async () => {
    const response = await fetch(`${urlPrefix}shottypes`);
    const shotTypes: shotTypes = await response.json();
    return shotTypes;
};

export const getMediaVids = async (isNba: boolean) => {
    const response = await fetch(`${urlPrefix}media?isNba=${isNba.toString()}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });
    const mediaVids: mediaVidBySeason = await response.json();
    return mediaVids;
};

export const refetchVideo = async (isNba: boolean, tuidYear: string, gameId: number, eventNum: number) => {
    const response = await fetch(`${urlPrefix}refetchvideo?isNba=${isNba.toString()}&tuidYear=${tuidYear}&gameId=${gameId}&eventNum=${eventNum}`);
    const result: boolean = await response.json();
    return result;
};

export const getLineupPlayers = async (season: string, lineupIds: string,isNba: boolean) => {
    const response = await fetch(`${urlPrefix}lineups?&season=${season}&lineupIds=${lineupIds}&isNba=${isNba.toString()}`);
    const lineupPlayers: lineupPlayerResponse = await response.json();
    return lineupPlayers;
};

export interface gameBySeason {
    [key: string] : game[]
}

export interface game {
    awayPts: number,
    awayTeam: string,
    dateSearch: string,
    gameDate: string,
    gameId: number,
    homePts: number,
    homeTeam: string
}

export interface playerBySeason {
    [key: string] : player[]
}

export interface playerPartial {
    id: number,
    name: string,
    teamAbbrs: string[],
    teamNames: string
}
export interface player {
    id: number,
    name: string,
    teamAbbrs: string,
    teamNames: string
}

export interface pbpVideo {
    awayColor: string,
    awayScore: number,
    awayTeam: string,
    gameClock: string,
    gameDate: string,
    gameId: number,
    homeColor: string,
    homeScore: number,
    homeTeam: string,
    isHome: boolean,
    period: number,
    playDesc: string,
    tuid: string,
    videoUrl: string
    locX: number | null,
    locY: number | null,
    shotType: ShotType | null,
    isMadeShot: boolean | null,
    is3Pt: boolean | null,
    eventNum: number,
    eventMsgType: number,
    player1Id: number | null,
    player2Id: number | null,
    player3Id: number | null,
    homeLineupId: number | null,
    awayLineupId: number | null,
}

export enum ShotType
{
    Rim = 1,
    Jumper = 2,
    MoveJumper = 3,
    Floater = 4,
}

export interface seasonTeamPlayerTransport {
    seasonTeamPlayer: seasonTeamPlayers,
    teamNamesById: teamNamesById,
    teamAbbrById: teamNamesById
}

export interface seasonTeamPlayers {
    [key: string] : teamPlayers;
}

export interface teamPlayers {
    [key: string] : player[]
}

export interface teamNamesById {
    [key: string] : string
}

export interface shotTypes {
    [key: string] : number
}

export interface mediaVidBySeason {
    [key: string]: mediaVid[]
}

export interface mediaVid {
    gameId: number,
    url: string,
    title: string
}

export interface mediaVidHeavyBySeason {
    [key: string]: mediaVidHeavy[]
}

export interface mediaVidHeavy extends mediaVid {
    game: game
}

export interface lineupPlayerTransport {
    lineupId: number,
    playerIds: number[],
    season: string
}
export interface lineupPlayerResponse {
    lineups: lineupPlayerTransport[]
}