import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { pbpQueryMenu, createQuarterTimeQuery } from "../../utils/pbp-query-utils";

interface quarterTimeSelectorProps {
  setQuery: (query: pbpQueryMenu) => void
}

const comparers = [
  { value: "gte", text: "≥" },
  { value: "gt", text: ">" },
  { value: "lt", text: "<" },
  { value: "lte", text: "≤" },
]

export default function QuarterTimeSelector(props: quarterTimeSelectorProps) {
  const [selectedComparer, setSelectedComparer] = useState(comparers[0].value);
  const [minuteValue, setMinuteValue] = useState(12);
  const [secondValue, setSecondValue] = useState(0);

  useEffect(() => {
    const minuteValueStr = minuteValue < 10 ? `0${minuteValue}` : minuteValue.toString();
    const secondValueStr = secondValue < 10 ? `0${secondValue}` : secondValue.toString();
    const query = createQuarterTimeQuery(`00:${minuteValueStr}:${secondValueStr}`, selectedComparer);
    const isValid = true;
    props.setQuery({
      ...query, isValid,
      isAnd: true, id: 0 // these don't matter, get overwritten by containers
    });
  }, [selectedComparer, minuteValue, secondValue]);
  return (<>
    <Select size="small" value={selectedComparer}
      onChange={(e) => setSelectedComparer(e.target.value)}>
      {comparers.map(comparer => (<MenuItem key={`comparer${comparer.value}`} value={comparer.value}>{comparer.text}</MenuItem>))}
    </Select>
    <TextField
      sx={{ width: '70px' }}

      size="small"
      id="outlined-number"
      label="Min"
      type="number"
      value={minuteValue}
      onChange={e => {
        const num = parseInt(e.target.value);
        if (num >= 0 && num <= 12) setMinuteValue(num)
        else if (isNaN(num)) setMinuteValue(0);
      }}
      InputProps={{ inputProps: { min: 0, max: 12 } }}
      InputLabelProps={{
        shrink: true,
      }}
    />:
    <TextField
      sx={{ width: '70px' }}
      size="small"
      id="outlined-number"
      label="Sec"
      type="number"
      value={secondValue}
      onChange={e => {
        const num = parseInt(e.target.value);
        if (num >= 0 && num <= 59) setSecondValue(num)
        else if (isNaN(num)) setSecondValue(0);
      }}
      InputProps={{ inputProps: { min: 0, max: 59 } }}
      InputLabelProps={{
        shrink: true,
      }} />
  </>);
}