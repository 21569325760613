import React, { Fragment, useState } from 'react';
import Modal from 'react-modal';

// import * as PbpQueryUtils from '../utils/pbp-query-utils';
import { useUserContext } from '../../utils/user-context';
import * as ApiUserUtils from '../../utils/api-user-utils';

Modal.setAppElement('#root');

function ChangePass(props: changePassProps) {
    const { authenticated, setAuthenticated } = useUserContext();
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const changePassword = async () => {
        if (password !== password2) {
            alert("Passwords do not match");
            return;
        } else if (password2.length < 6) {
            alert("Password must be at least 6 characters");
            return;
        }
        try {
            const didChange = await ApiUserUtils.changePassword(props.token, password2);
            if (!didChange) {
                alert('Could not find user. You can check if the link is the same as the email, reset password again, or email thehighlowdotcom@gmail.com.');
                return;
            }
            setAuthenticated(true);
            window.location.href = window.location.origin;
        } catch (e) {
            alert("An error occurred. Please try again later")
        }
    };

    return (<div>
        <h4>Change Password</h4>
        <div className='margin-top margin-bottom'>
        <div className='margin-top margin-bottom'>
            <input type='password' className='search-fields search-text'
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder={"Password"} />
        </div>
        <input type='password' className='search-fields search-text'
                value={password2}
                onChange={e => setPassword2(e.target.value)}
                placeholder={"Confirm password"} />
        </div>
        <div className='margin-top margin-bottom '>
            <button className='action-button submit-button' onClick={changePassword}>Change Password</button>
            <p>Back to <a className='white-link' href='/'>the High-Low</a></p>
        </div>
    </div>);
}

interface changePassProps {
    token: string
}

export default ChangePass;