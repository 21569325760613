export const resetSelectedSeason = (league: string, setSelectedSeason: (season: string) => void) => {
    setSelectedSeason(getLatestSeason(league));
};

export const getLatestSeason = (league: string) => {
    return '2024-25';
    //return league === 'nba' ? '2023-24' : '2024-25';
}

export const closestDefenderYears = new Set(['2024-25', '2023-24', '2022-23', '2021-22', '2020-21', '2019-20', '2018-19']);
