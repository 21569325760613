import { useState, useEffect } from 'react';


import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { game, player } from "../../utils/api-utils"
import { renderGameText, renderGameTextNoSpoilers, searchGames } from '../../utils/game-selector-utils'
import { pbpQueryMenu } from '../../utils/pbp-query-utils';
import * as PbpQueryUtils from '../../utils/pbp-query-utils';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

interface gameSelectorProps {
    spoilers: boolean,
    games: game[],
    players: player[],
    setQuery: (query: pbpQueryMenu) => void
}

const ALL_VIDEO = "all";
const HIGHLIGHTS = "high";
const LOWLIGHTS = "low";

export default function GameSelector(props: gameSelectorProps) {
    const [selectedGames, setSelectedGames] = useState([] as game[]);
    const [selectedPlayer, setSelectedPlayer] = useState<player | null>(null);
    const [playType, setPlayType] = useState(ALL_VIDEO);
    useEffect(() => {
        const query = PbpQueryUtils.createGamesQuery(selectedGames.map(x => x.gameId), selectedPlayer?.id, playType);
        const isValid = selectedGames.length > 0;
        props.setQuery({
            ...query, isValid,
            isAnd: true, id: 0 // these don't matter, get overwritten by containers
        });
    }, [selectedGames, selectedPlayer, playType]);
    useEffect(() => {
        setSelectedPlayer(null);
        setSelectedGames([]);
    }, [props.games, props.players]);
    return (<><Autocomplete
        filterOptions={(options, {inputValue}) => {
            return options.filter(game => searchGames(game, inputValue));
        }}
        fullWidth size="small" limitTags={1} sx={{ marginBottom: '5px' }}
        value={selectedGames}
        onChange={(e, newVal) => setSelectedGames(newVal)}
        multiple
        options={props.games}
        getOptionLabel={(option) => props.spoilers ? renderGameText(option) : renderGameTextNoSpoilers(option)}
        filterSelectedOptions
        renderInput={(params) => {
            return (
                <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Games"
                // placeholder="Favorites"
                />
            )
        }} />
        <Autocomplete
            filterOptions={(options, {inputValue}) => {
                return options.filter(player => (
                    player.name.toLocaleLowerCase().includes(inputValue) ||
                    player.teamAbbrs.toLocaleLowerCase().includes(inputValue) ||
                    player.teamNames.toLocaleLowerCase().includes(inputValue)));
            }}
            fullWidth size="small" limitTags={1} sx={{ marginBottom: '5px' }}
            value={selectedPlayer}
            onChange={(e, newVal) => setSelectedPlayer(newVal)}
            options={props.players}
            getOptionLabel={player => `${player.name} (${player.teamAbbrs})`}
            filterSelectedOptions
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Player"
                        placeholder="Optional"
                    />
                )
            }} />
        <ToggleButtonGroup
            size="small"
            exclusive
            value={playType}
            onChange={(e, newVal) => newVal !== null && setPlayType(newVal)}
            aria-label="Sort order">
            <ToggleButton value={ALL_VIDEO} aria-label="Earliest">
                All
            </ToggleButton>
            <ToggleButton value={HIGHLIGHTS} aria-label="Earliest">
                Highlights
            </ToggleButton>
            <ToggleButton value={LOWLIGHTS} aria-label="Latest">
                Lowlights
            </ToggleButton>
        </ToggleButtonGroup>
    </>
    )
}