import React from 'react';
import './league-season-selector.css';


function LeagueSeasonSelector(props: leagueSeasonSelectorProps) {
    return (<React.Fragment>
        <select className='search-fields' value={props.selectedLeague}
            onChange={(e) => props.setLeagueAndSeason(e.target.value)}>
            <option value='nba'>NBA</option>
            <option value='wnba'>WNBA</option>
        </select>
        <select className='search-fields' value={props.selectedSeason}
            onChange={(e) => props.setSelectedSeason(e.target.value)}>
            {
                props.seasons.sort((a, b) => {
                    if (a < b) return -1;
                    else if (a > b) return 1;
                    else return 0;
                }).map((season, i) => {
                    const seasonText = props.selectedLeague === 'nba' ? season : season.substring(0, 4);
                    return (<option key={`season${i}`} value={season}>{seasonText}</option>)
                })
            }
        </select>
    </React.Fragment>);
}

interface leagueSeasonSelectorProps {
    selectedLeague: string,
    setLeagueAndSeason: (league: string) => void,
    selectedSeason: string,
    seasons: string[],
    setSelectedSeason: (season: string) => void
}

export default LeagueSeasonSelector;