import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { player, teamPlayers } from "../../utils/api-utils";
import { pbpQueryMenu, createTeam2Query } from "../../utils/pbp-query-utils";

interface teamSelectorProps {
    teams: { id: string, name: string }[],
    setQuery: (query: pbpQueryMenu) => void
}

const ANY = 'any';
export default function TeamSelector(props: teamSelectorProps) {
    const [selectedHomeTeam, setSelectedHomeTeam] = useState<string>(ANY);
    const [selectedAwayTeam, setSelectedAwayTeam] = useState<string>(ANY);

    useEffect(() => {
        const homeTeamId = selectedHomeTeam === ANY ? undefined : parseInt(selectedHomeTeam);
        const awayTeamId = selectedAwayTeam === ANY ? undefined : parseInt(selectedAwayTeam);
        const isValid = homeTeamId !== undefined || awayTeamId !== undefined;
        const query = createTeam2Query(homeTeamId, awayTeamId);
        props.setQuery({
            ...query, isValid,
            isAnd: true, id: 0 // these don't matter, get overwritten by containers
        });
    }, [selectedHomeTeam, selectedAwayTeam]);

    useEffect(() => {
        if (!props.teams.some(team => team.id === selectedHomeTeam)) setSelectedHomeTeam(ANY);
        if (!props.teams.some(team => team.id === selectedAwayTeam)) setSelectedAwayTeam(ANY);
    }, [props.teams]);
    return (<>
        <FormControl sx={{ width: "100%" }}>
            <InputLabel>Home team</InputLabel>
            <Select size="small" label="Home Team" value={selectedHomeTeam}
                onChange={(e) => setSelectedHomeTeam(e.target.value)}>
                <MenuItem value={'any'}>Any team</MenuItem>
                {
                    props.teams.map((team, i) => (<MenuItem key={`team${team.id}`} value={team.id}>{team.name}</MenuItem>))
                }
            </Select>
        </FormControl>
        <FormControl sx={{ marginTop: "5px", width: "100%" }}>
            <InputLabel>Away team</InputLabel>
            <Select size="small" label="Away Team" value={selectedAwayTeam}
                onChange={(e) => setSelectedAwayTeam(e.target.value)}>
                <MenuItem value={'any'}>Any team</MenuItem>
                {
                    props.teams.map((team, i) => (<MenuItem key={`team${team.id}`} value={team.id}>{team.name}</MenuItem>))
                }
            </Select>
        </FormControl>
    </>)
}