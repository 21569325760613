import { useState } from 'react';
import './search.css';

import GameSelector from './common/game-selector';
import PlayerSelector from './common/player-selector';
import SelectedItem from './common/selected-item';
import LeagueSeasonSelector from './common/league-season-selector';
import { game, player } from '../utils/api-utils';
import { renderGameText, renderGameTextNoSpoilers } from '../utils/game-selector-utils'
import { useDataContext } from '../utils/data-context';
import * as ComponentUtils from '../utils/component-utils';


const ALL_VIDEO = "all";
const HIGHLIGHTS = "high";
const LOWLIGHTS = "low";
function GameSearch(props: gameSearchProps) {
    const [selectedLeague, setSelectedLeague] = useState('nba');
    const [selectedSeason, setSelectedSeason] = useState(ComponentUtils.getLatestSeason('nba'));
    const [videoType, setVideoType] = useState(ALL_VIDEO);
    const [selectedGame, _setSelectedGame] = useState<game | null>(null);
    const [selectedPlayer, setSelectedPlayer] = useState<player | null>(null);

    const data = useDataContext();
    const setLeagueAndSeason = (league: string) => {
        setSelectedLeague(league);
        ComponentUtils.resetSelectedSeason(league, setSelectedSeason);

    };
    const setSelectedGame = (game: game | null) => {
        _setSelectedGame(game);
        setSelectedPlayer(null);
    };
    const isNba = selectedLeague === 'nba';
    const gamesToUse = isNba ? data.gamesBySeason : data.wgamesBySeason;

    const filteredGames = gamesToUse[selectedSeason];
    let playersToUse = isNba ? data.playersBySeason[selectedSeason] : data.wplayersBySeason[selectedSeason];
    if (selectedGame !== null) {
        const awayTeam = selectedGame.awayTeam.toLocaleLowerCase();
        const homeTeam = selectedGame.homeTeam.toLocaleLowerCase();
        playersToUse = playersToUse.filter(x => {
            const teamNames = x.teamNames.toLocaleLowerCase();
            return teamNames.includes(awayTeam) || teamNames.includes(homeTeam);
        });
    }
    return (<div style={props.visible ? {} : { display: 'none' }}>
        <div className='search-field-container'>
            <LeagueSeasonSelector
                selectedLeague={selectedLeague}
                setLeagueAndSeason={setLeagueAndSeason}
                selectedSeason={selectedSeason}
                seasons={Object.keys(gamesToUse)}
                setSelectedSeason={setSelectedSeason}
            />
            {
                selectedGame === null ?
                    <GameSelector
                        games={filteredGames}
                        closeOnSelect={true}
                        onSelectItem={(game: game) => setSelectedGame(game)}
                        spoilers={props.spoilers}
                    />
                    :
                    <SelectedItem
                        text={props.spoilers ? renderGameText(selectedGame) : renderGameTextNoSpoilers(selectedGame)}
                        onDelete={() => setSelectedGame(null)}
                    />
            }
            <div className={'margin-top margin-bottom'}>
                {
                    selectedPlayer === null ?
                        <PlayerSelector
                            players={playersToUse}
                            displayText='Optionally filter by player'
                            onSelectItem={(player: player) => setSelectedPlayer(player)}
                        />
                        :
                        <button className='search-fields search-text action-button selected-item' onClick={() => setSelectedPlayer(null)}>
                            x&nbsp;&nbsp;&nbsp;&nbsp;{selectedPlayer.name} ({selectedPlayer.teamAbbrs})
                        </button>
                }
                {/* <p>(Players may not have appeared in the game)</p> */}
            </div>
            <div>
                <label>Play type: </label>
                <button className={videoType === ALL_VIDEO ? 'action-button active-button' : 'action-button'} onClick={() => setVideoType(ALL_VIDEO)}>All Plays</button>
                <button className={videoType === HIGHLIGHTS ? 'action-button active-button' : 'action-button'} onClick={() => setVideoType(HIGHLIGHTS)}>Highlight Plays</button>
                <button className={videoType === LOWLIGHTS ? 'action-button active-button' : 'action-button'} onClick={() => setVideoType(LOWLIGHTS)}>Lowlight Plays</button>
            </div>
            {props.children}
            <button className='action-button submit-button' onClick={() => {
                if (selectedGame === null) alert("Must select a game");
                else props.onSubmit(selectedGame.gameId, selectedSeason, isNba, videoType, selectedPlayer?.id);
            }}>Submit</button>
        </div>
    </div>);
}

interface gameSearchProps {
    visible: boolean,
    onSubmit: (gameId: number, season: string, isNba: boolean, videoType: string, playerId: number | undefined) => void,
    spoilers: boolean,
    toggleSpoilers: () => void,
    gameViz: boolean,
    toggleGameViz: () => void,
    children?: React.ReactNode
}

export default GameSearch;