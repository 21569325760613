import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { pbpQueryMenu, createLocQuery } from "../../utils/pbp-query-utils";

const locations = [
    { name: 'Rim', value: 'rim' },
    { name: 'Paint', value: 'paint' },
    { name: 'Left Midrange', value: 'lmid' },
    { name: 'Center Midrange', value: 'cmid' },
    { name: 'Right Midrange', value: 'rmid' },
    { name: 'Left Corner 3', value: 'lc3' },
    { name: 'Left Wing 3', value: 'lw3' },
    { name: 'Center 3', value: 'c3' },
    { name: 'Right Wing 3', value: 'rw3' },
    { name: 'Right Corner 3', value: 'rc3' },
    { name: 'Backcourt', value: 'bc' }
];

interface locationSelectorProps {
    setQuery: (query: pbpQueryMenu) => void
}
export default function LocationSelector(props: locationSelectorProps) {
    const [locationSearch, setLocationSearch] = useState('');
    const [selectedLocations, setSelectedLocations] = useState([] as { name: string, value: string }[]);
    useEffect(() => {
        const query = createLocQuery(selectedLocations.map(x => x.value));
        const isValid = selectedLocations.length > 0;
        props.setQuery({
            ...query, isValid,
            isAnd: true, id: 0 // these don't matter, get overwritten by containers
        });
    }, [selectedLocations]);
    return (<><Autocomplete
        inputValue={locationSearch}
        onInputChange={(e, val) => {
            if (e && e.type !== 'click') setLocationSearch(val)
        }}
        disableCloseOnSelect
        fullWidth
        size="small"
        value={selectedLocations}
        onChange={(e, newVal) => setSelectedLocations(newVal)}
        multiple
        limitTags={1}
        options={locations}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        renderInput={(params) => {
            return (
                <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Locations"
                // placeholder="Favorites"
                />
            )
        }} />
        <Accordion disableGutters sx={{backgroundColor: "#303030", border: "1px solid #424242"}}>
            <AccordionSummary
                sx={{margin: 0}}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography sx={{margin: 0}}>Note</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="caption">Applies to shots/assists, fouls, turnovers. Using this filter will exclude plays that are missing location data.

                    Missing all Foul and TO data: NBA 15-16, NBA 16-17. Missing all Foul data: WNBA 17, NBA 17-18. Additional 3% of Foul/TO data missing each season</Typography>
            </AccordionDetails>
        </Accordion>

    </>)
};