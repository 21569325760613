import { game, player, shotTypes, teamPlayers } from '../../utils/api-utils';
import * as PbpQueryUtils from '../../utils/pbp-query-utils';

import { useState } from 'react';
import '../search.css';

import GameSelector from './game-selector';
import QuarterSelector from './quarter-selector';
import QuarterTimeSelector from './quarter-time-selector';
import LineupSelector from './lineup-selector';
import LocationSelector from './location-selector';
import NumberGteLteSelector from './number-gtelte-selector';
import PlayerSelector from './player-selector';
import TeamSelector from './team-selector';

enum SearchType {
    GAMES = 'Game is...',
    LINEUPS = 'Lineup includes...',
    PLAYERS = 'Play is...',
    QUARTER = 'Quarter/clutch is...',
    QUARTER_TIME = 'Quarter time remaining is...',
    SCORE_MARGIN = 'Score difference is...',
    TEAM = 'Team is...',
    LOCATION = 'Event location is...'
};

function QueryModal(props: QueryModalProps) {
    const [searchType, setSearchType] = useState<string>(SearchType.PLAYERS);
    const onSubmit = (queryStatement: PbpQueryUtils.pbpQueryUI) => props.addQueryStatement(queryStatement);
    return (<div className='search-field-container'>
        <div className='search-field-container'>
            Filter type: 
            <select className='search-fields margin-left-15' value={searchType} onChange={e => setSearchType(e.target.value)}>
                {
                    Object.values(SearchType).map((x, i) => {
                        return (<option key={`searchType${i}`} value={x}>{x}</option>);
                    })
                }
            </select>
        </div>
        <div>
            {
                searchType === SearchType.PLAYERS &&
                <PlayerSelector
                    players={props.players}
                    selectedLeague={props.selectedLeague}
                    selectedSeason={props.selectedSeason}
                    shotTypes={props.shotTypes}
                    onSubmit={onSubmit}
                />
            }
            {
                searchType === SearchType.GAMES &&
                <GameSelector
                    games={props.games}
                    onSubmit={onSubmit}
                    spoilers={props.spoilers}
                />
            }
            {
                searchType === SearchType.QUARTER &&
                <QuarterSelector
                    onSubmit={onSubmit}
                />
            }
            {
                searchType === SearchType.QUARTER_TIME &&
                <QuarterTimeSelector
                    onSubmit={(value: string, comparer: string) => {
                        const queryStatement : PbpQueryUtils.pbpQueryUI = PbpQueryUtils.createQuarterTimeQuery(`00:${value}`, comparer);
                        queryStatement.displayText = `Time remaining in quarter is ${PbpQueryUtils.convertComparerValToSymbol(comparer)} ${value}`;
                        props.addQueryStatement(queryStatement);
                    }}
                />
            }
            {
                searchType === SearchType.SCORE_MARGIN &&
                <NumberGteLteSelector
                    displayText={SearchType.SCORE_MARGIN}
                    onSubmit={(value: number, comparer: string) => {
                        if (value < 0 || value > 100) {
                            alert('Score difference must be between 0 and 100');
                            return;
                        }
                        const queryStatement : PbpQueryUtils.pbpQueryUI = PbpQueryUtils.createScoreDiffQuery(value, comparer);
                        queryStatement.displayText = `Score difference is ${PbpQueryUtils.convertComparerValToSymbol(comparer)} ${value}`;
                        props.addQueryStatement(queryStatement);
                    }}
                />
            }
            {
                searchType === SearchType.TEAM &&
                <TeamSelector
                    teams={props.teams}
                    onSubmit={onSubmit}
                />
            }
            {
                searchType === SearchType.LOCATION &&
                <LocationSelector onSubmit={onSubmit} />
            }
        </div>
        {
            searchType === SearchType.LINEUPS &&
            <div className='lineup-query-container'>
                <LineupSelector teamPlayers={props.teamPlayers} teamsToUse={props.teams} onSubmit={onSubmit} />
            </div>
        }
    </div>);
}

interface QueryModalProps {
    selectedLeague: string,
    selectedSeason: string,
    games: game[],
    players: player[],
    teams: team[],
    teamPlayers: teamPlayers,
    shotTypes: shotTypes,
    addQueryStatement: (query: PbpQueryUtils.pbpQueryUI) => void,
    spoilers: boolean
}

interface team {
    id: string,
    name: string
}

export default QueryModal;