import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { player, teamPlayers } from "../../utils/api-utils";
import { pbpQueryMenu } from "../../utils/pbp-query-utils";

import * as PbpQueryUtils from '../../utils/pbp-query-utils';

interface lineupSelectorProps {
    teamPlayers: teamPlayers,
    teams: { id: string, name: string }[],
    setQuery: (query: pbpQueryMenu) => void
}

export default function LineupSelector(props: lineupSelectorProps) {
    const teams = Object.keys(props.teamPlayers);
    const [selectedTeam, setSelectedTeamState] = useState<string>(teams[0]);
    const [selectedPlayers, setSelectedPlayersState] = useState([] as player[]);

    const setSelectedTeam = (team: string) => {
        setSelectedTeamState(team);
        setSelectedPlayersState([]);
    };
    useEffect(() => {
        setSelectedTeam(teams[0]);
    }, [props.teamPlayers]);
    useEffect(() => {
        const query = PbpQueryUtils.createLineupQuery(selectedPlayers.map(x => x.id), parseInt(selectedTeam));
        const isValid = selectedPlayers.length > 0;
        props.setQuery({
            ...query, isValid,
            isAnd: true, id: 0 // these don't matter, get overwritten by containers
        });
    }, [selectedTeam, selectedPlayers]);
    const players = props.teamPlayers[selectedTeam] !== undefined ? props.teamPlayers[selectedTeam] : props.teamPlayers[teams[0]];
    return (<>
        <FormControl>
            <InputLabel>League</InputLabel>
            <Select size="small" fullWidth label="Season" value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}>
                {
                    props.teams.map((team, i) => (<MenuItem key={`team${team.id}`} value={team.id}>{team.name}</MenuItem>))
                }
            </Select>
        </FormControl>
        <Autocomplete
            disableCloseOnSelect
            filterOptions={(options, {inputValue}) => {
                return options.filter(player => (
                    player.name.toLocaleLowerCase().includes(inputValue)));
            }}
            sx={{ marginTop: '5px' }}
            size="small"
            fullWidth
            value={selectedPlayers}
            onChange={(e, newVal) => {
                if (newVal.length <= 5) setSelectedPlayersState(newVal)
            }}
            multiple
            limitTags={1}
            options={players}
            getOptionLabel={(player) => player.name} 
            filterSelectedOptions
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Players"
                    // placeholder="Favorites"
                    />
                )
            }} />
    </>)
}