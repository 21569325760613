import React, { Fragment, useEffect, useRef, useState } from 'react';
import { localPoint } from '@visx/event';

import { pbpVideo, refetchVideo } from '../utils/api-utils';
import * as BrowserUtils from '../utils/browser-utils';
import { renderFileNameFromPbpVideo } from '../utils/game-selector-utils'
import { useUserContext } from '../utils/user-context';

import './video-player.css';
import GameViz from './viz/game-viz';
import ShotMap from './viz/shot-map';
import MultiDownload from './multi-download';

function shuffle(array: any[]) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

const copyLinkToClipboardFromRef = (ref: React.RefObject<HTMLInputElement>) => {
    return () => {
        if (ref.current) {
            ref.current.focus();
            ref.current.select();
            try {
                document.execCommand('copy');
            } catch (err) {
                console.log('Oops couldn\'t copy', err);
            }
        }
    }
}

function forceDownload(blob: string, filename: string) {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}

// Current blob size limit is around 500MB for browsers
function downloadResource(url: string, pbpVideo: pbpVideo) {
    const filename = renderFileNameFromPbpVideo(pbpVideo);//`${pbpVideo.awayTeam}@${pbpVideo.homeTeam}_${pbpVideo.gameDate.replaceAll('/', '-')}_${pbpVideo.period}Q_${pbpVideo.gameClock.replace(':', '\uA789')}`;
    fetch(url, {
        headers: new Headers({
            // eslint-disable-next-line no-restricted-globals
            'Origin': location.origin
        }),
        mode: 'cors'
    })
        .then(response => response.blob())
        .then(blob => {
            let blobUrl = window.URL.createObjectURL(blob);
            forceDownload(blobUrl, filename);
        })
        .catch(e => {
            alert('Failed to download. You must manually open and download: ' + url);
            console.error(e)
        });
}


let isLastScrollUser = true;
let lastCarouselScroll = 0;
// Element to move, element or px from, element or px to, time in ms to animate
const scrollToC = (element: HTMLElement, to: number, isTop: boolean) => {
    isLastScrollUser = false;
    if (isTop)
        element.scrollTop = to;
    else
        element.scrollLeft = to;
}

let mouseEnterEvent: null | React.MouseEvent | React.TouchEvent = null;
let mouseDownEvent: null | React.MouseEvent | React.TouchEvent = null;

function VideoPlayer(props: videoPlayerProps) {
    const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [pbpToRender, setPbpToRender] = useState(props.data);
    const [pbpToRenderRandom, setPbpToRenderRandom] = useState(shuffle(props.data.map(x => x)));
    const [playlist, setPlaylist] = useState<pbpVideo[]>([]);
    const [videoWidth, setVideoWidth] = useState(1280);
    const [videoHeight, setVideoHeight] = useState(720);
    const [useTallCarousel, setUseTallCarousel] = useState((window.innerWidth - 350 - videoWidth) > 0);
    const showResults = props.showResults;
    const [didUserAlterPlaylist, setDidUserAlterPlaylist] = useState(false);
    const [filteredEventNums, selectedEventNums] = useState(new Set<number>());
    const { authenticated } = useUserContext();
    const videoForSidebar = showResults ? (props.randomize ? pbpToRenderRandom : props.data) : playlist;
    let lastPagCall = Date.now();
    const splitQuery = searchTerm.toLowerCase().split(' ');
    const pbpSearchResults = videoForSidebar.filter(x => {
        return splitQuery.every(queryForCompare => {
            return (x.awayTeam.toLowerCase().includes(queryForCompare) ||
                x.homeTeam.toLowerCase().includes(queryForCompare) ||
                x.gameDate.toLowerCase().includes(queryForCompare) ||
                x.playDesc.toLowerCase().includes(queryForCompare));
        });
    });

    const filteredPbp = React.useMemo(() => pbpToRender.filter(x => filteredEventNums.size === 0 || filteredEventNums.has(x.eventNum)), [pbpToRender, filteredEventNums.size]);
    const filteredRandomPbp = React.useMemo(() => pbpToRenderRandom.filter(x => filteredEventNums.size === 0 || filteredEventNums.has(x.eventNum)), [pbpToRenderRandom, filteredEventNums.size]);
    const filteredPbpToRender = props.randomize ? filteredRandomPbp : filteredPbp;
    const admin = BrowserUtils.getURLParams().get('admin');

    // do something better than this, hopefully this should be knowable
    const isNotSearching = (showResults ? props.data.length : playlist.length) === pbpSearchResults.length;

    useEffect(() => {
        const onResize = () => {
            let width = document.getElementById("App")?.clientWidth;
            if (!width) width = window.innerWidth;
            let height = document.getElementById("App")?.clientHeight;
            if (!height) height = window.innerHeight;
            if (width > (1280) && height > (720)) {
                setVideoWidth(1280);
                setVideoHeight(720);
            }
            else if (width > (960) && height > (540)) {
                setVideoWidth(960);
                setVideoHeight(540);
            }
            else {
                setVideoWidth(320);
                setVideoHeight(180);
            }
            // if we can go tall carousel (i.e. wide enough), we go tall, else we go wide
            if ((width - 350 - videoWidth) > 0)
                setUseTallCarousel(true);
            else
                setUseTallCarousel(false);
        };
        onResize();
        window.addEventListener('resize', onResize);
    }, []);

    const vidRef = useRef<HTMLVideoElement>(null);
    const handleTouchVideo = (e: React.TouchEvent) => {
        mouseDownEvent = e;
        if (!props.fullscreen && window.matchMedia('(pointer: coarse)').matches) return;
        if (vidRef !== null && vidRef.current !== null) {
            if (vidRef.current.paused)
                vidRef.current.play();
            else
                vidRef.current.pause();
        }
    }

    useEffect(() => {
        if (pbpToRender[selectedVideoIndex]?.videoUrl !== props.data[selectedVideoIndex]?.videoUrl || pbpToRender.length !== props.data.length) {
            setSelectedVideoIndex(0);
        }
        setPbpToRender(props.data);
        setPbpToRenderRandom(shuffle(props.data.map(x => x)));

        selectedEventNums(new Set());
        setDidUserAlterPlaylist(false);
        if (props.fullscreen && vidRef !== null && vidRef.current !== null) {
            try {
                vidRef.current.requestFullscreen();
            } catch (e) {
                console.error(e);
            }
        }
    }, [props.data]);

    const prevVideo = () => {
        if (selectedVideoIndex > 0)
            setSelectedVideoIndex(selectedVideoIndex - 1);
    };
    const carousel = useRef<HTMLDivElement>(null);
    const activePlay = useRef<HTMLDivElement>(null);
    const nextVideo = () => {
        if (selectedVideoIndex < filteredPbpToRender.length - 1)
            setSelectedVideoIndex(selectedVideoIndex + 1);
    };

    useEffect(() => {
        // don't scroll if user scrolled in last 3 seconds
        if (carousel.current && activePlay.current && Date.now() > (lastCarouselScroll + 3000)) {
            // Element to move, element or px from, element or px to, time in ms to animate
            if (useTallCarousel) // add 40 for the sticky positioned filter input
                scrollToC(carousel.current, activePlay.current.offsetTop, true);
            else // add the difference between the play buttons and the input button // 250 (or 75 if we are small) for the sticky positioned filter input
                scrollToC(carousel.current, activePlay.current.offsetLeft + ((videoWidth === 320) ? 0 : 0), false);
        }
    }, [selectedVideoIndex]);
    const selectedPbpVideo = filteredPbpToRender[selectedVideoIndex];
    const resizedVideoUrl = selectedPbpVideo.videoUrl;//.replace('1280x720', `${videoWidth}x${videoHeight}`);
    const sidebarStyle = useTallCarousel ? { maxHeight: videoHeight, justifyContent: 'center' } : { justifyContent: 'center' };
    const carouselSize = useTallCarousel ? { maxHeight: videoHeight - 40 } : {};
    const selectedHomeColor = { backgroundColor: selectedPbpVideo.homeColor };
    const selectedAwayColor = { backgroundColor: selectedPbpVideo.awayColor };
    let sidebarCss = useTallCarousel ? 'carousel sidebar-tall' : 'carousel sidebar-wide';
    let carouselCss = useTallCarousel ? 'carousel plays-container-tall sidebar-tall' : 'carousel plays-container-wide sidebar-wide';

    // make sure height/width doesn't go to 0 when loading next video
    const videoContainerStyle = { minWidth: videoWidth, minHeight: videoHeight };
    let searchCss = 'search-fields pbp-search ';
    if (!useTallCarousel && videoWidth === 320)
        searchCss += ' pbp-search-text-wide-small';
    else if (useTallCarousel)
        searchCss += ' pbp-search-text-tall'
    else
        searchCss += ' pbp-search-text-wide';
    const isw = pbpToRender[0].gameId.toString().startsWith('1');
    const playLink = BrowserUtils.createIdsVideoUrl([filteredPbpToRender[selectedVideoIndex].tuid], !isw);
    const guids = filteredPbpToRender.filter((item, i) => i < 339).map(x => x.tuid);
    const urlIsPlaylistLink = !didUserAlterPlaylist && props.isUrlLinkable && showResults;
    const playlistLink = urlIsPlaylistLink ? window.location.href : BrowserUtils.createIdsVideoUrl(guids, !isw);
    const playLinkInput = useRef<HTMLInputElement>(null);
    const playlistLinkInput = useRef<HTMLInputElement>(null);
    const onCarouselScroll = () => {
        if (isLastScrollUser) lastCarouselScroll = Date.now();
        else isLastScrollUser = true;
    };

    const throttledFetchNext = () => {
        const callAfterTime = lastPagCall + 2500;
        const timeToWait = callAfterTime - Date.now();
        if (timeToWait < 0 && props.paginationFunc !== null) {
            lastPagCall = Date.now();
            props.paginationFunc();
            setDidUserAlterPlaylist(true);
        }
    };

    const loader = useRef(null);

    useEffect(() => {
        var options = {
            root: document.querySelector('#scrollableDiv'),
            rootMargin: "20px",
            threshold: 1.0
        };

        if (props.paginationFunc !== null) {
            // initialize IntersectionObserver
            // and attaching to Load More div
            const observer = new IntersectionObserver(throttledFetchNext, options);
            if (loader.current) {
                observer.observe(loader.current)
            }
            return () => observer.disconnect();
        }
    });

    const addPlay = (play: pbpVideo) => {
        const toSet = playlist.map(x => x);
        toSet.push(play);
        setPlaylist(toSet);
    };

    const removePlay = (i: number) => {
        setPlaylist(playlist.filter((x, j) => j !== i));
    };

    const removeVideo = (i: number) => {
        setDidUserAlterPlaylist(true);
        props.removeVideo(i);
    }

    const onMouseTouchUp = (event: React.MouseEvent | React.TouchEvent, isTouch: boolean) => {
        if (mouseDownEvent !== null) {
            const isValidUp = isTouch || mouseEnterEvent !== null
                && mouseDownEvent.timeStamp >= mouseEnterEvent.timeStamp;
            if (isValidUp) {
                const mouseDownX = localPoint(mouseDownEvent)?.x ?? -1;
                const mouseUpX = localPoint(event)?.x ?? -1;
                const mouseDownY = localPoint(mouseDownEvent)?.y ?? -1;
                const mouseUpY = localPoint(event)?.y ?? -1;
                const xMovement = Math.abs(mouseDownX - mouseUpX);
                const isHorizontalSwipe = xMovement > Math.abs(mouseDownY - mouseUpY);
                if (isHorizontalSwipe && xMovement > 100) {
                    if (mouseDownX > mouseUpX) prevVideo();
                    else nextVideo();
                }
            }
        }
    };

    const PlaylistSearch = (<div className='playlist-search-container'>
        <input type='text' className={searchCss} onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
            placeholder={'Search/filter'} />
    </div>);
    return (<div>
        {BrowserUtils.getURLParams().get('admin') && props.data.length > 0 &&
            <ShotMap data={props.data} />
        }
        {props.videoIsGame && props.data.length > 0 &&
            <GameViz plays={props.data} initialShowGameViz={props.initialShowGameViz} setFilteredEventNums={(urls: Set<number>) => {
                if (urls.size > 0 && selectedVideoIndex >= urls.size) setSelectedVideoIndex(0);
                selectedEventNums(urls);
            }} />
        }
        <div>
            {!useTallCarousel && PlaylistSearch}
            <div className={sidebarCss} style={sidebarStyle}>
                {useTallCarousel && PlaylistSearch}
                <div id='scrollableDiv' className={carouselCss} style={carouselSize} ref={carousel} onScroll={onCarouselScroll}>
                    {
                        pbpSearchResults.filter(play => filteredEventNums.size === 0 || filteredEventNums.has(play.eventNum)).map((play, i) => {
                            const playHomeColor = { backgroundColor: play.homeColor };
                            const playAwayColor = { backgroundColor: play.awayColor };
                            let containerCss = 'play-container';
                            // the length check is to make sure the filtered results are the same as the toRender results
                            // it's not technically correct but it's probably too much work to properly test
                            const isPlayActive = selectedPbpVideo.gameId === play.gameId && selectedPbpVideo.eventNum === play.eventNum;
                            if (isPlayActive) containerCss += ' active-play';
                            return (<div className={containerCss} ref={isPlayActive ? activePlay : null} key={`play${i}`}>
                                <button onClick={() => {
                                    if (!props.randomize) setPbpToRender(pbpSearchResults);
                                    else setPbpToRenderRandom(pbpSearchResults);
                                    if (pbpSearchResults.length !== pbpToRender.length) setDidUserAlterPlaylist(true);
                                    setSelectedVideoIndex(i);
                                }} key={i} className='play'>
                                    <p className='play-component'>
                                        <span className="team-highlight" style={playHomeColor}>{play.homeTeam}</span> {props.spoilers && play.homeScore} -&nbsp;
                                        <span className="team-highlight" style={playAwayColor}>{play.awayTeam}</span> {props.spoilers && play.awayScore}&nbsp;&nbsp;
                                    </p>
                                    <p className='play-component'><span className="play-desc-misc">({play.gameDate} {play.period}Q {play.gameClock})</span></p>
                                    <p className='play-component'>{props.spoilers && play.playDesc}</p>
                                </button>
                                {showResults &&
                                    <button onClick={() => addPlay(play)} className='play-action add-play' title='Add to My Playlist'>
                                        {/* <i className="material-icons add-icon md-18">add</i> */}
                                        <span>+</span>
                                    </button>
                                }
                                {isNotSearching &&
                                    <button onClick={() => showResults ? removeVideo(i) : removePlay(i)} className='play-action remove-play' title='Remove from current playlist'>
                                        {/* <i className="material-icons md-48">close</i> */}
                                        <span>x</span>
                                    </button>
                                }
                            </div>
                            )
                        })
                    }
                    {
                        props.paginationFunc !== null &&
                        <div className="loading" ref={loader}>
                            <p>Loading...</p>
                        </div>
                    }
                </div>
            </div>

            <div className='play-video-container'>
                <div style={videoContainerStyle}>
                    <video autoPlay controls muted playsInline={!props.fullscreen}
                        height={videoHeight} width={videoWidth}
                        ref={vidRef} src={resizedVideoUrl}
                        onEnded={nextVideo}
                        onTouchStart={handleTouchVideo}
                        onMouseEnter={e => mouseEnterEvent = e}
                        onMouseLeave={() => mouseEnterEvent = null}
                        onMouseDown={e => {
                            mouseDownEvent = e;
                            e.preventDefault();
                        }}
                        onDragStart={e => mouseDownEvent = e}
                        // onDragEnd={e => onMouseTouchUp(e, true)}
                        // onTouchEnd={e => onMouseTouchUp(e, true)}
                        onMouseUp={e => onMouseTouchUp(e, false)}>
                    </video>
                </div>
            </div>
            {
                filteredPbpToRender.length > 0 &&
                <Fragment>
                    <div>
                        <button onClick={prevVideo} className="change-play-button">
                            <i className="material-icons change-play-icon">arrow_back</i> Last
                        </button>
                        <button onClick={nextVideo} className="change-play-button">
                            Next <i className="material-icons change-play-icon">arrow_forward</i>
                        </button>
                    </div>
                    <p className='play-desc'>
                        <span className="team-highlight" style={selectedHomeColor}>
                            {selectedPbpVideo.homeTeam}</span> {props.spoilers && selectedPbpVideo.homeScore} -&nbsp;
                        <span className="team-highlight" style={selectedAwayColor}>
                            {selectedPbpVideo.awayTeam}</span> {props.spoilers && selectedPbpVideo.awayScore}&nbsp;&nbsp;
                        <span className="play-desc-misc">({selectedPbpVideo.gameDate} {selectedPbpVideo.period}Q {selectedPbpVideo.gameClock})</span>&nbsp;
                        {props.spoilers && selectedPbpVideo.playDesc}
                    </p>
                    <div className='copy-link-container'>
                        <label>Link to play </label>
                        <input type="text" className='copy-link-text' readOnly value={playLink} ref={playLinkInput} />
                        <button className='copy-link-button' onClick={copyLinkToClipboardFromRef(playLinkInput)}>
                            <i className="material-icons copy-link-icon">content_copy</i>
                        </button>
                        &nbsp;
                        <label>{urlIsPlaylistLink ? 'Link to playlist ' : 'Link to playlist (max 339) '}</label>
                        <input type="text" className='copy-link-text' readOnly value={playlistLink} ref={playlistLinkInput} />
                        <button className='copy-link-button' onClick={copyLinkToClipboardFromRef(playlistLinkInput)}>
                            <i className="material-icons copy-link-icon">content_copy</i>
                        </button>
                    </div>
                    <div className='copy-link-container'>
                        <button className="download-video-button" onClick={() => downloadResource(resizedVideoUrl, selectedPbpVideo)}>Download</button>
                        <MultiDownload plays={filteredPbpToRender} visible={authenticated} />
                        &nbsp;
                        {
                            admin && <button onClick={() => refetchVideo(true, selectedPbpVideo.tuid.substring(0, 2), selectedPbpVideo.gameId, selectedPbpVideo.eventNum)}>Admin refetch</button>
                        }
                    </div>
                </Fragment>
            }
        </div>

    </div>);
}

interface videoPlayerProps {
    isUrlLinkable: boolean,
    data: pbpVideo[],
    paginationFunc: null | (() => void),
    removeVideo: (i: number) => void,
    spoilers: boolean,
    toggleSpoilers: () => void,
    fullscreen: boolean,
    toggleFullscreen: () => void,
    randomize: boolean,
    toggleRandomize: () => void,
    videoIsGame: boolean,
    initialShowGameViz: boolean,
    showResults: boolean
}

export default VideoPlayer;