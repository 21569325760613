import { player, teamPlayers } from '../../utils/api-utils';
import React, { useRef, useState } from 'react';
import '../search.css';

function LineupSelector(props: lineupSelectorProps) {
    const { selectedPlayers, players } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [showPlayers, setShowPlayers] = useState(false);

    const selectedPlayersArr = players.filter(x => selectedPlayers[x.id]);
    const playersSelect = useRef(null);

    const setSelectedPlayers = (key: number) => {
        const value = !selectedPlayers[key];
        const toSet = { ...selectedPlayers };
        toSet[key] = value;
        if (!value || selectedPlayersArr.length < 5) {
            props.setSelectedPlayers(toSet);
            setSearchTerm('');
        }
        try {
            (document.activeElement as HTMLElement).blur();
        } catch (error) {
            console.log('No element to blur', error);
        }
    };
    const onSearchBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target !== playersSelect.current) {
            setShowPlayers(false);
        }
    };

    return (<React.Fragment>
        <input type='text' className='margin-top search-fields search-text'
            value={searchTerm}
            onFocus={() => setShowPlayers(true)}
            onBlur={onSearchBlur}
            onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
            placeholder={props.placeholder ? props.placeholder : 'Search by player name'} />
        <div className='search-results-container' ref={playersSelect}>
            {showPlayers &&
                players
                    .filter(player => player.name.toLocaleLowerCase().includes(searchTerm))
                    .map((player, i) => {
                        const className = selectedPlayers[player.id] ? 'search-result-selected' : 'search-result';
                        const abbrClause = player.teamAbbrs && player.teamAbbrs.length > 0 ? `(${player.teamAbbrs})` : ``;
                        return (<button className={className}
                            key={`player${i}`}
                            onMouseDown={e => e.preventDefault()}
                            onKeyUp={(e) => { if (e.keyCode === 13 || e.keyCode === 32) { e.preventDefault() } }}
                            onClick={() => setSelectedPlayers(player.id)}>{player.name}{abbrClause}</button>);
                    })
            }
        </div>
        {selectedPlayersArr.length > 0 &&
            <div className='all-filters-container'>
                <div className='filter-container'>
                    <div className='filter-header'>{props.selectedPlayerHeader ? props.selectedPlayerHeader : "Searching for lineups with..."}</div>
                    {players.filter(x => selectedPlayers[x.id]).map((x, i) => {
                        const abbrClause = x.teamAbbrs && x.teamAbbrs.length > 0 ? ` (${x.teamAbbrs})` : ``;
                        return (<div className='filter' key={`selectedPlayer${i}`}>
                            <label className='checkbox-container'>
                                <input type='checkbox' checked={selectedPlayers[x.id]}
                                    className='filter-checkbox' onChange={() => setSelectedPlayers(x.id)} />
                                <span className='checkbox-label'>{x.name}{abbrClause}</span>
                            </label>
                        </div>);
                    })}
                </div>
            </div>
        }
    </React.Fragment>);
}

export interface selectedPlayers {
    [key: number]: boolean
}

interface lineupSelectorProps {
    placeholder?: string,
    selectedPlayerHeader?: string,
    players: player[],
    selectedPlayers: selectedPlayers,
    setSelectedPlayers: (selectedPlayers: selectedPlayers) => void
}

export default LineupSelector;