export const getURLParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    for (let [key, value] of urlParams) {
        const lowerCaseKey = key.toLowerCase();
        if (!urlParams.has(lowerCaseKey))
            urlParams.append(lowerCaseKey, value);
    }
    return urlParams;
};

export const BASE_VIDEO_URL = '/video'
export const GAME_VIDEO_URL = `${BASE_VIDEO_URL}/game/:league/:season/:gameId`;
export const createGameVideoUrl = (gameId: number, season: string, isNba: boolean, videoType: string, playerId: number | undefined) => {
    var path = GAME_VIDEO_URL.replace(':league', isNba ? 'nba' : 'wnba')
        .replace(':gameId', gameId.toString())
        .replace(':season', season);
        path += "?videoType=" + videoType;
        if (playerId !== undefined) path += "&playerId="+ playerId;
    return `${window.location.protocol}//${window.location.host}${path}`;
};

export const GAMES_VIDEO_URL = `${BASE_VIDEO_URL}/games/:league/:season/:gameIds`;
export const createGamesVideoUrl = (gameIds: number[], season: string, isNba: boolean, videoType: string, playerId: number | undefined) => {
    var path = GAMES_VIDEO_URL.replace(':league', isNba ? 'nba' : 'wnba')
        .replace(':gameIds', gameIds.map(x => x.toString()).join())
        .replace(':season', season);
        path += "?videoType=" + videoType;
        if (playerId !== undefined) path += "&playerId="+ playerId;
    return `${window.location.protocol}//${window.location.host}${path}`;
};

export const PLAYER_VIDEO_URL = `${BASE_VIDEO_URL}/player/:league/:season/:playerId/:filters`;
export const createPlayerVideoUrl = (playerId: number, season: string, isNba: boolean, filters: string, shotFilters: string, ordsq: string) => {
    var path = PLAYER_VIDEO_URL.replace(':league', isNba ? 'nba' : 'wnba')
        .replace(':playerId', playerId.toString())
        .replace(':season', season)
        .replace(':filters', filters);
    return `${window.location.protocol}//${window.location.host}${path}?ordsq=${ordsq}&shotFilters=${shotFilters}`;
};

export const PLAYLIST_VIDEO_URL = `${BASE_VIDEO_URL}/playlist/:playlistName/:league/:season`;
export const createPlaylistVideoUrl = (playlistName: string, season: string, isNba: boolean, ordsq: string) => {
    var path = PLAYLIST_VIDEO_URL.replace(':league', isNba ? 'nba' : 'wnba')
    .replace(':playlistName', playlistName.toString())
    .replace(':season', season);
    return `${window.location.protocol}//${window.location.host}${path}?ordsq=${ordsq}`;
};

export const LINEUP_VIDEO_URL = `${BASE_VIDEO_URL}/lineup/:league/:season/:teamId/:playerIds`;
export const createLineupVideoUrl = (season: string, isNba: boolean, teamId: number, playerIds: string, ordsq: string, off: boolean, def: boolean) => {
    var path = LINEUP_VIDEO_URL.replace(':league', isNba ? 'nba' : 'wnba')
        .replace(':teamId', teamId.toString())
        .replace(':season', season)
        .replace(':playerIds', playerIds);
    return `${window.location.protocol}//${window.location.host}${path}?ordsq=${ordsq}&off=${off}&def=${def}`;
};

export const IDS_VIDEO_URL = `${BASE_VIDEO_URL}/ids`;
export const createIdsVideoUrl = (ids: string[], isNba: boolean) => {
    const nba = isNba ? '' : '&isw=t';
    return `${window.location.protocol}//${window.location.host}${IDS_VIDEO_URL}?ids=${ids.join('')}${nba}`;
};

export const QUERY_VIDEO_URL = `${BASE_VIDEO_URL}/query`;

export const CHANGE_PASSWORD_URL = `/changepass/:token`;
