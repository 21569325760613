import {useState} from 'react';
import ReactDOM from "react-dom";

import './search.css';

import LeagueSeasonSelector from './common/league-season-selector';
import LineupSelector from './common/lineup-selector';
import OrderEarliestLatestSelector from './common/order-earliest-latest-selector';
import * as ComponentUtils from '../utils/component-utils';
import { useDataContext } from '../utils/data-context';

function LineupSearch(props: seasonTeamPlayersProps) {
    const data = useDataContext();
    const [selectorState, setSelectorState] = useState({
        league: 'nba',
        season: ComponentUtils.getLatestSeason('nba'),
        team: Object.keys(data.seasonTeamPlayers.seasonTeamPlayer[ComponentUtils.getLatestSeason('nba')])[0]
    });
    const selectedLeague = selectorState.league;
    const selectedSeason = selectorState.season;
    const selectedTeam = selectorState.team;
    const seasonTeamPlayerTransport = selectedLeague === 'nba' ? data.seasonTeamPlayers : data.wseasonTeamPlayers;
    const seasonTeamPlayers = seasonTeamPlayerTransport.seasonTeamPlayer;
    const [getOffensePlays, setGetOffenseFilter] = useState(true);
    const [getDefensePlays, setGetDefenseFilter] = useState(true);
    const selectedPlayersState : selectedPlayers = {};
    seasonTeamPlayers[selectedSeason][selectedTeam].forEach(player => selectedPlayersState[player.id] = false);
    const [selectedPlayers, setSelectedPlayersState] = useState<selectedPlayers>(selectedPlayersState);
    const [orderByTime, setOrderByTime] = useState('');
    const setLeagueAndSeason = (league: string) => {
        const seasonTeamPlayers = league === 'nba' ? data.seasonTeamPlayers.seasonTeamPlayer : data.wseasonTeamPlayers.seasonTeamPlayer;
        setSelectorState({
            league,
            season: ComponentUtils.getLatestSeason(league),
            team: Object.keys(seasonTeamPlayers[ComponentUtils.getLatestSeason(league)])[0]
        });
    };
    const setSelectedTeam = (team: string) => {
        setSelectorState({
            ...selectorState,
            team
        });
        setSelectedPlayersState({});
    };
    const setSelectedSeason = (season: string) => {
        setSelectorState({
            ...selectorState,
            season
        });
    };
    const selectedPlayersArr = seasonTeamPlayers[selectedSeason][selectedTeam].filter(x => selectedPlayers[x.id]);
    const onSubmit = () => {
        if (selectedPlayersArr.length === 0) {
            alert("Must select at least one player");
            return;
        }
        props.onSelectItem(selectedPlayersArr.map(x => x.id), selectedLeague === 'nba', selectedSeason, selectedTeam, getOffensePlays, getDefensePlays, orderByTime);
    };
    const teamsToUse = Object.keys(seasonTeamPlayers[selectedSeason]).map(x => {
        return {id: x, name: seasonTeamPlayerTransport.teamNamesById[x]};
    });
    teamsToUse.sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
    });
    return (<div style={props.visible ? {} : { display: 'none' }}>
        <div className='search-field-container'>
            <div>
                <LeagueSeasonSelector 
                    selectedLeague={selectedLeague}
                    setLeagueAndSeason={setLeagueAndSeason}
                    selectedSeason={selectedSeason}
                    seasons={Object.keys(seasonTeamPlayers)}
                    setSelectedSeason={setSelectedSeason}
                />
                <select className='search-fields' value={selectedTeam} 
                    onChange={(e) => setSelectedTeam(e.target.value)}>
                    {
                        teamsToUse.map((team, i) => {
                            return (<option key={`team${i}`} onChange={() => setSelectedTeam(team.id)} value={team.id}>{team.name}</option>);
                        })
                    }
                </select>
            </div>
            <LineupSelector
                players={seasonTeamPlayers[selectedSeason][selectedTeam]}
                selectedPlayers={selectedPlayers}
                setSelectedPlayers={setSelectedPlayersState}
            />
            <div className='all-filters-container'>
                <div className='filter-container none'>
                    <div className='filter-header'>Play type</div>
                    <div className='filter'>
                        <label className='checkbox-container'>
                            <input type='checkbox' checked={getOffensePlays}
                                className='filter-checkbox' onChange={() => setGetOffenseFilter(!getOffensePlays)} />
                            <span className='checkbox-label'>Offense</span>
                        </label>
                    </div>
                    <div className='filter'>
                        <label className='checkbox-container'>
                            <input type='checkbox' checked={getDefensePlays}
                                className='filter-checkbox' onChange={() => setGetDefenseFilter(!getDefensePlays)} />
                            <span className='checkbox-label'>Defense</span>
                        </label>
                    </div>
                </div>
                <OrderEarliestLatestSelector
                    orderByTime={orderByTime}
                    setOrderByTime={setOrderByTime}/>
            </div>
            {props.children}
            <button className='submit-button action-button' onClick={() => onSubmit()}>Search</button>
        </div>
    </div>);
}
interface selectedPlayers {
    [key: number]: boolean
}
interface seasonTeamPlayersProps {
    visible: boolean,
    onSelectItem: (playerIds: number[], isNba: boolean, season: string, teamId: string, includeOffense: boolean, includeDefense: boolean, ordsq: string) => void,
    children?: React.ReactNode
}

export default LineupSearch;